/* eslint-disable */
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Any: any
  Byte: any
  DateTime: any
  Decimal: number
  URL: any
  UUID: any
}

export type Accessories = {
  articleAccessories: Array<ProductAccessory>
  articleAccessoriesCount: Scalars['Int']
  categoryAccessories: Array<CategoryAccessory>
  categoryAccessoriesCount: Scalars['Int']
  dependentAccessories: Array<Product>
  insuranceAccessories: Array<Product>
  insuranceAccessoryIds: Array<Scalars['String']>
  keyPrefix: Scalars['String']
  mandatoryAccessories: Array<Product>
  normalAccessories: Array<Product>
  productNearServicesAccessories: Array<Product>
  topAccessories: Array<Product>
}

export type AccessoryGuide = {
  category: Category
  manufacturerNames: Array<Scalars['String']>
}

export type AccessoryGuideProductsWithHits = {
  hits: Scalars['Int']
  products?: Maybe<Array<ProductReference>>
}

export type AccessoryProductModel = {
  availabilityInformation?: Maybe<AccountGraphql_Availability>
  product?: Maybe<LatestBoughtProduct>
}

export enum AccessoryType {
  Accessories = 'ACCESSORIES',
  AddonCampaign = 'ADDON_CAMPAIGN',
  CategoryAccessory = 'CATEGORY_ACCESSORY',
  MandatoryAccessory = 'MANDATORY_ACCESSORY',
  Paas = 'PAAS',
  PaasReference = 'PAAS_REFERENCE',
  ProductNearServices = 'PRODUCT_NEAR_SERVICES',
  RecommendedAccessories = 'RECOMMENDED_ACCESSORIES',
  SparePart = 'SPARE_PART',
  Unspecified = 'UNSPECIFIED',
}

export enum AccountAndPrivacyTermsState {
  Accepted = 'ACCEPTED',
  NeedsToAcceptPrivacyTermsUpdate = 'NEEDS_TO_ACCEPT_PRIVACY_TERMS_UPDATE',
  None = 'NONE',
  NotAccepted = 'NOT_ACCEPTED',
}

export type Activity = {
  banners?: Maybe<Array<Maybe<Banner>>>
  id: Scalars['Int']
  productErpIdentifiers?: Maybe<Array<Maybe<Scalars['String']>>>
  productFilterList: ProductFilterList
  products: Array<Product>
  title?: Maybe<Scalars['String']>
}

export type ActivityProductFilterListArgs = {
  facets?: InputMaybe<Array<InputMaybe<FacetParameterInput>>>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  searchPhrase?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<SortBy>
}

export type AdContainer = {
  activityId: Scalars['Int']
  banner?: Maybe<Banner>
  bannerId: Scalars['Int']
  productReferences: Array<ProductReference>
  ticket: Scalars['String']
}

export type Address = {
  accessCode?: Maybe<Scalars['String']>
  addressErpIdentifier?: Maybe<Scalars['String']>
  addressLine?: Maybe<Scalars['String']>
  addressSpecificationList?: Maybe<Array<Maybe<AddressSpecification>>>
  addressSpecificationTypes?: Maybe<Scalars['String']>
  addressee?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  createdDateTimeUtc: Scalars['DateTime']
  deliveryModeErpIdentifier?: Maybe<Scalars['String']>
  department?: Maybe<Scalars['String']>
  goodsReceiverName?: Maybe<Scalars['String']>
  hiddenForGui: Scalars['Boolean']
  houseNumber?: Maybe<Scalars['String']>
  id: Scalars['Int']
  inactive: Scalars['Boolean']
  isBox: Scalars['Boolean']
  isNew: Scalars['Boolean']
  isOnetime: Scalars['Boolean']
  lastModifiedBy?: Maybe<Scalars['String']>
  lastModifiedDateTimeUtc: Scalars['DateTime']
  lastValidatedDateTimeUtc?: Maybe<Scalars['DateTime']>
  locationIdentifier?: Maybe<Scalars['String']>
  origin?: Maybe<AddressOrigin>
  postalCode?: Maybe<Scalars['String']>
  taxCategoryErpIdentifier?: Maybe<Scalars['String']>
  validFromDateTimeUtc: Scalars['DateTime']
  validToDateTimeUtc: Scalars['DateTime']
}

export type AddressDetails = {
  addressLine?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  receipient?: Maybe<Scalars['String']>
}

export enum AddressOrigin {
  Contact = 'CONTACT',
  Customer = 'CUSTOMER',
}

export type AddressSpecification = {
  addressId: Scalars['Int']
  addressType: AddressType
  isPrimary: Scalars['Boolean']
}

export type AddressSuggestionModel = {
  label?: Maybe<Scalars['String']>
  locality?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export enum AddressType {
  Billing = 'BILLING',
  Business = 'BUSINESS',
  None = 'NONE',
  OfficialBilling = 'OFFICIAL_BILLING',
  OfficialShipping = 'OFFICIAL_SHIPPING',
  Other = 'OTHER',
  Shipping = 'SHIPPING',
  Visiting = 'VISITING',
}

export enum AmountType {
  Fixed = 'FIXED',
  None = 'NONE',
  Percentage = 'PERCENTAGE',
  PerUnit = 'PER_UNIT',
}

export type Area = {
  blocks: Array<GridBlock>
  name: Scalars['String']
}

export type Article = {
  categoryName: Scalars['String']
  mainImage?: Maybe<Image>
  postDate: Scalars['DateTime']
  postDateText: Scalars['String']
  summary: Scalars['String']
  title: Scalars['String']
  updatedDate: Scalars['DateTime']
  updatedDateText: Scalars['String']
  url: Scalars['String']
}

export type ArticleBoxList = {
  articles: Array<Article>
  blogUrl: Scalars['String']
}

export type ArticlePage = {
  blogUrl: Scalars['String']
  body: Scalars['String']
  breadcrumbs: Array<Breadcrumb>
  categoryArticles: Array<BlogArticle>
  categoryName: Scalars['String']
  categoryNumberOfArticles: Scalars['Int']
  content: Array<GridBlock>
  disableSiteSelector: Scalars['Boolean']
  excerpt?: Maybe<Scalars['String']>
  header: Scalars['String']
  heroImage?: Maybe<Image>
  mainImage?: Maybe<Image>
  metaDescription?: Maybe<Scalars['String']>
  metaKeywords?: Maybe<Scalars['String']>
  noIndex: Scalars['Boolean']
  ogImage?: Maybe<Image>
  postDate: Scalars['DateTime']
  postDateIso8601Text: Scalars['String']
  postDateText: Scalars['String']
  tags: Array<Scalars['String']>
  theme: Theme
  title: Scalars['String']
  type: PageType
  updatedDate: Scalars['DateTime']
  updatedDateIso8601Text: Scalars['String']
  updatedDateText: Scalars['String']
  youTubeId?: Maybe<Scalars['String']>
}

export type ArticleTagCount = {
  articleCount: Scalars['Int']
  tag: Scalars['String']
}

export type AttestContact = {
  attesterLevelIds?: Maybe<Array<Scalars['Int']>>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['Int']
  lastName?: Maybe<Scalars['String']>
  purchaserGroupId?: Maybe<Scalars['Int']>
}

export type AttestGroup = {
  customerId: Scalars['Int']
  errorCodes?: Maybe<Array<ErrorCode>>
  id: Scalars['Int']
  isDefault: Scalars['Boolean']
  isValid: Scalars['Boolean']
  levels?: Maybe<Array<Maybe<AttestGroupLevel>>>
  members?: Maybe<Array<Scalars['Int']>>
  name?: Maybe<Scalars['String']>
  useOnlyHighestAttestLevel: Scalars['Boolean']
}

export type AttestGroupLevel = {
  /** @deprecated This list contains only main attesters (no stand-in attesters). Use AttestersWithStandins instead */
  attesters?: Maybe<Array<Scalars['Int']>>
  attestersWithStandins?: Maybe<Array<Maybe<Attester>>>
  errorCodes?: Maybe<Array<ErrorCode>>
  id: Scalars['Int']
  isValid: Scalars['Boolean']
  level: Scalars['Int']
  limit: Scalars['Decimal']
  name?: Maybe<Scalars['String']>
}

export type AttestGroupOverview = {
  id: Scalars['Int']
  isDefault: Scalars['Boolean']
  isValid: Scalars['Boolean']
  name?: Maybe<Scalars['String']>
  useHighestLevel: Scalars['Boolean']
}

export type AttestModel = {
  availableStandInContacts?: Maybe<Array<KeyValuePairOfInt32AndString>>
  isAttester: Scalars['Boolean']
  selectedStandIn: Scalars['Int']
  standInForContacts?: Maybe<Array<Maybe<Scalars['String']>>>
  standInUntilDateTime?: Maybe<Scalars['DateTime']>
}

export type AttestPreOrderRequestInput = {
  approve: Scalars['Boolean']
  comment?: InputMaybe<Scalars['String']>
  preOrderId: Scalars['Int']
  stepNumber: Scalars['Int']
}

export type AttestPreOrderResult = {
  attestPreOrderResultEnum: AttestPreOrderResultEnum
  orderId: Scalars['Int']
}

export enum AttestPreOrderResultEnum {
  Approved = 'APPROVED',
  Error = 'ERROR',
  StillPending = 'STILL_PENDING',
}

export type AttestSetupOverview = {
  attestContacts: Array<AttestContact>
  attestGroups: Array<AttestGroupOverview>
}

export type Attester = {
  contactId: Scalars['Int']
  standInContactId?: Maybe<Scalars['Int']>
}

export type AttesterRequestInput = {
  contactId: Scalars['Int']
  levelId: Scalars['Int']
}

export type AuthConnectedListContact = {
  companyName?: Maybe<Scalars['String']>
  contactInfo?: Maybe<GetContactsForContactSelectorDto>
  customerClassificationType?: Maybe<CustomerClassificationType>
  customerErpIdentifier?: Maybe<Scalars['String']>
  customerId: Scalars['Int']
  email?: Maybe<Scalars['String']>
  erpId?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  id: Scalars['Int']
  isB2B: Scalars['Boolean']
  isBlocked: Scalars['Boolean']
  isInactive: Scalars['Boolean']
  isManaged: Scalars['Boolean']
  isPrimary: Scalars['Boolean']
  isPunchOut: Scalars['Boolean']
  isSuperUser: Scalars['Boolean']
  loginUrl?: Maybe<Scalars['String']>
  siteInfo?: Maybe<SiteInfo>
  sourcingCompany: SourcingCompany
  userIdentifier: Scalars['UUID']
}

export type AutoComplete = {
  didYouMean?: Maybe<Array<Maybe<DidYouMean>>>
  productHitCount: Scalars['Int']
  products?: Maybe<Array<Maybe<ProductReference>>>
  relatedCategories: Array<AutoCompleteCategory>
  searchPhrase?: Maybe<Scalars['String']>
  suggestions?: Maybe<Array<Maybe<Suggestion>>>
}

export type AutoCompleteCategory = {
  category?: Maybe<ProductGraphql_Category>
  count: Scalars['Int']
  selected: Scalars['Boolean']
  text?: Maybe<Scalars['String']>
  ticket?: Maybe<Scalars['String']>
}

export type Availability = {
  IsNotifyEligible: Scalars['Boolean']
  availabilityDetails?: Maybe<AvailabilityDetails>
  availabilityStatus: AvailabilityStatus
  availabilityStatusPresentation: AvailabilityStatusPresentation
  externalStock?: Maybe<ExternalStockAvailability>
  internal: Scalars['Boolean']
  internalEtaStock?: Maybe<InternalEtaStockAvailability>
  internalStock?: Maybe<InternalStockAvailability>
  isAvailableForSale: Scalars['Boolean']
  isIncludedInKit: Scalars['Boolean']
  isNotifyEligible: Scalars['Boolean']
  isPreOrder: Scalars['Boolean']
  maxAvailableQuantity?: Maybe<Scalars['Int']>
  productErpIdentifier?: Maybe<Scalars['String']>
  productLifeCycleState: ProductLifeCycleState
  showAvailabilityQuantities: Scalars['Boolean']
  showEtaDate: Scalars['Boolean']
}

export type AvailabilityDetails = {
  dateTimeUtc?: Maybe<Scalars['DateTime']>
  formattedDateUtc?: Maybe<Scalars['String']>
  key: AvailabilityStatusType
}

export enum AvailabilityStatus {
  ComingSoon = 'COMING_SOON',
  ExternalStock = 'EXTERNAL_STOCK',
  ImmediateShipping = 'IMMEDIATE_SHIPPING',
  OutOfStock = 'OUT_OF_STOCK',
  Unknown = 'UNKNOWN',
}

export enum AvailabilityStatusPresentation {
  ShowAsComingSoon = 'SHOW_AS_COMING_SOON',
  ShowAsExternalStock = 'SHOW_AS_EXTERNAL_STOCK',
  ShowAsImmediateShipping = 'SHOW_AS_IMMEDIATE_SHIPPING',
  ShowAsOutOfStock = 'SHOW_AS_OUT_OF_STOCK',
}

export enum AvailabilityStatusType {
  ProductAvailabilityEsd = 'PRODUCT_AVAILABILITY_ESD',
  ProductAvailabilityEstimatedShippingDate = 'PRODUCT_AVAILABILITY_ESTIMATED_SHIPPING_DATE',
  ProductAvailabilityImmediateShipping = 'PRODUCT_AVAILABILITY_IMMEDIATE_SHIPPING',
  ProductAvailabilityNoImmediateShipping = 'PRODUCT_AVAILABILITY_NO_IMMEDIATE_SHIPPING',
  ProductAvailabilityOutOfStock = 'PRODUCT_AVAILABILITY_OUT_OF_STOCK',
  ProductAvailabilityOutOfStockComingSoon = 'PRODUCT_AVAILABILITY_OUT_OF_STOCK_COMING_SOON',
  ProductAvailabilityShippingDate = 'PRODUCT_AVAILABILITY_SHIPPING_DATE',
  ProductAvailabilityShippingDateAtTheEarliest = 'PRODUCT_AVAILABILITY_SHIPPING_DATE_AT_THE_EARLIEST',
  ProductAvailabilityShortage = 'PRODUCT_AVAILABILITY_SHORTAGE',
  ProductDistributorLeadTimeFourSixDays = 'PRODUCT_DISTRIBUTOR_LEAD_TIME_FOUR_SIX_DAYS',
  ProductDistributorLeadTimeOneTwoDays = 'PRODUCT_DISTRIBUTOR_LEAD_TIME_ONE_TWO_DAYS',
  ProductDistributorLeadTimeSixEightDays = 'PRODUCT_DISTRIBUTOR_LEAD_TIME_SIX_EIGHT_DAYS',
  ProductDistributorLeadTimeTenTwelveDays = 'PRODUCT_DISTRIBUTOR_LEAD_TIME_TEN_TWELVE_DAYS',
  ProductDistributorLeadTimeThreeFourDays = 'PRODUCT_DISTRIBUTOR_LEAD_TIME_THREE_FOUR_DAYS',
  ProductEstimatedInOurStore = 'PRODUCT_ESTIMATED_IN_OUR_STORE',
  ProductEstimatedInOurStoreNoDate = 'PRODUCT_ESTIMATED_IN_OUR_STORE_NO_DATE',
  ProductEtaLeadTimeEnumOne2TwoWeeks = 'PRODUCT_ETA_LEAD_TIME_ENUM_ONE2_TWO_WEEKS',
  ProductEtaLeadTimeEnumThree2FourWeeks = 'PRODUCT_ETA_LEAD_TIME_ENUM_THREE2_FOUR_WEEKS',
  ProductEtaLeadTimeEnumTwo2ThreeWeeks = 'PRODUCT_ETA_LEAD_TIME_ENUM_TWO2_THREE_WEEKS',
}

export type Banner = {
  buttonText?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
  displayMode: Scalars['String']
  id: Scalars['Int']
  imageUrl?: Maybe<Scalars['String']>
  relatedProductErpIdentifiers?: Maybe<Array<Scalars['String']>>
  relatedProducts?: Maybe<Array<Product>>
  showOnCategories: Scalars['String']
  text?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type BannerDto = {
  fileName?: Maybe<Scalars['String']>
  filePath?: Maybe<Scalars['String']>
  fileSize: Scalars['Int']
  pageId: Scalars['Int']
}

export type BlogArticle = {
  categoryName: Scalars['String']
  mainImage?: Maybe<Image>
  postDate: Scalars['DateTime']
  postDateText: Scalars['String']
  summary: Scalars['String']
  tags: Array<Scalars['String']>
  title: Scalars['String']
  updatedDate: Scalars['DateTime']
  url: Scalars['String']
  youTubeId?: Maybe<Scalars['String']>
}

export type BlogCategory = {
  name: Scalars['String']
  summary?: Maybe<Scalars['String']>
}

export type BlogPage = {
  articles: Array<BlogArticle>
  banner: Image
  breadcrumbs: Array<Breadcrumb>
  /** @deprecated Use FullCategories instead, which should replace this property in the future. */
  categories: Array<Scalars['String']>
  categoryTags: Array<ArticleTagCount>
  description: Scalars['String']
  disableSiteSelector: Scalars['Boolean']
  fullCategories: Array<BlogCategory>
  header: Scalars['String']
  metaDescription?: Maybe<Scalars['String']>
  metaKeywords?: Maybe<Scalars['String']>
  noIndex: Scalars['Boolean']
  numberOfArticles: Scalars['Int']
  ogImage?: Maybe<Image>
  theme: Theme
  title: Scalars['String']
  type: PageType
}

export type Brand = {
  logo?: Maybe<Image>
  name: Scalars['String']
  url: Scalars['String']
}

export type BrandContent = {
  bigHeader: Scalars['Boolean']
  brandName?: Maybe<Scalars['String']>
  campaignOnly: Scalars['Boolean']
  categoryPath?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  contentBlocks: Array<GridBlock>
  cssClass: Scalars['String']
  cssStyle?: Maybe<Scalars['String']>
  header?: Maybe<Scalars['String']>
  image?: Maybe<Image>
  newProductsOnly: Scalars['Boolean']
  outerCssClass: Scalars['String']
  outerCssStyle: Scalars['String']
  searchPhrase?: Maybe<Scalars['String']>
  showMoreText?: Maybe<Scalars['String']>
  showMoreUrl?: Maybe<Scalars['String']>
  textFirst: Scalars['Boolean']
  type: BrandContentType
}

export type BrandContentNavigationItem = {
  displayName: Scalars['String']
  isActive: Scalars['Boolean']
  subNavigationItems: Array<BrandContentSubNavigationItem>
  url: Scalars['String']
}

export type BrandContentPage = {
  allBrandsUrl: Scalars['String']
  breadcrumbs: Array<Breadcrumb>
  content: Array<BrandContent>
  disableSiteSelector: Scalars['Boolean']
  header?: Maybe<Scalars['String']>
  metaDescription?: Maybe<Scalars['String']>
  metaKeywords?: Maybe<Scalars['String']>
  navigationItems: Array<BrandContentNavigationItem>
  noIndex: Scalars['Boolean']
  ogImage?: Maybe<Image>
  theme: Theme
  title: Scalars['String']
  type: PageType
}

export type BrandContentSubNavigationItem = {
  displayName: Scalars['String']
  subNavigationItems: Array<BrandContentSubNavigationItem>
  url: Scalars['String']
}

export enum BrandContentType {
  ActivityBanner = 'ACTIVITY_BANNER',
  HeaderBanner = 'HEADER_BANNER',
  HtmlBlock = 'HTML_BLOCK',
  ProductFilterBlock = 'PRODUCT_FILTER_BLOCK',
  ProductGroup = 'PRODUCT_GROUP',
  ShopByBrandSectionsContentBlocks = 'SHOP_BY_BRAND_SECTIONS_CONTENT_BLOCKS',
}

export type BrandListPage = {
  brands: Array<Brand>
  breadcrumbs: Array<Breadcrumb>
  disableSiteSelector: Scalars['Boolean']
  header: Scalars['String']
  metaDescription?: Maybe<Scalars['String']>
  metaKeywords?: Maybe<Scalars['String']>
  noIndex: Scalars['Boolean']
  ogImage?: Maybe<Image>
  theme: Theme
  title: Scalars['String']
  type: PageType
}

export type BrandNavigation = {
  allBrandsUrl: Scalars['String']
  brands: Array<Brand>
}

export type BrandPage = {
  allBrandsUrl: Scalars['String']
  breadcrumbs: Array<Breadcrumb>
  description: Scalars['String']
  disableSiteSelector: Scalars['Boolean']
  header: Scalars['String']
  logo?: Maybe<Image>
  metaDescription?: Maybe<Scalars['String']>
  metaKeywords?: Maybe<Scalars['String']>
  noIndex: Scalars['Boolean']
  ogImage?: Maybe<Image>
  theme: Theme
  title: Scalars['String']
  type: PageType
  url: Scalars['String']
}

export type Breadcrumb = {
  displayName: Scalars['String']
  url: Scalars['String']
}

export type BusinessCustomer = {
  addresses?: Maybe<Array<Maybe<Address>>>
  assortmentGroupId: Scalars['Int']
  assortmentGroupName?: Maybe<Scalars['String']>
  assortments?: Maybe<Array<Maybe<CustomerAssortment>>>
  attestEnabled: Scalars['Boolean']
  bidGroupErpIdentifier?: Maybe<Scalars['String']>
  billingAddress?: Maybe<Address>
  blocked: Scalars['Boolean']
  businessType: Scalars['Int']
  cellPhone?: Maybe<Scalars['String']>
  chargeGroupErpIdentifier?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  createdInSystem?: Maybe<Scalars['String']>
  creditCheckAnswer?: Maybe<Scalars['String']>
  creditCheckProvider?: Maybe<Scalars['String']>
  creditLimit: Scalars['Decimal']
  creditLimitLastModifiedDateTimeUtc?: Maybe<Scalars['DateTime']>
  currencyCode?: Maybe<Scalars['String']>
  customerClassificationType: CustomerClassificationType
  customerGroupErpIdentifier?: Maybe<Scalars['String']>
  customerType: UserType
  deliveryModeErpIdentifier?: Maybe<Scalars['String']>
  deliveryModeOverride: Scalars['Boolean']
  deliveryStatus: CustomerQueryDeliveryStatus
  electronicAddresses?: Maybe<Array<Maybe<ElectronicAddress>>>
  electronicInvoiceReceiverList?: Maybe<Array<Maybe<ElectronicInvoiceReceiver>>>
  email?: Maybe<Scalars['String']>
  erpIdentifier?: Maybe<Scalars['String']>
  id: Scalars['Int']
  inactive: Scalars['Boolean']
  invoiceEmail?: Maybe<Scalars['String']>
  invoiceType: CustomerInvoiceType
  isCustomerReferenceMandatory: Scalars['Boolean']
  isGoodsMarkMandatory: Scalars['Boolean']
  isGuestCustomer: Scalars['Boolean']
  isInvoiceCostCenterMandatory: Scalars['Boolean']
  isPurchaseOrderNumberMandatory: Scalars['Boolean']
  name?: Maybe<Scalars['String']>
  offersByEmail: Scalars['Boolean']
  organizationNumber?: Maybe<Scalars['String']>
  paymentTermsErpIdentifier?: Maybe<Scalars['String']>
  priceGroupLineDiscount?: Maybe<Scalars['String']>
  priceGroupStatic?: Maybe<Scalars['String']>
  productFilterErpIdentifier?: Maybe<Scalars['String']>
  rentalAgreementId?: Maybe<Scalars['String']>
  rentalDisabled: Scalars['Boolean']
  rentalOfferId?: Maybe<Scalars['String']>
  sendCatalog: Scalars['Boolean']
  shippingAddress?: Maybe<Address>
  skiNumber?: Maybe<Scalars['String']>
  sourcingCompany: SourcingCompanyInput
  taxCategoryErpIdentifier?: Maybe<Scalars['String']>
  vatRegistrationNumber?: Maybe<Scalars['String']>
}

export enum ByUrlState {
  HasNoLogin = 'HAS_NO_LOGIN',
  IsLockedOut = 'IS_LOCKED_OUT',
  Ok = 'OK',
  UnexpectedError = 'UNEXPECTED_ERROR',
}

export enum CampaignType {
  Campaign = 'CAMPAIGN',
  LimitedBatch = 'LIMITED_BATCH',
  NewLowerPrice = 'NEW_LOWER_PRICE',
}

export enum CanContactCreateRmaStatus {
  ContactIsNotAllowed = 'CONTACT_IS_NOT_ALLOWED',
  Ok = 'OK',
  WrongPurchaseIsDeniedOnlyUnopen = 'WRONG_PURCHASE_IS_DENIED_ONLY_UNOPEN',
  WrongPurchaseIsDeniedOver60Days = 'WRONG_PURCHASE_IS_DENIED_OVER60_DAYS',
}

export type CancelContactInvitationRequestInput = {
  contactId: Scalars['Int']
}

export type CancelPreOrderRequestInput = {
  preOrderId: Scalars['Int']
}

export type Cart = {
  id: Scalars['Int']
  items: Array<CartItem>
}

export type CartItem = {
  comment?: Maybe<Scalars['String']>
  endUserInformation?: Maybe<Array<Maybe<EndUserInformation>>>
  id: Scalars['Int']
  isDependent: Scalars['Boolean']
  isMandatory: Scalars['Boolean']
  mainProductErpIdentifier?: Maybe<Scalars['String']>
  parentCartItemId?: Maybe<Scalars['Int']>
  product?: Maybe<Product>
  productErpIdentifier: Scalars['String']
  quantity: Scalars['Int']
}

export type CartItemInput = {
  lastViewedPriceExcludingVat?: InputMaybe<Scalars['Decimal']>
  lastViewedPriceIncludingVat?: InputMaybe<Scalars['Decimal']>
  lastViewedVatAmount?: InputMaybe<Scalars['Decimal']>
  parentCartItemId?: InputMaybe<Scalars['Int']>
  productErpIdentifier?: InputMaybe<Scalars['String']>
  quantity: Scalars['Int']
}

export type CartItemParamInput = {
  comment?: InputMaybe<Scalars['String']>
  mainProductErpIdentifier: Scalars['String']
  parentCartItemId?: InputMaybe<Scalars['Int']>
  productErpIdentifier: Scalars['String']
  quantity: Scalars['Int']
}

export type CartItemResult = {
  comment?: Maybe<Scalars['String']>
  id: Scalars['Int']
  isDependent: Scalars['Boolean']
  isMandatory: Scalars['Boolean']
  mainProductErpIdentifier?: Maybe<Scalars['String']>
  parentCartItemId: Scalars['Int']
  product?: Maybe<Product>
  productErpIdentifier: Scalars['String']
  quantity: Scalars['Int']
}

export type CartProduct = {
  availabilityInformation?: Maybe<AccountGraphql_Availability>
  displayName?: Maybe<Scalars['String']>
  freeFreight: Scalars['Boolean']
  isRentable: Scalars['Boolean']
  manufacturer?: Maybe<Scalars['String']>
  nameSlug?: Maybe<Scalars['String']>
  nonReturnable: Scalars['Boolean']
  price?: Maybe<CartProductPrice>
  priceDisplay?: Maybe<Scalars['String']>
  primaryImageId?: Maybe<Scalars['String']>
  primaryVendorId?: Maybe<Scalars['String']>
  productErpIdentifier?: Maybe<Scalars['String']>
  productForAvailability?: Maybe<Product>
  productType: ProductType
  wayOfTransport: WayOfTransport
}

export type CartProductPrice = {
  bidSourceLocally: Scalars['Boolean']
  excludingVat: Scalars['Decimal']
  includingVat: Scalars['Decimal']
  isBidPrice: Scalars['Boolean']
  serviceDuration?: Maybe<Scalars['Int']>
  vat: Scalars['Decimal']
}

export type CartTotals = {
  subTotals?: Maybe<Array<Maybe<SubTotal>>>
  totalExcludingVat: Scalars['Decimal']
  totalIncludingVat: Scalars['Decimal']
  totalQuantity: Scalars['Decimal']
  totalVat: Scalars['Decimal']
}

export type CaseDetailModel = {
  created: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  modified: Scalars['DateTime']
  orderErpIdentifier?: Maybe<Scalars['String']>
  productList?: Maybe<Array<Maybe<CaseDetailProductModel>>>
  products?: Maybe<Scalars['String']>
  status: CaseStatus
  statusReason: CaseStatusReason
  ticket?: Maybe<Scalars['String']>
  type: CaseType
}

export type CaseDetailProductModel = {
  description?: Maybe<Scalars['String']>
  productDetails?: Maybe<Product>
  productErpIdentifier?: Maybe<Scalars['String']>
  quantity: Scalars['Int']
}

export type CaseReferenceModel = {
  created: Scalars['DateTime']
  modified: Scalars['DateTime']
  orderErpIdentifier?: Maybe<Scalars['String']>
  status: CaseStatus
  statusReason: CaseStatusReason
  ticket?: Maybe<Scalars['String']>
  type: CaseType
}

export enum CaseStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  None = 'NONE',
  Resolved = 'RESOLVED',
}

export enum CaseStatusReason {
  AddressTagSent = 'ADDRESS_TAG_SENT',
  BackFromService = 'BACK_FROM_SERVICE',
  Canceled = 'CANCELED',
  ClosedWaitingForMoney = 'CLOSED_WAITING_FOR_MONEY',
  Created = 'CREATED',
  CreditOrReplacement = 'CREDIT_OR_REPLACEMENT',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  None = 'NONE',
  NoHandling = 'NO_HANDLING',
  ProblemSolved = 'PROBLEM_SOLVED',
  ProductReceived = 'PRODUCT_RECEIVED',
  ReferredToOtherDepartment = 'REFERRED_TO_OTHER_DEPARTMENT',
  ReferredToSupport = 'REFERRED_TO_SUPPORT',
  SentToServiceProvider = 'SENT_TO_SERVICE_PROVIDER',
  Solved = 'SOLVED',
  WaitingForDetails = 'WAITING_FOR_DETAILS',
}

export enum CaseType {
  DamagedInTransit = 'DAMAGED_IN_TRANSIT',
  DefectiveProduct = 'DEFECTIVE_PRODUCT',
  IncorrectDelivery = 'INCORRECT_DELIVERY',
  MissingDelivery = 'MISSING_DELIVERY',
  MissingProduct = 'MISSING_PRODUCT',
  None = 'NONE',
  WrongPurchase = 'WRONG_PURCHASE',
  WrongSpecification = 'WRONG_SPECIFICATION',
}

export type Category = {
  categoryIcon?: Maybe<Scalars['String']>
  categoryImageFileName?: Maybe<Scalars['String']>
  children?: Maybe<Array<Category>>
  classification?: Maybe<Scalars['String']>
  documentType?: Maybe<Scalars['String']>
  englishCategoryName?: Maybe<Scalars['String']>
  englishPathSlug?: Maybe<Scalars['String']>
  hasAccessoryGuideEnabled: Scalars['Boolean']
  id: Scalars['String']
  idPath?: Maybe<Scalars['String']>
  isLeaf: Scalars['Boolean']
  keyPrefix: Scalars['String']
  lastModified?: Maybe<Scalars['DateTime']>
  mostPopularProduct?: Maybe<ProductReference>
  name?: Maybe<Scalars['String']>
  namePath?: Maybe<Scalars['String']>
  navigation?: Maybe<NavigationByCategory>
  parentId?: Maybe<Scalars['String']>
  partitionKey?: Maybe<Scalars['String']>
  pathSlug: Scalars['String']
  productLists?: Maybe<CategoryProductLists>
  productSearch?: Maybe<ProductsByCategory>
  seoLongCategoryTitle?: Maybe<Array<Maybe<QualifiedValueOfString>>>
  seoLongTitleName?: Maybe<Scalars['String']>
  seoMetaDescription?: Maybe<Scalars['String']>
  seoText?: Maybe<Scalars['String']>
  seoTitle?: Maybe<Scalars['String']>
  sortOrder: Scalars['Int']
}

export type CategoryNavigationArgs = {
  facets?: InputMaybe<Array<InputMaybe<FacetParameterInput>>>
}

export type CategoryProductSearchArgs = {
  facets?: InputMaybe<Array<InputMaybe<FacetParameterInput>>>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  searchPhrase?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<SortBy>
}

export type CategoryAccessory = {
  articleAssortment: Scalars['String']
  articleAxCategory: Scalars['String']
  articleId: Scalars['String']
  articleManufacturerId: Scalars['String']
  axCategoryId?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  customerType?: Maybe<Scalars['String']>
  dependent: Scalars['Boolean']
  displayOrder: Scalars['Int']
  id?: Maybe<Scalars['String']>
  itemGroup: Scalars['String']
  mandatory: Scalars['Boolean']
  manufacturerId?: Maybe<Scalars['String']>
  maxPrice: Scalars['Decimal']
  minPrice: Scalars['Decimal']
  placement: Placement
  priority?: Maybe<Scalars['String']>
  productLifecycle?: Maybe<Array<ProductLifecycle>>
  sourcingCompany?: Maybe<Scalars['String']>
  specificationFilter?: Maybe<Scalars['String']>
  type: AccessoryType
}

export type CategoryByPath = {
  categoryId: Scalars['String']
  englishName?: Maybe<Scalars['String']>
  englishNamePath?: Maybe<Scalars['String']>
  englishPathSlug?: Maybe<Scalars['String']>
  hasVisibleProducts: Scalars['Boolean']
  idPath: Scalars['String']
  isLeaf: Scalars['Boolean']
  language: Language
  name: Scalars['String']
  namePath: Scalars['String']
  pathSlug: Scalars['String']
  productCount: Scalars['Int']
  seoTitle: Scalars['String']
  sortOrder: Scalars['Int']
  url?: Maybe<Scalars['String']>
}

export type CategoryDto = {
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  pageId: Scalars['Int']
  productCount: Scalars['Int']
}

export type CategoryNavigationItem = {
  category?: Maybe<Category>
  categoryDisplayName?: Maybe<Scalars['String']>
  categoryEnglishDisplayName?: Maybe<Scalars['String']>
  categoryId: Scalars['String']
  hits: Scalars['Int']
  productCategoryPimIdentifier?: Maybe<Scalars['Int']>
  selected: Scalars['Boolean']
  sortOrder: Scalars['Int']
  url?: Maybe<Scalars['String']>
}

export type CategoryNavigationTreeItem = {
  categoryDisplayName: Scalars['String']
  categoryEnglishDisplayName: Scalars['String']
  categoryId: Scalars['String']
  categoryIdPath: Scalars['String']
  categoryPathSlug: Scalars['String']
  children: Array<CategoryNavigationTreeItem>
  hits: Scalars['Int']
  selected: Scalars['Boolean']
  ticket: Scalars['String']
  url: Scalars['String']
}

export type CategoryProductLists = {
  ads: Array<AdContainer>
  bestsellers: ProductReferenceList
  campaignProducts: ProductReferenceList
  newProducts: ProductReferenceList
  popularAccessories: ProductReferenceList
}

export type CategoryProductListsBestsellersArgs = {
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type CategoryProductListsCampaignProductsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type CategoryProductListsNewProductsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type CategoryProductListsPopularAccessoriesArgs = {
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type ChangePasswordBasicResult = {
  ok: Scalars['Boolean']
}

export type ChangePasswordRequestInput = {
  confirmNewPassword?: InputMaybe<Scalars['String']>
  newPassword: Scalars['String']
  oldPassword: Scalars['String']
}

export enum Channel {
  B2B = 'B2B',
  B2Bb2C = 'B2BB2C',
  B2C = 'B2C',
  Undefined = 'UNDEFINED',
}

export enum ChargeType {
  ChemicalTax = 'CHEMICAL_TAX',
  Delivery = 'DELIVERY',
  DeliveryInsurance = 'DELIVERY_INSURANCE',
  DiscountCopy = 'DISCOUNT_COPY',
  DiscountCopyInternal = 'DISCOUNT_COPY_INTERNAL',
  DiscountVoucher = 'DISCOUNT_VOUCHER',
  DiscountVoucherOrderTotal = 'DISCOUNT_VOUCHER_ORDER_TOTAL',
  DiscountVoucherSelection = 'DISCOUNT_VOUCHER_SELECTION',
  DiscountVoucherShipping = 'DISCOUNT_VOUCHER_SHIPPING',
  InvoicePayment = 'INVOICE_PAYMENT',
  Kickback = 'KICKBACK',
  None = 'NONE',
}

export type CheckoutPaymentMethod = {
  available: Scalars['Boolean']
  creditLimitRequired: Scalars['Boolean']
  description?: Maybe<Scalars['String']>
  displayName?: Maybe<Scalars['String']>
  hasMonthlyCost: Scalars['Boolean']
  id: Scalars['Int']
  imageName?: Maybe<Scalars['String']>
  invoiceType: InvoiceType
  isCreditCard: Scalars['Boolean']
  isExpressDeliveryAllowed: Scalars['Boolean']
  isPayLater: Scalars['Boolean']
  isRental: Scalars['Boolean']
  monthlyCost?: Maybe<MonthlyCostDto>
  monthlyCostExcludingVat?: Maybe<Scalars['Decimal']>
  monthlyCostIncludingVat?: Maybe<Scalars['Decimal']>
  paymentCharge?: Maybe<PaymentCharge>
  paymentMethodGroupId: Scalars['Int']
  paymentServiceProvider?: Maybe<Scalars['String']>
  paymentServiceProviderParameters?: Maybe<
    Array<Maybe<PaymentMethodPaymentServiceProviderParameter>>
  >
  paymentTermsErpIdentifier?: Maybe<Scalars['String']>
  paymentType?: Maybe<Scalars['String']>
  requiresAddressLookup: Scalars['Boolean']
  requiresBillingAddress: Scalars['Boolean']
  requiresSocialSecurityNumber: Scalars['Boolean']
  selected: Scalars['Boolean']
  unavailableMessage?: Maybe<Scalars['String']>
}

export type CompactOrderDto = {
  grandTotalExcludingVat: Scalars['Decimal']
  grandTotalIncludingVat: Scalars['Decimal']
  invoiceDueDate?: Maybe<Scalars['DateTime']>
  invoiceDueDateExpired?: Maybe<Scalars['Boolean']>
  orderChipStatus?: Maybe<OrderChipStatus>
  orderDateUtc: Scalars['DateTime']
  orderErpIdentifier?: Maybe<Scalars['String']>
  orderId: Scalars['Int']
  orderItems?: Maybe<Array<Maybe<CompactOrderItemDto>>>
  orderState: OrderState
  paymentChipStatus?: Maybe<OrderPaymentChipStatus>
  paymentState: PaymentState
  preOrderId?: Maybe<Scalars['Int']>
  priceDisplay?: Maybe<Scalars['String']>
}

export type CompactOrderItemDto = {
  orderItemId: Scalars['Int']
  orderItemState: OrderItemState
}

export type CompactPreOrder = {
  createdDateTimeUtc: Scalars['DateTime']
  id: Scalars['Int']
  placedByFirstName?: Maybe<Scalars['String']>
  placedByLastName?: Maybe<Scalars['String']>
  status: PreOrderAttestState
}

export type Comparisons = {
  productLists: Array<ProductList>
}

export type ConnectedListContact = {
  companyName?: Maybe<Scalars['String']>
  customerClassificationType?: Maybe<CustomerClassificationType>
  customerErpIdentifier?: Maybe<Scalars['String']>
  customerId: Scalars['Int']
  email?: Maybe<Scalars['String']>
  erpId?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  id: Scalars['Int']
  isB2B: Scalars['Boolean']
  isBlocked: Scalars['Boolean']
  isInactive: Scalars['Boolean']
  isManaged: Scalars['Boolean']
  isPrimary: Scalars['Boolean']
  isPunchOut: Scalars['Boolean']
  isSuperUser: Scalars['Boolean']
  loginUrl?: Maybe<Scalars['String']>
  sourcingCompany: SourcingCompanyInput
  userIdentifier: Scalars['UUID']
}

export type ConsumerCustomer = {
  addresses?: Maybe<Array<Maybe<Address>>>
  assortmentGroupId: Scalars['Int']
  assortmentGroupName?: Maybe<Scalars['String']>
  assortments?: Maybe<Array<Maybe<CustomerAssortment>>>
  bidGroupErpIdentifier?: Maybe<Scalars['String']>
  blocked: Scalars['Boolean']
  chargeGroupErpIdentifier?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  createdInSystem?: Maybe<Scalars['String']>
  creditCheckAnswer?: Maybe<Scalars['String']>
  creditCheckProvider?: Maybe<Scalars['String']>
  creditLimit: Scalars['Decimal']
  creditLimitLastModifiedDateTimeUtc?: Maybe<Scalars['DateTime']>
  currencyCode?: Maybe<Scalars['String']>
  customerClassificationType: CustomerClassificationType
  customerGroupErpIdentifier?: Maybe<Scalars['String']>
  customerType: UserType
  deliveryModeErpIdentifier?: Maybe<Scalars['String']>
  deliveryModeOverride: Scalars['Boolean']
  deliveryStatus: CustomerQueryDeliveryStatus
  electronicAddresses?: Maybe<Array<Maybe<ElectronicAddress>>>
  electronicInvoiceReceiverList?: Maybe<Array<Maybe<ElectronicInvoiceReceiver>>>
  erpIdentifier?: Maybe<Scalars['String']>
  id: Scalars['Int']
  inactive: Scalars['Boolean']
  invoiceEmail?: Maybe<Scalars['String']>
  invoiceType: CustomerInvoiceType
  isGuestCustomer: Scalars['Boolean']
  name?: Maybe<Scalars['String']>
  offersByEmail: Scalars['Boolean']
  paymentTermsErpIdentifier?: Maybe<Scalars['String']>
  priceGroupLineDiscount?: Maybe<Scalars['String']>
  priceGroupStatic?: Maybe<Scalars['String']>
  productFilterErpIdentifier?: Maybe<Scalars['String']>
  rentalAgreementId?: Maybe<Scalars['String']>
  rentalOfferId?: Maybe<Scalars['String']>
  sendCatalog: Scalars['Boolean']
  socialSecurityNumber?: Maybe<Scalars['String']>
  sourcingCompany: SourcingCompanyInput
  taxCategoryErpIdentifier?: Maybe<Scalars['String']>
}

export type Contact = {
  accountTermsAccepted: Scalars['Boolean']
  addresses?: Maybe<Array<Maybe<Address>>>
  billingAddress?: Maybe<Address>
  blockWebLogin: Scalars['Boolean']
  businessCustomer?: Maybe<BusinessCustomer>
  businessCustomerId?: Maybe<Scalars['Int']>
  canSeeAllOrders: Scalars['Boolean']
  cannotManageAddresses: Scalars['Boolean']
  cellPhone?: Maybe<Scalars['String']>
  connectedContacts?: Maybe<Array<ConnectedListContact>>
  consumerCustomerId?: Maybe<Scalars['Int']>
  contactInvitationStatus?: Maybe<Scalars['String']>
  createdDateTimeUtc: Scalars['DateTime']
  createdInSystem?: Maybe<Scalars['String']>
  customer?: Maybe<Customer>
  customerReferenceCostCenter?: Maybe<Scalars['String']>
  customerReferenceName?: Maybe<Scalars['String']>
  electronicAddresses?: Maybe<Array<Maybe<ElectronicAddress>>>
  email?: Maybe<Scalars['String']>
  erpIdentifier?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  id: Scalars['Int']
  inactive: Scalars['Boolean']
  invoiceEmail?: Maybe<Scalars['String']>
  invoiceType: CustomerInvoiceType
  isPrimary: Scalars['Boolean']
  isPunchOutUser: Scalars['Boolean']
  languageCode?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  needsToAcceptPrivacyTerms: Scalars['Boolean']
  offersByEmail: Scalars['Boolean']
  phone?: Maybe<Scalars['String']>
  preCalculatePrices: Scalars['Boolean']
  preselectedBillingAddressId?: Maybe<Scalars['Int']>
  preselectedShippingAddressId?: Maybe<Scalars['Int']>
  preventFromPlacingOrders: Scalars['Boolean']
  priceFeedType?: Maybe<PriceFeedType>
  productFilterErpIdentifier?: Maybe<Scalars['String']>
  profilingAccepted: Scalars['Boolean']
  sendCatalog: Scalars['Boolean']
  sendSolutionsMagazine: Scalars['Boolean']
  shippingAddress?: Maybe<Address>
  sourcingCompany: SourcingCompanyInput
  superUser: Scalars['Boolean']
  suppressCampaignsAndAccessories: Scalars['Boolean']
  userIdentifier: Scalars['UUID']
  userType: UserType
  visibility?: Maybe<ContactVisibility>
}

export enum ContactInvitationStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  None = 'NONE',
  Pending = 'PENDING',
  Revoked = 'REVOKED',
}

export enum ContactInvitationValidationResult {
  AlreadyAccepted = 'ALREADY_ACCEPTED',
  AlreadyDeclined = 'ALREADY_DECLINED',
  InvalidActivationLink = 'INVALID_ACTIVATION_LINK',
  InvalidContact = 'INVALID_CONTACT',
  InvalidToken = 'INVALID_TOKEN',
  Ok = 'OK',
}

export type ContactModel = {
  cellphone?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type ContactVisibility = {
  showAddresses: Scalars['Boolean']
  showAttest: Scalars['Boolean']
  showContacts: Scalars['Boolean']
  showExternalOrders: Scalars['Boolean']
  showInvoices: Scalars['Boolean']
  showPreOrder: Scalars['Boolean']
  showServices: Scalars['Boolean']
}

export type ContactWithTypedCustomerResult = {
  attest?: Maybe<AttestModel>
  availableDisplayInvoiceTypes?: Maybe<Array<CustomerInvoiceType>>
  availableDisplayLanguages?: Maybe<Array<Language>>
  businessCustomer?: Maybe<BusinessCustomer>
  consumerCustomer?: Maybe<ConsumerCustomer>
  contact?: Maybe<Contact>
  contextUsername?: Maybe<Scalars['String']>
  language: Language
  showInvoiceTypeDropdown: Scalars['Boolean']
  username?: Maybe<Scalars['String']>
}

export type ContentLink = {
  isContentPageLink: Scalars['Boolean']
  openInNewWindow: Scalars['Boolean']
  text: Scalars['String']
  url: Scalars['String']
}

export type ContentPart = {
  container: ContentPartContainerType
  content?: Maybe<Scalars['String']>
}

export enum ContentPartContainerType {
  Fluid = 'FLUID',
  None = 'NONE',
  Normal = 'NORMAL',
}

export type ContentPartListType = {
  contentParts: Array<ContentPart>
}

export type CreateAddressRequestInput = {
  addressLine: Scalars['String']
  addressee: Scalars['String']
  billing: Scalars['Boolean']
  city: Scalars['String']
  department?: InputMaybe<Scalars['String']>
  goodsReceiverName?: InputMaybe<Scalars['String']>
  houseNumber?: InputMaybe<Scalars['String']>
  houseNumberSuffix?: InputMaybe<Scalars['String']>
  postalCode: Scalars['String']
  shipping: Scalars['Boolean']
  suppressValidation: Scalars['Boolean']
}

export type CreateAddressResult = {
  addressId: Scalars['Int']
}

export type CreateAttestGroupLevelCommandResult = {
  id: Scalars['Int']
  level: Scalars['Int']
}

export type CreateBannerDto = {
  logo?: Maybe<BannerDto>
  signedUrl?: Maybe<Scalars['String']>
}

export type CreateBannerRequestInput = {
  filename?: InputMaybe<Scalars['String']>
  filesize: Scalars['Int']
  pageId: Scalars['Int']
}

export type CreateCategoryRequestInput = {
  name?: InputMaybe<Scalars['String']>
  pageId: Scalars['Int']
}

export type CreateGroupLevelRequestInput = {
  groupId: Scalars['Int']
  limit: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
}

export type CreateGroupRequestInput = {
  name?: InputMaybe<Scalars['String']>
  useOnlyHighestAttestLevel: Scalars['Boolean']
}

export type CreateGroupResult = {
  result: Scalars['Int']
}

export type CreateLogoDto = {
  logo?: Maybe<LogoDto>
  signedUrl?: Maybe<Scalars['String']>
}

export type CreateLogoRequestInput = {
  filename?: InputMaybe<Scalars['String']>
  filesize: Scalars['Int']
  pageId: Scalars['Int']
}

export type CreatePageContentRequestInput = {
  content?: InputMaybe<Scalars['String']>
  header?: InputMaybe<Scalars['String']>
  lead?: InputMaybe<Scalars['String']>
  pageId: Scalars['Int']
}

export type CreateProductRequestInput = {
  categoryId: Scalars['Int']
  productErpIdentifier?: InputMaybe<Scalars['String']>
}

export type CreateRmaRequestInput = {
  caseType: CaseType
  description?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  orderErpIdentifier?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Array<InputMaybe<ProductModelInput>>>
}

export type CreateRmaResult = {
  canContactCreateRmaStatus: CanContactCreateRmaStatus
}

export enum Currency {
  Dkk = 'DKK',
  Eur = 'EUR',
  Nok = 'NOK',
  None = 'NONE',
  Sek = 'SEK',
}

export type Customer = {
  addresses?: Maybe<Array<Maybe<Address>>>
  assortmentGroupId: Scalars['Int']
  assortmentGroupName?: Maybe<Scalars['String']>
  assortments?: Maybe<Array<Maybe<CustomerAssortment>>>
  bidGroupErpIdentifier?: Maybe<Scalars['String']>
  blocked: Scalars['Boolean']
  chargeGroupErpIdentifier?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  createdInSystem?: Maybe<Scalars['String']>
  creditCheckAnswer?: Maybe<Scalars['String']>
  creditCheckProvider?: Maybe<Scalars['String']>
  creditLimit: Scalars['Decimal']
  creditLimitLastModifiedDateTimeUtc?: Maybe<Scalars['DateTime']>
  currencyCode?: Maybe<Scalars['String']>
  customerClassificationType: CustomerClassificationType
  customerGroupErpIdentifier?: Maybe<Scalars['String']>
  customerType: UserType
  deliveryModeErpIdentifier?: Maybe<Scalars['String']>
  deliveryModeOverride: Scalars['Boolean']
  deliveryStatus: CustomerQueryDeliveryStatus
  electronicAddresses?: Maybe<Array<Maybe<ElectronicAddress>>>
  electronicInvoiceReceiverList?: Maybe<Array<Maybe<ElectronicInvoiceReceiver>>>
  erpIdentifier?: Maybe<Scalars['String']>
  id: Scalars['Int']
  inactive: Scalars['Boolean']
  invoiceEmail?: Maybe<Scalars['String']>
  invoiceType: CustomerInvoiceType
  isGuestCustomer: Scalars['Boolean']
  name?: Maybe<Scalars['String']>
  offersByEmail: Scalars['Boolean']
  paymentTermsErpIdentifier?: Maybe<Scalars['String']>
  priceGroupLineDiscount?: Maybe<Scalars['String']>
  priceGroupStatic?: Maybe<Scalars['String']>
  productFilterErpIdentifier?: Maybe<Scalars['String']>
  rentalAgreementId?: Maybe<Scalars['String']>
  rentalOfferId?: Maybe<Scalars['String']>
  sendCatalog: Scalars['Boolean']
  sourcingCompany: SourcingCompanyInput
  taxCategoryErpIdentifier?: Maybe<Scalars['String']>
}

export type CustomerAssortment = {
  assortmentErpIdentifier?: Maybe<Scalars['String']>
  assortmentId: Scalars['Int']
  erpRecordIdentifier?: Maybe<Scalars['String']>
  id: Scalars['Int']
  licenseAgreementNumber?: Maybe<Scalars['String']>
}

export enum CustomerClassificationType {
  Corporate = 'CORPORATE',
  CurrencuGainLoss = 'CURRENCU_GAIN_LOSS',
  DkCorporate = 'DK_CORPORATE',
  DkHome = 'DK_HOME',
  DkPublic = 'DK_PUBLIC',
  DkSmbLarge = 'DK_SMB_LARGE',
  DkSmbMid = 'DK_SMB_MID',
  DkSmbSmall = 'DK_SMB_SMALL',
  FiCorporate = 'FI_CORPORATE',
  FiHome = 'FI_HOME',
  FiPublic = 'FI_PUBLIC',
  FiSmbLarge = 'FI_SMB_LARGE',
  FiSmbMid = 'FI_SMB_MID',
  FiSmbSmall = 'FI_SMB_SMALL',
  Home = 'HOME',
  IcInterCompany = 'IC_INTER_COMPANY',
  None = 'NONE',
  NoCorporate = 'NO_CORPORATE',
  NoHome = 'NO_HOME',
  NoLargeCorpDirect = 'NO_LARGE_CORP_DIRECT',
  NoPublic = 'NO_PUBLIC',
  NoSmbLarge = 'NO_SMB_LARGE',
  NoSmbMid = 'NO_SMB_MID',
  NoSmbSmall = 'NO_SMB_SMALL',
  ProMarketing = 'PRO_MARKETING',
  Public = 'PUBLIC',
  SeCorporate = 'SE_CORPORATE',
  SeHome = 'SE_HOME',
  SeIth = 'SE_ITH',
  SePublic = 'SE_PUBLIC',
  SeSmbLarge = 'SE_SMB_LARGE',
  SeSmbMid = 'SE_SMB_MID',
  SeSmbSmall = 'SE_SMB_SMALL',
  SmbLarge = 'SMB_LARGE',
  SmbMid = 'SMB_MID',
  SmbSmall = 'SMB_SMALL',
  Solutions = 'SOLUTIONS',
}

export type CustomerContact = {
  avatar?: Maybe<Image>
  email?: Maybe<Scalars['String']>
  name: Scalars['String']
  phone?: Maybe<Scalars['String']>
  title: Scalars['String']
}

export type CustomerContent = {
  categoryPaths?: Maybe<Array<Scalars['String']>>
  displayAsList: Scalars['Boolean']
  groupDisplayName?: Maybe<Scalars['String']>
  groupProductLists?: Maybe<Array<CustomerContentProductGroupingProductList>>
  linkBoxes?: Maybe<Array<CustomerContentLinkBox>>
  listProductIds?: Maybe<Array<Scalars['String']>>
  textContent?: Maybe<Scalars['String']>
  type: CustomerContentType
}

export type CustomerContentLinkBox = {
  alternativeImageUrl?: Maybe<Scalars['String']>
  image?: Maybe<Image>
  openInNewWindow: Scalars['Boolean']
  text: Scalars['String']
  url: Scalars['String']
}

export type CustomerContentProductGroupingProductList = {
  displayAsList: Scalars['Boolean']
  productIds: Array<Scalars['String']>
}

export enum CustomerContentType {
  CategoryLinkBoxes = 'CATEGORY_LINK_BOXES',
  LinkBoxes = 'LINK_BOXES',
  ProductGrouping = 'PRODUCT_GROUPING',
  ProductList = 'PRODUCT_LIST',
  Text = 'TEXT',
}

export type CustomerInformation = {
  cellphone?: Maybe<Scalars['String']>
  customerId: Scalars['Int']
  customerOrderCount: Scalars['Int']
  emailAddress?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  placedByContactId: Scalars['Int']
}

export enum CustomerInvoiceType {
  Email = 'EMAIL',
  EmailAndPaper = 'EMAIL_AND_PAPER',
  None = 'NONE',
  NoPrint = 'NO_PRINT',
  Paper = 'PAPER',
  Xml = 'XML',
  XmlAndEmail = 'XML_AND_EMAIL',
  XmlAndPdf = 'XML_AND_PDF',
}

export type CustomerLink = {
  displayName: Scalars['String']
  url: Scalars['String']
}

export type CustomerNavigation = {
  contacts: Array<CustomerContact>
  customerName: Scalars['String']
  documentsAndLinks: Array<CustomerLink>
  landingPageUrl: Scalars['String']
  logo?: Maybe<Image>
  parentCustomerLandingPageUrl?: Maybe<Scalars['String']>
  parentCustomerName?: Maybe<Scalars['String']>
  parentCustomerProductCategoryLinks: Array<CustomerLink>
  productCategoryLinks: Array<CustomerLink>
}

export type CustomerProductGroupPage = {
  breadcrumbs: Array<Breadcrumb>
  content: Array<CustomerContent>
  customerName: Scalars['String']
  customerNavigation?: Maybe<CustomerNavigation>
  disableSiteSelector: Scalars['Boolean']
  header: Scalars['String']
  logo?: Maybe<Image>
  metaDescription?: Maybe<Scalars['String']>
  metaKeywords?: Maybe<Scalars['String']>
  noIndex: Scalars['Boolean']
  ogImage?: Maybe<Image>
  theme: Theme
  title: Scalars['String']
  type: PageType
}

export enum CustomerQueryDeliveryStatus {
  Complete = 'COMPLETE',
  Continuous = 'CONTINUOUS',
  Intermediate = 'INTERMEDIATE',
  None = 'NONE',
}

export type CustomerServiceInquiry = {
  fields: Array<CustomerServiceInquiryField>
  title: Scalars['String']
}

export type CustomerServiceInquiryField = {
  allowMultipleSelections: Scalars['Boolean']
  label: Scalars['String']
  options?: Maybe<Array<Scalars['String']>>
  required: Scalars['Boolean']
  rows: Scalars['Int']
  type: QueryFieldType
  zendeskId?: Maybe<Scalars['String']>
}

export type CustomerServiceInquiryFieldInput = {
  isDropdownField?: InputMaybe<Scalars['Boolean']>
  label: Scalars['String']
  values: Array<Scalars['String']>
  zendeskId?: InputMaybe<Scalars['String']>
}

export type CustomerStartPage = {
  breadcrumbs: Array<Breadcrumb>
  content: Array<CustomerContent>
  customerName: Scalars['String']
  customerNavigation?: Maybe<CustomerNavigation>
  disableSiteSelector: Scalars['Boolean']
  logo?: Maybe<Image>
  metaDescription?: Maybe<Scalars['String']>
  metaKeywords?: Maybe<Scalars['String']>
  noIndex: Scalars['Boolean']
  ogImage?: Maybe<Image>
  theme: Theme
  title: Scalars['String']
  type: PageType
}

export type CxmlDataResult = {
  authorizationCode?: Maybe<Scalars['String']>
  contactId: Scalars['Int']
  customerLandingPage?: Maybe<Scalars['URL']>
  punchOutTicket: Scalars['UUID']
  sessionUserIdentifier: Scalars['UUID']
  state: CxmlDataState
}

export enum CxmlDataState {
  InvalidGuid = 'INVALID_GUID',
  InvalidSiteType = 'INVALID_SITE_TYPE',
  LoginNotFound = 'LOGIN_NOT_FOUND',
  MultipleContacts = 'MULTIPLE_CONTACTS',
  NoContacts = 'NO_CONTACTS',
  Ok = 'OK',
  TicketDoesNotExist = 'TICKET_DOES_NOT_EXIST',
}

export type CxmlPreDataResult = {
  xml?: Maybe<Scalars['String']>
}

export enum DatePeriodType {
  All = 'ALL',
  OneMonth = 'ONE_MONTH',
  OneWeek = 'ONE_WEEK',
  SixMonths = 'SIX_MONTHS',
  Specified = 'SPECIFIED',
  ThreeMonths = 'THREE_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS',
  TwoWeeks = 'TWO_WEEKS',
}

export type DeclineContactInvitationResult = {
  status: ContactInvitationValidationResult
}

export type DeleteAddressRequestInput = {
  addressId: Scalars['Int']
}

export type DeleteAddressesRequestInput = {
  addressIds?: InputMaybe<Array<Scalars['Int']>>
}

export type DeleteBannerRequestInput = {
  pageId: Scalars['Int']
}

export type DeleteCategoryRequestInput = {
  categoryId: Scalars['Int']
}

export type DeleteGroupLevelRequestInput = {
  id: Scalars['Int']
}

export type DeleteGroupRequestInput = {
  groupId: Scalars['Int']
}

export type DeleteLogoRequestInput = {
  pageId: Scalars['Int']
}

export type DeleteProductListResult = {
  id: Scalars['Int']
}

export type DeleteProductRequestInput = {
  categoryId: Scalars['Int']
  productErpIdentifiers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type Delivery = {
  deliveryDateUtc?: Maybe<Scalars['DateTime']>
  deliveryMethod?: Maybe<AccountGraphql_DeliveryMethod>
  deliveryPackages?: Maybe<Array<Maybe<DeliveryPackage>>>
  id: Scalars['Int']
}

export type DeliveryMethod = {
  description: Scalars['String']
  displayName: Scalars['String']
  shippingFee: Price
  shippingFeeExcludingVat: Scalars['Decimal']
  shippingFeeVat: Scalars['Decimal']
  shippingInsuranceFee: Price
  shippingInsuranceFeeExcludingVat: Scalars['Decimal']
  shippingInsuranceFeeVat: Scalars['Decimal']
  shippingWithInsuranceFee: Price
  totalFeeExcludingVat: Scalars['Decimal']
  totalFeeVat: Scalars['Decimal']
}

export type DeliveryMethodDto = {
  description?: Maybe<Scalars['String']>
  displayName?: Maybe<Scalars['String']>
  shippingFeeExcludingVat: Scalars['Decimal']
  shippingFeeVat: Scalars['Decimal']
  shippingInsuranceFeeExcludingVat: Scalars['Decimal']
  shippingInsuranceFeeVat: Scalars['Decimal']
  totalFeeExcludingVat: Scalars['Decimal']
  totalFeeVat: Scalars['Decimal']
}

export type DeliveryPackage = {
  id: Scalars['Int']
  trackingNumber?: Maybe<Scalars['String']>
  trackingUrl?: Maybe<Scalars['String']>
}

export type DidYouMean = {
  rank: Scalars['Int']
  relevance: Scalars['Decimal']
  ticket?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export enum Direction {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type EditorialPage = {
  body: Scalars['String']
  breadcrumbs: Array<Breadcrumb>
  container: EditorialPageContainerType
  content: Array<GridBlock>
  disableSiteSelector: Scalars['Boolean']
  metaDescription?: Maybe<Scalars['String']>
  metaKeywords?: Maybe<Scalars['String']>
  noIndex: Scalars['Boolean']
  ogImage?: Maybe<Image>
  theme: Theme
  title: Scalars['String']
  type: PageType
}

export enum EditorialPageContainerType {
  Fluid = 'FLUID',
  None = 'NONE',
  Normal = 'NORMAL',
  Styledust = 'STYLEDUST',
}

export type ElectronicAddress = {
  electronicAddressType: ElectronicAddressType
  erpIdentifier?: Maybe<Scalars['String']>
  id: Scalars['Int']
  isPrimary: Scalars['Boolean']
  value?: Maybe<Scalars['String']>
}

export enum ElectronicAddressType {
  Cellphone = 'CELLPHONE',
  Email = 'EMAIL',
  Fax = 'FAX',
  None = 'NONE',
  OfficialPhoneNumber = 'OFFICIAL_PHONE_NUMBER',
  Phone = 'PHONE',
  Telex = 'TELEX',
  Url = 'URL',
}

export type ElectronicInvoiceReceiver = {
  id: Scalars['Int']
  isDefault: Scalars['Boolean']
  referenceText?: Maybe<Scalars['String']>
  referenceValue?: Maybe<Scalars['String']>
}

export enum ElevateProductFilter {
  AssortmentId = 'ASSORTMENT_ID',
  CampaignB2B = 'CAMPAIGN_B2_B',
  CampaignB2C = 'CAMPAIGN_B2_C',
  CategoryNameDaDk = 'CATEGORY_NAME_DA_DK',
  CategoryNameEnUs = 'CATEGORY_NAME_EN_US',
  CategoryNameFiFi = 'CATEGORY_NAME_FI_FI',
  CategoryNameNbNo = 'CATEGORY_NAME_NB_NO',
  CategoryNameNlNl = 'CATEGORY_NAME_NL_NL',
  CategoryNameSvSe = 'CATEGORY_NAME_SV_SE',
  CategoryPathDaDk = 'CATEGORY_PATH_DA_DK',
  CategoryPathEnUs = 'CATEGORY_PATH_EN_US',
  CategoryPathFiFi = 'CATEGORY_PATH_FI_FI',
  CategoryPathNbNo = 'CATEGORY_PATH_NB_NO',
  CategoryPathNlNl = 'CATEGORY_PATH_NL_NL',
  CategoryPathSvSe = 'CATEGORY_PATH_SV_SE',
  CategoryPimId = 'CATEGORY_PIM_ID',
  DisplayLifecycleStatus = 'DISPLAY_LIFECYCLE_STATUS',
  DisplayManufacturerIsWhiteLabel = 'DISPLAY_MANUFACTURER_IS_WHITE_LABEL',
  DisplayNameDaDk = 'DISPLAY_NAME_DA_DK',
  DisplayNameEnUs = 'DISPLAY_NAME_EN_US',
  DisplayNameFiFi = 'DISPLAY_NAME_FI_FI',
  DisplayNameNbNo = 'DISPLAY_NAME_NB_NO',
  DisplayNameNlNl = 'DISPLAY_NAME_NL_NL',
  DisplayNameSvSe = 'DISPLAY_NAME_SV_SE',
  EcoresCategoryErpIdentifier = 'ECORES_CATEGORY_ERP_IDENTIFIER',
  EnergyClass = 'ENERGY_CLASS',
  EnergyClassImageId = 'ENERGY_CLASS_IMAGE_ID',
  EnergyDocumentId = 'ENERGY_DOCUMENT_ID',
  EtaDateTimeUtc = 'ETA_DATE_TIME_UTC',
  ImageIdentifier = 'IMAGE_IDENTIFIER',
  ImageUrl = 'IMAGE_URL',
  InstockForB2B = 'INSTOCK_FOR_B2_B',
  InstockForB2C = 'INSTOCK_FOR_B2_C',
  InternalProduct = 'INTERNAL_PRODUCT',
  IsAccessory = 'IS_ACCESSORY',
  IsAvailableForSale = 'IS_AVAILABLE_FOR_SALE',
  IsNewProduct = 'IS_NEW_PRODUCT',
  LineDiscountGroupErpIdentifier = 'LINE_DISCOUNT_GROUP_ERP_IDENTIFIER',
  ManufacturerErpIdentifier = 'MANUFACTURER_ERP_IDENTIFIER',
  ManufacturerName = 'MANUFACTURER_NAME',
  ManufacturerProductIdentifier = 'MANUFACTURER_PRODUCT_IDENTIFIER',
  MaxQuantityPerOrder = 'MAX_QUANTITY_PER_ORDER',
  MinQuantityPerOrder = 'MIN_QUANTITY_PER_ORDER',
  NameEnUs = 'NAME_EN_US',
  NameSvSe = 'NAME_SV_SE',
  ProductErpIdentifier = 'PRODUCT_ERP_IDENTIFIER',
  ProductKey = 'PRODUCT_KEY',
  ProductNewUntilDateTimeUtc = 'PRODUCT_NEW_UNTIL_DATE_TIME_UTC',
  PromotedB2B = 'PROMOTED_B2_B',
  PromotedB2C = 'PROMOTED_B2_C',
  PromotedSpecificationsDaDk = 'PROMOTED_SPECIFICATIONS_DA_DK',
  PromotedSpecificationsDaDkB2B = 'PROMOTED_SPECIFICATIONS_DA_DK_B2_B',
  PromotedSpecificationsDaDkB2C = 'PROMOTED_SPECIFICATIONS_DA_DK_B2_C',
  PromotedSpecificationsEnUs = 'PROMOTED_SPECIFICATIONS_EN_US',
  PromotedSpecificationsEnUsB2B = 'PROMOTED_SPECIFICATIONS_EN_US_B2_B',
  PromotedSpecificationsEnUsB2C = 'PROMOTED_SPECIFICATIONS_EN_US_B2_C',
  PromotedSpecificationsFiFi = 'PROMOTED_SPECIFICATIONS_FI_FI',
  PromotedSpecificationsFiFiB2B = 'PROMOTED_SPECIFICATIONS_FI_FI_B2_B',
  PromotedSpecificationsFiFiB2C = 'PROMOTED_SPECIFICATIONS_FI_FI_B2_C',
  PromotedSpecificationsNbNo = 'PROMOTED_SPECIFICATIONS_NB_NO',
  PromotedSpecificationsNbNoB2B = 'PROMOTED_SPECIFICATIONS_NB_NO_B2_B',
  PromotedSpecificationsNbNoB2C = 'PROMOTED_SPECIFICATIONS_NB_NO_B2_C',
  PromotedSpecificationsNlNl = 'PROMOTED_SPECIFICATIONS_NL_NL',
  PromotedSpecificationsNlNlB2B = 'PROMOTED_SPECIFICATIONS_NL_NL_B2_B',
  PromotedSpecificationsNlNlB2C = 'PROMOTED_SPECIFICATIONS_NL_NL_B2_C',
  PromotedSpecificationsSvSe = 'PROMOTED_SPECIFICATIONS_SV_SE',
  PromotedSpecificationsSvSeB2B = 'PROMOTED_SPECIFICATIONS_SV_SE_B2_B',
  PromotedSpecificationsSvSeB2C = 'PROMOTED_SPECIFICATIONS_SV_SE_B2_C',
  Rank = 'RANK',
  Relevance = 'RELEVANCE',
  ReviewCount = 'REVIEW_COUNT',
  ReviewScore = 'REVIEW_SCORE',
  ShortDescriptionDaDk = 'SHORT_DESCRIPTION_DA_DK',
  ShortDescriptionEnUs = 'SHORT_DESCRIPTION_EN_US',
  ShortDescriptionFiFi = 'SHORT_DESCRIPTION_FI_FI',
  ShortDescriptionNbNo = 'SHORT_DESCRIPTION_NB_NO',
  ShortDescriptionNlNl = 'SHORT_DESCRIPTION_NL_NL',
  ShortDescriptionSvSe = 'SHORT_DESCRIPTION_SV_SE',
  ShowAlwaysInStock = 'SHOW_ALWAYS_IN_STOCK',
  ShowEtaOnWeb = 'SHOW_ETA_ON_WEB',
  SourcingCompany = 'SOURCING_COMPANY',
  SpecificationInDisplayNameDaDk = 'SPECIFICATION_IN_DISPLAY_NAME_DA_DK',
  SpecificationInDisplayNameDaDkB2B = 'SPECIFICATION_IN_DISPLAY_NAME_DA_DK_B2_B',
  SpecificationInDisplayNameDaDkB2C = 'SPECIFICATION_IN_DISPLAY_NAME_DA_DK_B2_C',
  SpecificationInDisplayNameEnUs = 'SPECIFICATION_IN_DISPLAY_NAME_EN_US',
  SpecificationInDisplayNameEnUsB2B = 'SPECIFICATION_IN_DISPLAY_NAME_EN_US_B2_B',
  SpecificationInDisplayNameEnUsB2C = 'SPECIFICATION_IN_DISPLAY_NAME_EN_US_B2_C',
  SpecificationInDisplayNameFiFi = 'SPECIFICATION_IN_DISPLAY_NAME_FI_FI',
  SpecificationInDisplayNameFiFiB2B = 'SPECIFICATION_IN_DISPLAY_NAME_FI_FI_B2_B',
  SpecificationInDisplayNameFiFiB2C = 'SPECIFICATION_IN_DISPLAY_NAME_FI_FI_B2_C',
  SpecificationInDisplayNameNbNo = 'SPECIFICATION_IN_DISPLAY_NAME_NB_NO',
  SpecificationInDisplayNameNbNoB2B = 'SPECIFICATION_IN_DISPLAY_NAME_NB_NO_B2_B',
  SpecificationInDisplayNameNbNoB2C = 'SPECIFICATION_IN_DISPLAY_NAME_NB_NO_B2_C',
  SpecificationInDisplayNameNlNl = 'SPECIFICATION_IN_DISPLAY_NAME_NL_NL',
  SpecificationInDisplayNameNlNlB2B = 'SPECIFICATION_IN_DISPLAY_NAME_NL_NL_B2_B',
  SpecificationInDisplayNameNlNlB2C = 'SPECIFICATION_IN_DISPLAY_NAME_NL_NL_B2_C',
  SpecificationInDisplayNameSvSe = 'SPECIFICATION_IN_DISPLAY_NAME_SV_SE',
  SpecificationInDisplayNameSvSeB2B = 'SPECIFICATION_IN_DISPLAY_NAME_SV_SE_B2_B',
  SpecificationInDisplayNameSvSeB2C = 'SPECIFICATION_IN_DISPLAY_NAME_SV_SE_B2_C',
  SuppressExternalStock = 'SUPPRESS_EXTERNAL_STOCK',
}

export type ElevateProductFilterArgumentInput = {
  argumentName: ElevateProductFilter
  argumentValue?: InputMaybe<Scalars['String']>
}

export enum EmailResponseType {
  AlreadySent = 'ALREADY_SENT',
  EmailAddressAlreadyExists = 'EMAIL_ADDRESS_ALREADY_EXISTS',
  Failed = 'FAILED',
  None = 'NONE',
  PartialSucess = 'PARTIAL_SUCESS',
  Success = 'SUCCESS',
}

export enum EmailStatus {
  EmailExists = 'EMAIL_EXISTS',
  IsFederated = 'IS_FEDERATED',
  Valid = 'VALID',
}

export type EndUserInformation = {
  id: Scalars['Int']
  isMandatory: Scalars['Boolean']
  label?: Maybe<Scalars['String']>
  lineNumber: Scalars['Decimal']
  value?: Maybe<Scalars['String']>
}

export enum ErrorCode {
  GroupHasInvalidLevels = 'GROUP_HAS_INVALID_LEVELS',
  GroupHasNoLevels = 'GROUP_HAS_NO_LEVELS',
  GroupHasNoMembers = 'GROUP_HAS_NO_MEMBERS',
  LevelHasNoAttesters = 'LEVEL_HAS_NO_ATTESTERS',
}

export type ExtendedDisplay = {
  description: Scalars['String']
  descriptionIsMarkdown: Scalars['Boolean']
  files: Array<ProductMediaDocumentType>
  imageIds: Array<Scalars['String']>
  keySellingPoints: Array<Scalars['String']>
  productErpIdentifier: Scalars['String']
  seoText: Scalars['String']
}

export type ExternalOrderDto = {
  billingAddress?: Maybe<Scalars['String']>
  customerId: Scalars['Int']
  externalOrderNumber?: Maybe<Scalars['String']>
  grandTotalExcludingVat: Scalars['Decimal']
  grandTotalExcludingVatDisplay?: Maybe<Scalars['String']>
  grandTotalIncludingVat: Scalars['Decimal']
  id: Scalars['Int']
  orderDateUtc: Scalars['DateTime']
  placedById: Scalars['Int']
  shippingAddress?: Maybe<Scalars['String']>
  shippingCost: Scalars['Decimal']
}

export type ExternalOrderItemDto = {
  externalOrderId?: Maybe<Scalars['String']>
  id: Scalars['Int']
  priceValue: Scalars['Decimal']
  priceValueDisplay?: Maybe<Scalars['String']>
  priceVat: Scalars['Decimal']
  priceVatDisplay?: Maybe<Scalars['String']>
  productDisplayName?: Maybe<Scalars['String']>
  quantityOrdered: Scalars['Int']
  vendor?: Maybe<Scalars['String']>
}

export type ExternalStockAvailability = {
  leadTime: ExternalStockLeadTime
  quantity: Scalars['Int']
}

export enum ExternalStockLeadTime {
  Always = 'ALWAYS',
  FourSixDays = 'FOUR_SIX_DAYS',
  OneTwoDays = 'ONE_TWO_DAYS',
  SixEightDays = 'SIX_EIGHT_DAYS',
  TenTwelveDays = 'TEN_TWELVE_DAYS',
  ThreeFourDays = 'THREE_FOUR_DAYS',
}

export type Facet = {
  displayEnglishName: Scalars['String']
  displayName: Scalars['String']
  displayOrder: Scalars['Int']
  facetOpened: Scalars['Boolean']
  facetType: FacetType
  id: Scalars['String']
  isTranslated: Scalars['Boolean']
  name: Scalars['String']
  nameInUrl: Scalars['String']
  ticket: Scalars['String']
  totalNumberOfProducts: Scalars['Int']
  type: FacetType
  unitText: Scalars['String']
  values: Array<FacetValue>
}

export type FacetParameterInput = {
  name?: InputMaybe<Scalars['String']>
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export enum FacetType {
  Boolean = 'BOOLEAN',
  Multiple = 'MULTIPLE',
  Range = 'RANGE',
}

export type FacetValue = {
  displayName?: Maybe<Scalars['String']>
  hits: Scalars['Int']
  id: Scalars['String']
  max?: Maybe<Scalars['Decimal']>
  min?: Maybe<Scalars['Decimal']>
  name: Scalars['String']
  selected: Scalars['Boolean']
  ticket: Scalars['String']
}

export type Favorites = {
  productLists?: Maybe<Array<Maybe<ProductList>>>
  productRecommendations?: Maybe<Array<ProductReference>>
}

export enum Field {
  CustomerPurchaseOrderNumber = 'CUSTOMER_PURCHASE_ORDER_NUMBER',
  PlacedByFirstName = 'PLACED_BY_FIRST_NAME',
  PreOrderAttestedDate = 'PRE_ORDER_ATTESTED_DATE',
  PreOrderCreatedDate = 'PRE_ORDER_CREATED_DATE',
  PreOrderId = 'PRE_ORDER_ID',
}

export type Footer = {
  companyInfo: Scalars['String']
  contactInfo?: Maybe<Scalars['String']>
  navigationGroups: Array<FooterNavigationGroup>
  navigationGroups2: Array<FooterNavigationGroup>
  socialMediaLinks: SocialMediaLinks
}

export type FooterNavigationGroup = {
  header: Scalars['String']
  links: Array<ContentLink>
}

export type Ga4EventInput = {
  name?: InputMaybe<Scalars['String']>
  params?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>
}

export type Ga4Input = {
  clientId?: InputMaybe<Scalars['String']>
  events?: InputMaybe<Array<InputMaybe<Ga4EventInput>>>
}

export type GdprContact = {
  companyName?: Maybe<Scalars['String']>
  contactId: Scalars['Int']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
}

export type GdprErrand = {
  contact?: Maybe<GdprContact>
  createdDateTime?: Maybe<Scalars['DateTime']>
  status: GdprErrandStatus
}

export enum GdprErrandStatus {
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Invalid = 'INVALID',
  InProgress = 'IN_PROGRESS',
  None = 'NONE',
  Open = 'OPEN',
  WrongAccount = 'WRONG_ACCOUNT',
}

export type GetAddressSuggestionsRequestInput = {
  addressLine?: InputMaybe<Scalars['String']>
}

export type GetAddressSuggestionsResult = {
  addresses?: Maybe<Array<Maybe<AddressSuggestionModel>>>
}

export enum GetAddressesQuerySortByValue {
  Addressee = 'ADDRESSEE',
  AddressLine = 'ADDRESS_LINE',
  AddressType = 'ADDRESS_TYPE',
  Created = 'CREATED',
  Department = 'DEPARTMENT',
  GoodsReceiverName = 'GOODS_RECEIVER_NAME',
  LastUsed = 'LAST_USED',
  PostalCode = 'POSTAL_CODE',
}

export type GetAddressesRequestInput = {
  addressTypes?: InputMaybe<Array<AddressType>>
  pageNumber: Scalars['Int']
  pageSize: Scalars['Int']
  searchQuery?: InputMaybe<Scalars['String']>
  seeAllAddresses: Scalars['Boolean']
  sortBy: GetAddressesQuerySortByValue
  sortByAscending: Scalars['Boolean']
}

export type GetAddressesResult = {
  addresses?: Maybe<Array<Maybe<Address>>>
  numberOfPages: Scalars['Int']
  pageNumber: Scalars['Int']
  pageSize: Scalars['Int']
  totalCount: Scalars['Int']
}

export type GetAttestGroupInput = {
  groupId: Scalars['Int']
}

export type GetAttestGroupResult = {
  group?: Maybe<AttestGroup>
}

export type GetAttestSummaryResult = {
  preOrdersToAttest: Scalars['Int']
  preOrdersWaitingForAttest: Scalars['Int']
}

export type GetBrandInfoResult = {
  descriptionHtml: Scalars['String']
  logoUrl: Scalars['String']
  name: Scalars['String']
  url: Scalars['String']
}

export type GetBusinessCustomerSettingsResult = {
  businessCustomerId: Scalars['Int']
  enableCustomerPages: Scalars['Boolean']
  showFrameAgreementBanner: Scalars['Boolean']
}

export type GetByUrlDataResult = {
  customerLandingPage?: Maybe<Scalars['URL']>
  orderId?: Maybe<Scalars['Int']>
  state: ByUrlState
}

export type GetCampaignBannerResult = {
  buttonText: Scalars['String']
  colorTheme: Scalars['String']
  link?: Maybe<ContentLink>
  published: Scalars['DateTime']
  text: Scalars['String']
  title: Scalars['String']
  unpublished: Scalars['DateTime']
}

export type GetCartForCheckoutResult = {
  allItemsCanBeDeliveredImmediately: Scalars['Boolean']
  id: Scalars['Int']
  items?: Maybe<Array<Maybe<AccountGraphql_CartItem>>>
  totalPriceDisplay?: Maybe<Scalars['String']>
  totals?: Maybe<CartTotals>
}

export type GetCaseRequestInput = {
  ticket?: InputMaybe<Scalars['String']>
}

export type GetCaseResult = {
  caseDetail?: Maybe<CaseDetailModel>
}

export type GetCasesRequestInput = {
  endDateTimeUtc?: InputMaybe<Scalars['DateTime']>
  pageNumber: Scalars['Int']
  pageSize: Scalars['Int']
  searchPhrase?: InputMaybe<Scalars['String']>
  showAllCases: Scalars['Boolean']
  startDateTimeUtc?: InputMaybe<Scalars['DateTime']>
}

export type GetCasesResult = {
  caseReferences?: Maybe<Array<Maybe<CaseReferenceModel>>>
  totalCount: Scalars['Int']
}

export type GetCategoriesDto = {
  categories?: Maybe<Array<CategoryDto>>
  totalCount: Scalars['Int']
}

export type GetCompactOrdersResult = {
  orders?: Maybe<Array<CompactOrderDto>>
  pendingOrders: Scalars['Int']
}

export type GetCompactPreOrderResult = {
  preOrders?: Maybe<Array<Maybe<CompactPreOrder>>>
  preOrdersToAttest?: Maybe<Array<Maybe<CompactPreOrder>>>
}

export type GetCompanyInfoQueryResult = {
  companyName?: Maybe<Scalars['String']>
  disallowNewContacts: Scalars['Boolean']
  isInvalidOrganizationNumber: Scalars['Boolean']
}

export type GetContactsForContactSelectorDto = {
  accountAndPrivacyTermsState: AccountAndPrivacyTermsState
  isFavorite: Scalars['Boolean']
  lastLoginDateTimeUtc?: Maybe<Scalars['DateTime']>
  needsToAcceptPrivacyTerms: Scalars['Boolean']
}

export type GetCustomerPageCategoriesRequestInput = {
  pageNumber: Scalars['Int']
  pageSize: Scalars['Int']
}

export type GetCustomerPageProductsRequestInput = {
  categoryId: Scalars['Int']
  pageNumber: Scalars['Int']
  pageSize: Scalars['Int']
}

export type GetExternalOrderItemsRequestInput = {
  externalOrderId: Scalars['Int']
}

export type GetExternalOrderItemsResult = {
  orderItems?: Maybe<Array<Maybe<ExternalOrderItemDto>>>
}

export type GetExternalOrdersResult = {
  orders?: Maybe<Array<Maybe<ExternalOrderDto>>>
}

export type GetInvitedContactResult = {
  LoginStatus?: Maybe<InvitedContactLoginStatus>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  status: ContactInvitationValidationResult
}

export type GetInvoiceCopyRequestInput = {
  invoiceId: Scalars['Int']
  sourcingCompany: SourcingCompanyInput
}

export type GetInvoiceCopyResult = {
  contentType?: Maybe<Scalars['String']>
  fileContent?: Maybe<Array<Scalars['Byte']>>
}

export type GetInvoiceDetailsRequestInput = {
  invoiceId: Scalars['Int']
  seeAllInvoices: Scalars['Boolean']
  sourcingCompany: SourcingCompanyInput
}

export type GetInvoiceDetailsResult = {
  charges?: Maybe<Array<Maybe<InvoiceCharge>>>
  createdDateUtc: Scalars['DateTime']
  creditingInvoiceNumber?: Maybe<Scalars['String']>
  currencyCode?: Maybe<Scalars['String']>
  customerErpIdentifier?: Maybe<Scalars['String']>
  dueDateExpired: Scalars['Boolean']
  dueDateUtc?: Maybe<Scalars['DateTime']>
  externalInvoiceReferenceNumber?: Maybe<Scalars['String']>
  grandTotalExcludingVat: Scalars['Decimal']
  grandTotalIncludingVat: Scalars['Decimal']
  grandTotalRoundOff: Scalars['Decimal']
  grandTotalVat: Scalars['Decimal']
  invoiceErpIdentifier?: Maybe<Scalars['String']>
  invoiceItemsAmountExcludingVat: Scalars['Decimal']
  invoiceItemsVatAmount: Scalars['Decimal']
  invoiceOrders?: Maybe<Array<Maybe<Order>>>
  isCreditInvoice: Scalars['Boolean']
  isReadyForDownload: Scalars['Boolean']
  items?: Maybe<Array<Maybe<InvoiceItem>>>
  ocrNumber?: Maybe<Scalars['String']>
  orderPaymentState: PaymentState
  paymentMethodId: Scalars['Int']
  paymentState: PaymentState
  priceDisplay?: Maybe<InvoiceDetailPriceModel>
  pricesArePerMonth: Scalars['Boolean']
}

export type GetInvoiceSummaryResult = {
  overdue: Scalars['Int']
  unpaid: Scalars['Int']
}

export enum GetInvoicesQuerySearchByValue {
  All = 'ALL',
  InvoiceErpIdentifier = 'INVOICE_ERP_IDENTIFIER',
  None = 'NONE',
  OcrNumber = 'OCR_NUMBER',
  OrderErpIdentifier = 'ORDER_ERP_IDENTIFIER',
}

export enum GetInvoicesQuerySortByValue {
  CreatedDate = 'CREATED_DATE',
  DueDate = 'DUE_DATE',
  GrandTotal = 'GRAND_TOTAL',
  InvoiceErpIdentifier = 'INVOICE_ERP_IDENTIFIER',
  None = 'NONE',
  OcrNumber = 'OCR_NUMBER',
}

export type GetInvoicesRequestInput = {
  datePeriod?: InputMaybe<DatePeriodType>
  endDateTimeUtc?: InputMaybe<Scalars['DateTime']>
  invoiceIds?: InputMaybe<Array<Scalars['Int']>>
  pageNumber?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  searchBy: GetInvoicesQuerySearchByValue
  searchPhrase?: InputMaybe<Scalars['String']>
  seeAllInvoices: Scalars['Boolean']
  showOnlyUnpaid: Scalars['Boolean']
  sortBy: GetInvoicesQuerySortByValue
  sortByAscendingOrder: Scalars['Boolean']
  sourcingCompany: SourcingCompanyInput
  startDateTimeUtc?: InputMaybe<Scalars['DateTime']>
}

export type GetInvoicesResult = {
  rows?: Maybe<Array<Maybe<InvoiceRow>>>
  totalCount: Scalars['Int']
}

export type GetLatestBoughtProductsDto = {
  product?: Maybe<Product>
  productErpIdentifier?: Maybe<Scalars['String']>
}

export type GetLatestBoughtProductsResult = {
  products?: Maybe<Array<Maybe<GetLatestBoughtProductsDto>>>
}

export enum GetManageableContactsQuerySortBy {
  BlockWebLogin = 'BLOCK_WEB_LOGIN',
  CannotManageAddresses = 'CANNOT_MANAGE_ADDRESSES',
  CanSeeAllOrders = 'CAN_SEE_ALL_ORDERS',
  ContactInvitationDateUtc = 'CONTACT_INVITATION_DATE_UTC',
  ContactInvitationStatus = 'CONTACT_INVITATION_STATUS',
  Email = 'EMAIL',
  Name = 'NAME',
  SuperUser = 'SUPER_USER',
}

export type GetManageableContactsRequestInput = {
  contactInvitationStatuses?: InputMaybe<Array<ContactInvitationStatus>>
  pageNumber: Scalars['Int']
  pageSize: Scalars['Int']
  searchQuery?: InputMaybe<Scalars['String']>
  sortBy: GetManageableContactsQuerySortBy
  sortByAscending: Scalars['Boolean']
}

export type GetManageableContactsResult = {
  manageableContacts?: Maybe<Array<Maybe<Contact>>>
  totalCount: Scalars['Int']
}

export type GetOffersByEmailStatusResult = {
  accepted: Scalars['Boolean']
}

export type GetOrderConfirmationDetailRequestInput = {
  orderId: Scalars['Int']
  token?: InputMaybe<Scalars['String']>
}

export type GetOrderDetailsForRmaRequestInput = {
  orderId: Scalars['Int']
}

export type GetOrderDetailsRequestInput = {
  orderId: Scalars['Int']
  seeAllOrders: Scalars['Boolean']
}

export type GetOrderDetailsResult = {
  accessories?: Maybe<AccountGraphql_Accessories>
  billingAddress?: Maybe<OrderDetailsShippingAddress>
  billingAddressId?: Maybe<Scalars['Int']>
  canCreateRma: Scalars['Boolean']
  canPayOrder: Scalars['Boolean']
  canRequestPartialDelivery: Scalars['Boolean']
  canResendPaymentConfirmation: Scalars['Boolean']
  charges?: Maybe<Array<Maybe<OrderDetailsCharge>>>
  createdDateTimeUtc: Scalars['DateTime']
  currencyCode: Currency
  customerInformation?: Maybe<CustomerInformation>
  deliveries?: Maybe<Array<Maybe<Delivery>>>
  deliveryMethodId: Scalars['Int']
  deliveryModeErpIdentifier?: Maybe<Scalars['String']>
  deliveryStatus: CustomerQueryDeliveryStatus
  grandTotalExcludingVat: Scalars['Decimal']
  grandTotalVat: Scalars['Decimal']
  invoiceInformation?: Maybe<InvoiceInformation>
  invoices?: Maybe<Array<Maybe<Invoice>>>
  items?: Maybe<Array<Maybe<OrderItem>>>
  lastPartialDeliveryRequestDateTime?: Maybe<Scalars['DateTime']>
  latestPaymentState?: Maybe<OrderPaymentState>
  orderErpIdentifier?: Maybe<Scalars['String']>
  orderId: Scalars['Int']
  orderState: OrderState
  orderTotalExcludingVat: Scalars['Decimal']
  orderTotalVat: Scalars['Decimal']
  otherInformation?: Maybe<OtherInformation>
  paymentStates?: Maybe<Array<Maybe<OrderPaymentState>>>
  paymentType?: Maybe<Scalars['String']>
  placedByContactId: Scalars['Int']
  preOrderId?: Maybe<Scalars['Int']>
  preOrderResult?: Maybe<GetPreOrderV2Result>
  pricesArePerMonth: Scalars['Boolean']
  sentToErp: Scalars['Boolean']
  shippingAddress?: Maybe<OrderDetailsShippingAddress>
  shippingAddressId: Scalars['Int']
  showPartialDeliveryNotAvailable: Scalars['Boolean']
}

export type GetOrderHistoryForProductDto = {
  canCreateRma: Scalars['Boolean']
  createdDateTimeUtc?: Maybe<Scalars['DateTime']>
  currencyCode?: Maybe<Scalars['String']>
  id: Scalars['Int']
  orderErpIdentifier?: Maybe<Scalars['String']>
  orderItemState: OrderItemState
  orderState: OrderState
  priceDisplay?: Maybe<PriceModel>
  priceValue: Scalars['Decimal']
  priceVat: Scalars['Decimal']
  pricesArePerMonth: Scalars['Boolean']
  quantityAllocated: Scalars['Int']
  quantityOrdered: Scalars['Int']
  totalPriceValue: Scalars['Decimal']
  totalPriceVat: Scalars['Decimal']
}

export type GetOrderHistoryForProductRequestInput = {
  productErpIdentifier?: InputMaybe<Scalars['String']>
  seeAllOrders: Scalars['Boolean']
}

export type GetOrderHistoryForProductResult = {
  orders?: Maybe<Array<Maybe<GetOrderHistoryForProductDto>>>
  quantities?: Maybe<ProductHistoryQuantityModel>
}

export enum GetOrderHistoryV2QuerySearchByValue {
  All = 'ALL',
  ContactName = 'CONTACT_NAME',
  CustomerPurchaseOrderNumber = 'CUSTOMER_PURCHASE_ORDER_NUMBER',
  None = 'NONE',
  OrderErpIdentifier = 'ORDER_ERP_IDENTIFIER',
  ProductDisplayName = 'PRODUCT_DISPLAY_NAME',
  ProductErpIdentifier = 'PRODUCT_ERP_IDENTIFIER',
  SerialNumber = 'SERIAL_NUMBER',
}

export enum GetOrderHistoryV2QuerySortByValue {
  ContactName = 'CONTACT_NAME',
  CustomerPurchaseOrderNumber = 'CUSTOMER_PURCHASE_ORDER_NUMBER',
  GrandTotal = 'GRAND_TOTAL',
  None = 'NONE',
  OrderDate = 'ORDER_DATE',
  OrderErpIdentifier = 'ORDER_ERP_IDENTIFIER',
  OrderState = 'ORDER_STATE',
  PaymentTypeName = 'PAYMENT_TYPE_NAME',
}

export type GetOrderHistoryV2Result = {
  orders?: Maybe<Array<Maybe<OrderDto>>>
  totalCount: Scalars['Int']
}

export type GetOrdersRequestInput = {
  datePeriod?: InputMaybe<DatePeriodType>
  endDateTimeUtc?: InputMaybe<Scalars['DateTime']>
  excludeCancelled: Scalars['Boolean']
  orderIds?: InputMaybe<Array<Scalars['Int']>>
  pageNumber?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  searchBy: GetOrderHistoryV2QuerySearchByValue
  searchPhrase?: InputMaybe<Scalars['String']>
  seeAllOrders: Scalars['Boolean']
  showOnlyOpenOrders: Scalars['Boolean']
  showOnlyRmaOrders: Scalars['Boolean']
  sortBy: GetOrderHistoryV2QuerySortByValue
  sortByAscendingOrder: Scalars['Boolean']
  startDateTimeUtc?: InputMaybe<Scalars['DateTime']>
}

export type GetPreOrderInput = {
  preOrderId: Scalars['Int']
}

export type GetPreOrderV2Result = {
  information?: Maybe<PreOrderInformationModel>
  preOrder?: Maybe<PreOrderV2>
}

export enum GetProductHistoryQuerySearchByValue {
  All = 'ALL',
  None = 'NONE',
  OrderErpIdentifier = 'ORDER_ERP_IDENTIFIER',
  ProductDisplayName = 'PRODUCT_DISPLAY_NAME',
  ProductErpIdentifier = 'PRODUCT_ERP_IDENTIFIER',
}

export enum GetProductHistoryQuerySortByValue {
  None = 'NONE',
  OrderItemCreatedDate = 'ORDER_ITEM_CREATED_DATE',
  ProductDisplayName = 'PRODUCT_DISPLAY_NAME',
  ProductErpIdentifier = 'PRODUCT_ERP_IDENTIFIER',
}

export type GetProductHistoryRequestInput = {
  datePeriod?: InputMaybe<DatePeriodType>
  endDateTimeUtc?: InputMaybe<Scalars['DateTime']>
  excludeCancelled: Scalars['Boolean']
  pageNumber?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  searchBy: GetProductHistoryQuerySearchByValue
  searchPhrase?: InputMaybe<Scalars['String']>
  seeAllOrders: Scalars['Boolean']
  showOnlyOpenOrders: Scalars['Boolean']
  sortBy: GetProductHistoryQuerySortByValue
  sortByAscendingOrder: Scalars['Boolean']
  startDateTimeUtc?: InputMaybe<Scalars['DateTime']>
}

export type GetProductHistoryResult = {
  productErpIdentifiers?: Maybe<Array<Maybe<Scalars['String']>>>
  products?: Maybe<Array<Maybe<ProductHistoryDto>>>
  totalCount: Scalars['Int']
}

export type GetProductListsResult = {
  productLists?: Maybe<Array<Maybe<AccountGraphql_ProductList>>>
}

export type GetProductsDto = {
  products?: Maybe<Array<Maybe<ProductDto>>>
  totalCount: Scalars['Int']
}

export type GetSarReportContentResult = {
  contentType?: Maybe<Scalars['String']>
  fileContent?: Maybe<Array<Scalars['Byte']>>
  fileName?: Maybe<Scalars['String']>
}

export type GetUspResult = {
  usps: Array<Usp>
}

export type GoogleAnalytics = {
  apiSecret?: Maybe<Scalars['String']>
  measurementId?: Maybe<Scalars['String']>
}

export type GoogleAnalyticsPurchase = {
  coupon?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  deliveryStatus?: Maybe<Scalars['String']>
  items?: Maybe<Array<Maybe<GoogleAnalyticsPurchaseItem>>>
  shippingCost: Scalars['Decimal']
  shippingMethod?: Maybe<Scalars['String']>
  tax: Scalars['Decimal']
  transactionId?: Maybe<Scalars['String']>
  value: Scalars['Decimal']
}

export type GoogleAnalyticsPurchaseItem = {
  brand?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  category2?: Maybe<Scalars['String']>
  category3?: Maybe<Scalars['String']>
  category4?: Maybe<Scalars['String']>
  coupon?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  price: Scalars['Decimal']
  quantity: Scalars['Int']
}

export type GridBlock = {
  areas: Array<Area>
  columnSpan: Scalars['Int']
  properties: Scalars['Any']
  type: Scalars['String']
}

export type GroupMemberRequestInput = {
  contactId: Scalars['Int']
  groupId: Scalars['Int']
}

export type Image = {
  height: Scalars['Int']
  relativeUrl: Scalars['String']
  url: Scalars['String']
}

export type InternalEtaStockAvailability = {
  eta: Scalars['DateTime']
  quantity: Scalars['Int']
}

export type InternalStockAvailability = {
  quantity: Scalars['Int']
  showAlwaysInStock: Scalars['Boolean']
}

export type InvitedContactLoginStatus = {
  hasLogin: Scalars['Boolean']
  showLogin: Scalars['Boolean']
  showLoginOtherAccount: Scalars['Boolean']
}

export type Invoice = {
  dueDateExpired: Scalars['Boolean']
  externalInvoiceReferenceNumber?: Maybe<Scalars['String']>
  id: Scalars['Int']
  invoiceDueDateUtc?: Maybe<Scalars['DateTime']>
  invoiceErpIdentifier?: Maybe<Scalars['String']>
  isReadyForDownload: Scalars['Boolean']
  paymentState: PaymentState
}

export type InvoiceCharge = {
  amount: Scalars['Decimal']
  chargeType: ChargeType
  id: Scalars['Int']
  priceDisplay?: Maybe<Scalars['String']>
  vatAmount: Scalars['Decimal']
}

export type InvoiceDetailPriceModel = {
  grandTotalExcludingVat?: Maybe<Scalars['String']>
  grandTotalIncludingVat?: Maybe<Scalars['String']>
  roundOff?: Maybe<Scalars['String']>
  vat?: Maybe<Scalars['String']>
}

export type InvoiceInformation = {
  electronicInvoiceReceiverReference?: Maybe<Scalars['String']>
  invoiceEmailAddress?: Maybe<Scalars['String']>
  invoiceType?: Maybe<Scalars['String']>
}

export type InvoiceItem = {
  charges?: Maybe<Array<Maybe<InvoiceCharge>>>
  id: Scalars['Int']
  itemPrice: Scalars['Decimal']
  nameSlug?: Maybe<Scalars['String']>
  priceDisplay?: Maybe<PriceModel>
  productDisplayName?: Maybe<Scalars['String']>
  productErpIdentifier?: Maybe<Scalars['String']>
  quantity: Scalars['Int']
  rowPrice: Scalars['Decimal']
  rowVat: Scalars['Decimal']
  rowVatDisplay?: Maybe<Scalars['String']>
}

export type InvoiceOrder = {
  customerPurchaseOrderNumber?: Maybe<Scalars['String']>
  id: Scalars['Int']
  orderErpIdentifier?: Maybe<Scalars['String']>
}

export type InvoiceRow = {
  createdDateUtc: Scalars['DateTime']
  currencyCode?: Maybe<Scalars['String']>
  customerErpIdentifier?: Maybe<Scalars['String']>
  dueDateExpired: Scalars['Boolean']
  dueDateUtc?: Maybe<Scalars['DateTime']>
  externalInvoiceReferenceNumber?: Maybe<Scalars['String']>
  grandTotalExcludingVat: Scalars['Decimal']
  grandTotalIncludingVat: Scalars['Decimal']
  id: Scalars['Int']
  invoiceErpIdentifier?: Maybe<Scalars['String']>
  invoiceOrders?: Maybe<Array<Maybe<InvoiceOrder>>>
  isCreditInvoice: Scalars['Boolean']
  isReadyForDownload: Scalars['Boolean']
  ocrNumber?: Maybe<Scalars['String']>
  orderPaymentState: PaymentState
  paymentState: PaymentState
  priceDisplay?: Maybe<Scalars['String']>
  priceDisplayForExport?: Maybe<Scalars['String']>
  pricesArePerMonth: Scalars['Boolean']
}

export enum InvoiceType {
  Email = 'EMAIL',
  EmailAndPaper = 'EMAIL_AND_PAPER',
  None = 'NONE',
  NoPrint = 'NO_PRINT',
  Paper = 'PAPER',
  Xml = 'XML',
  XmlAndEmail = 'XML_AND_EMAIL',
  XmlAndPdf = 'XML_AND_PDF',
}

export type IsAttesterResult = {
  isAttester: Scalars['Boolean']
}

export type KeyValuePairOfInt32AndString = {
  key: Scalars['Int']
  value: Scalars['String']
}

export type KeyValuePairOfStringAndStringInput = {
  key: Scalars['String']
  value: Scalars['String']
}

export enum Language {
  Danish = 'DANISH',
  Dutch = 'DUTCH',
  English = 'ENGLISH',
  Finnish = 'FINNISH',
  None = 'NONE',
  Norwegian = 'NORWEGIAN',
  Swedish = 'SWEDISH',
}

export type LatestBoughtProduct = {
  assortment?: Maybe<Scalars['String']>
  availability?: Maybe<AccountGraphql_Availability>
  categoryName?: Maybe<Scalars['String']>
  chargeGroupErpIdentifier?: Maybe<Scalars['String']>
  currentPrice?: Maybe<ProductHistoryPriceModel>
  displayName: Scalars['String']
  displaySpecifications: Scalars['String']
  ecoResCategoryErpIdentifier: Scalars['String']
  ecoResCategoryName?: Maybe<Scalars['String']>
  energyClass?: Maybe<Scalars['String']>
  energyDocumentId?: Maybe<Scalars['String']>
  energyLabelImageId?: Maybe<Scalars['String']>
  freeFreight: Scalars['Boolean']
  fullDisplayName: Scalars['String']
  id: Scalars['String']
  internalProduct: Scalars['Boolean']
  isDemoProduct: Scalars['Boolean']
  isHiddenFromSearchEngines: Scalars['Boolean']
  isNewProduct: Scalars['Boolean']
  isRentable: Scalars['Boolean']
  lastModifiedDateTimeUtc: Scalars['DateTime']
  lineDiscountGroupErpIdentifier: Scalars['String']
  lotQuantity?: Maybe<Scalars['Int']>
  manufacturerErpIdentifier: Scalars['String']
  manufacturerName: Scalars['String']
  manufacturerProductIdentifier?: Maybe<Scalars['String']>
  maxQuantityPerOrder: Scalars['Int']
  minQuantityPerOrder: Scalars['Int']
  nameSlug: Scalars['String']
  nonReturnable: Scalars['Boolean']
  oneLiner?: Maybe<Scalars['String']>
  price?: Maybe<AccountGraphql_Price>
  primaryImageId?: Maybe<Scalars['String']>
  primaryServiceProductErpIdentifier?: Maybe<Scalars['String']>
  productCategoryPimIdentifier: Scalars['String']
  productErpIdentifier: Scalars['String']
  productType: ProductType
  promotedSpecifications: Array<SpecificationForDisplayDto>
  reviewCount: Scalars['Int']
  reviewScore: Scalars['Int']
  showAlwaysInStock: Scalars['Boolean']
  wayOfTransport: WayOfTransport
}

export type LogoDto = {
  fileName?: Maybe<Scalars['String']>
  filePath?: Maybe<Scalars['String']>
  fileSize: Scalars['Int']
  pageId: Scalars['Int']
}

export type MainNavigation = {
  menuItems: Array<MenuNavigationItem>
}

export type MakeContactInactiveRequestInput = {
  contactId: Scalars['Int']
}

export type ManufacturerProductSearch = {
  facets: Array<Facet>
  navigationTree: Array<CategoryNavigationTreeItem>
  productHits: Scalars['Int']
  productReferences: Array<ProductReference>
}

export type Media = {
  extension?: Maybe<Scalars['String']>
  fileType?: Maybe<Scalars['String']>
  filename?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  language?: Maybe<Scalars['String']>
  sortOrder: Scalars['Int']
  source?: Maybe<Scalars['String']>
  type: MediaType
}

export enum MediaType {
  Coc = 'COC',
  Document = 'DOCUMENT',
  DoC = 'DO_C',
  EnergyDocument = 'ENERGY_DOCUMENT',
  EnergyLabel = 'ENERGY_LABEL',
  Image = 'IMAGE',
  Normal = 'NORMAL',
  ProductDataSheetBrochure = 'PRODUCT_DATA_SHEET_BROCHURE',
  QuickStartGuide = 'QUICK_START_GUIDE',
  RoHs = 'RO_HS',
  Un38 = 'UN38',
  UserManual = 'USER_MANUAL',
}

export type MenuNavigationItem = {
  displayName: Scalars['String']
  html: Scalars['String']
  iconName?: Maybe<Scalars['String']>
  inVariant: Scalars['Boolean']
  isSelected: Scalars['Boolean']
  subNavigationItems: Array<MenuSubNavigationItem>
  type: MenuNavigationItemType
  url?: Maybe<Scalars['String']>
}

export enum MenuNavigationItemType {
  Brands = 'BRANDS',
  Categories = 'CATEGORIES',
  Compare = 'COMPARE',
  Normal = 'NORMAL',
}

export type MenuSubNavigationItem = {
  displayName: Scalars['String']
  isInFooter: Scalars['Boolean']
  url: Scalars['String']
}

export type MenuVisibilityResult = {
  showAddresses: Scalars['Boolean']
  showAttest: Scalars['Boolean']
  showContacts: Scalars['Boolean']
  showInvoices: Scalars['Boolean']
  showPreOrder: Scalars['Boolean']
  showServices: Scalars['Boolean']
}

export type MonthlyCostDto = {
  amountExcludingVat?: Maybe<Scalars['Decimal']>
  amountIncludingVat?: Maybe<Scalars['Decimal']>
  effectiveInterestRate: Scalars['Decimal']
  interestRate: Scalars['Float']
  invoiceFee: Scalars['Decimal']
  numberOfMonths?: Maybe<Scalars['Int']>
  startFee: Scalars['Decimal']
  totalCreditPurchaseCostIncludingVat: Scalars['Decimal']
}

export type Mutation = {
  addAttestGroupMember?: Maybe<Scalars['Boolean']>
  addAttester?: Maybe<Scalars['Boolean']>
  attestPreOrder?: Maybe<AttestPreOrderResult>
  cancelContactInvitation?: Maybe<Scalars['Boolean']>
  cancelPreOrder?: Maybe<Scalars['Boolean']>
  changePassword?: Maybe<ChangePasswordBasicResult>
  confirmContactInvitation: Scalars['Boolean']
  createAddress?: Maybe<CreateAddressResult>
  createAttestGroup?: Maybe<CreateGroupResult>
  createAttestGroupLevel?: Maybe<CreateAttestGroupLevelCommandResult>
  createCustomerPageBanner?: Maybe<CreateBannerDto>
  createCustomerPageCategory?: Maybe<CategoryDto>
  createCustomerPageContent?: Maybe<PageContentDto>
  createCustomerPageLogo?: Maybe<CreateLogoDto>
  createCustomerPageProduct?: Maybe<ProductDto>
  createFavoriteListFromCart?: Maybe<ProductList>
  createNotifyMeRegistration?: Maybe<Registration>
  createRma?: Maybe<CreateRmaResult>
  declineContactInvitation?: Maybe<DeclineContactInvitationResult>
  deleteAddress?: Maybe<Scalars['Boolean']>
  deleteAddresses?: Maybe<Scalars['Boolean']>
  deleteAttestGroup?: Maybe<Scalars['Boolean']>
  deleteAttestGroupLevel?: Maybe<Scalars['Boolean']>
  deleteCustomerPageBanner?: Maybe<Scalars['Boolean']>
  deleteCustomerPageCategory?: Maybe<Scalars['Boolean']>
  deleteCustomerPageLogo?: Maybe<Scalars['Boolean']>
  deleteCustomerPageProduct?: Maybe<Scalars['Boolean']>
  deleteFavoriteList?: Maybe<DeleteProductListResult>
  emptyCart?: Maybe<Cart>
  joinBusiness?: Maybe<RegisterCustomerResult>
  logClientMessage: Scalars['Boolean']
  makeContactInactive?: Maybe<Scalars['Boolean']>
  mergeCartsById: Scalars['Boolean']
  mergeCartsByUser: Scalars['Boolean']
  notifyAddToCart?: Maybe<Scalars['String']>
  notifyClick?: Maybe<Scalars['String']>
  registerBusiness?: Maybe<RegisterCustomerResult>
  registerConsumer?: Maybe<RegisterCustomerResult>
  removeAttestGroupMember?: Maybe<Scalars['Boolean']>
  removeAttester?: Maybe<Scalars['Boolean']>
  sendContactInvitation?: Maybe<SendContactInvitationResult>
  sendGoogleAnalyticsEvent: Scalars['Boolean']
  sendPaymentConfirmation?: Maybe<PaymentConfirmationResult>
  sendProductListInvitation?: Maybe<SendProductListInvitationResult>
  sendRequestForPartialDelivery?: Maybe<SendRequestForPartialDeliveryResult>
  setAccountAndPrivacyTerms: Scalars['Boolean']
  setAttestDefaultGroup?: Maybe<Scalars['Boolean']>
  setContactSelectorFavorite: Scalars['Boolean']
  setLastLogin: Scalars['Boolean']
  setPrivacyTerms: Scalars['Boolean']
  submitCustomerServiceInquiry: Scalars['String']
  surveyOptIn?: Maybe<Scalars['Boolean']>
  updateAccountInformation?: Maybe<Scalars['Boolean']>
  updateAddress?: Maybe<UpdateAddressResult>
  updateAttestGroup?: Maybe<Scalars['Boolean']>
  updateAttestGroupLevel?: Maybe<Scalars['Boolean']>
  updateCartItemComment?: Maybe<UpdateCartItemCommentResult>
  updateCartWithItem?: Maybe<UpdateCartResult>
  updateCartWithMultipleItems?: Maybe<UpdateCartResult>
  updateCartWithProduct?: Maybe<Array<CartItemResult>>
  updateCartWithProducts?: Maybe<Array<CartItemResult>>
  updateCompareListWithProduct?: Maybe<UpdateProductListResult>
  updateContactPermissions?: Maybe<Scalars['Boolean']>
  updateCustomerPageCategory?: Maybe<Scalars['Boolean']>
  updateCustomerPageContent?: Maybe<Scalars['Boolean']>
  updateFavoriteListName?: Maybe<UpdateProductListNameResult>
  updateFavoriteListWithProduct?: Maybe<UpdateProductListResult>
  updateRteRequestStatus?: Maybe<UpdateRequestStatusResult>
  updateSarRequestStatus?: Maybe<UpdateRequestStatusResult>
  updateShowPricesInclVatUserSettings?: Maybe<Scalars['Boolean']>
  updateUserInformation?: Maybe<Scalars['Boolean']>
  upsertEndUserInformation?: Maybe<UpsertRequiredEndUserInformationResult>
}

export type MutationAddAttestGroupMemberArgs = {
  command: GroupMemberRequestInput
}

export type MutationAddAttesterArgs = {
  command: AttesterRequestInput
}

export type MutationAttestPreOrderArgs = {
  command: AttestPreOrderRequestInput
}

export type MutationCancelContactInvitationArgs = {
  command: CancelContactInvitationRequestInput
}

export type MutationCancelPreOrderArgs = {
  command: CancelPreOrderRequestInput
}

export type MutationChangePasswordArgs = {
  command: ChangePasswordRequestInput
}

export type MutationConfirmContactInvitationArgs = {
  accountTermsAccepted: Scalars['Boolean']
  firstName?: InputMaybe<Scalars['String']>
  invitationIdentifier: Scalars['UUID']
  lastName?: InputMaybe<Scalars['String']>
  offersByEmail: Scalars['Boolean']
  password?: InputMaybe<Scalars['String']>
}

export type MutationCreateAddressArgs = {
  command: CreateAddressRequestInput
}

export type MutationCreateAttestGroupArgs = {
  command: CreateGroupRequestInput
}

export type MutationCreateAttestGroupLevelArgs = {
  command: CreateGroupLevelRequestInput
}

export type MutationCreateCustomerPageBannerArgs = {
  command: CreateBannerRequestInput
}

export type MutationCreateCustomerPageCategoryArgs = {
  command: CreateCategoryRequestInput
}

export type MutationCreateCustomerPageContentArgs = {
  command: CreatePageContentRequestInput
}

export type MutationCreateCustomerPageLogoArgs = {
  command: CreateLogoRequestInput
}

export type MutationCreateCustomerPageProductArgs = {
  command: CreateProductRequestInput
}

export type MutationCreateFavoriteListFromCartArgs = {
  favoriteListName: Scalars['String']
}

export type MutationCreateNotifyMeRegistrationArgs = {
  email: Scalars['String']
  productErpIdentifier: Scalars['String']
}

export type MutationCreateRmaArgs = {
  command: CreateRmaRequestInput
}

export type MutationDeclineContactInvitationArgs = {
  invitationIdentifier: Scalars['UUID']
}

export type MutationDeleteAddressArgs = {
  command: DeleteAddressRequestInput
}

export type MutationDeleteAddressesArgs = {
  command: DeleteAddressesRequestInput
}

export type MutationDeleteAttestGroupArgs = {
  command: DeleteGroupRequestInput
}

export type MutationDeleteAttestGroupLevelArgs = {
  command: DeleteGroupLevelRequestInput
}

export type MutationDeleteCustomerPageBannerArgs = {
  command: DeleteBannerRequestInput
}

export type MutationDeleteCustomerPageCategoryArgs = {
  command: DeleteCategoryRequestInput
}

export type MutationDeleteCustomerPageLogoArgs = {
  command: DeleteLogoRequestInput
}

export type MutationDeleteCustomerPageProductArgs = {
  command: DeleteProductRequestInput
}

export type MutationDeleteFavoriteListArgs = {
  id: Scalars['Int']
}

export type MutationJoinBusinessArgs = {
  accountTermsAccepted: Scalars['Boolean']
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  offersByEmail: Scalars['Boolean']
  password?: InputMaybe<Scalars['String']>
  preRegistrationToken?: InputMaybe<Scalars['String']>
}

export type MutationLogClientMessageArgs = {
  message?: InputMaybe<Scalars['String']>
}

export type MutationMakeContactInactiveArgs = {
  command: MakeContactInactiveRequestInput
}

export type MutationMergeCartsByIdArgs = {
  sourceCartId: Scalars['Int']
  targetContactId: Scalars['Int']
}

export type MutationMergeCartsByUserArgs = {
  sourceContactId?: InputMaybe<Scalars['Int']>
  sourceUserIdentifier?: InputMaybe<Scalars['UUID']>
  targetContactId: Scalars['Int']
}

export type MutationNotifyAddToCartArgs = {
  ticket: Scalars['String']
}

export type MutationNotifyClickArgs = {
  ticket: Scalars['String']
}

export type MutationRegisterBusinessArgs = {
  accountTermsAccepted: Scalars['Boolean']
  companyName?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  offersByEmail: Scalars['Boolean']
  organizationNumber?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  preRegistrationToken?: InputMaybe<Scalars['String']>
}

export type MutationRegisterConsumerArgs = {
  accountTermsAccepted: Scalars['Boolean']
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  offersByEmail: Scalars['Boolean']
  password?: InputMaybe<Scalars['String']>
  preRegistrationToken?: InputMaybe<Scalars['String']>
}

export type MutationRemoveAttestGroupMemberArgs = {
  command: GroupMemberRequestInput
}

export type MutationRemoveAttesterArgs = {
  command: RemoveAttesterRequestInput
}

export type MutationSendContactInvitationArgs = {
  command: SendContactInvitationRequestInput
}

export type MutationSendGoogleAnalyticsEventArgs = {
  ga4Input?: InputMaybe<Ga4Input>
  payload?: InputMaybe<Scalars['String']>
}

export type MutationSendPaymentConfirmationArgs = {
  command: SendPaymentConfirmationEmailRequestInput
}

export type MutationSendProductListInvitationArgs = {
  id: Scalars['Int']
  message: Scalars['String']
  receiverEmailAddress: Scalars['String']
  senderEmailAddress: Scalars['String']
  senderFirstName: Scalars['String']
  senderLastName: Scalars['String']
}

export type MutationSendRequestForPartialDeliveryArgs = {
  command: SendRequestForPartialDeliveryRequestInput
}

export type MutationSetAccountAndPrivacyTermsArgs = {
  contactId: Scalars['Int']
  offersByEmail?: InputMaybe<Scalars['Boolean']>
}

export type MutationSetAttestDefaultGroupArgs = {
  command: SetDefaultGroupRequestInput
}

export type MutationSetContactSelectorFavoriteArgs = {
  contactId: Scalars['Int']
  isFavorite: Scalars['Boolean']
}

export type MutationSetLastLoginArgs = {
  contactId: Scalars['Int']
}

export type MutationSetPrivacyTermsArgs = {
  contactId: Scalars['Int']
}

export type MutationSubmitCustomerServiceInquiryArgs = {
  captchaResponse?: InputMaybe<Scalars['String']>
  fields: Array<CustomerServiceInquiryFieldInput>
  id: Scalars['Int']
  title: Scalars['String']
}

export type MutationSurveyOptInArgs = {
  command: SurveyOptInRequestInput
}

export type MutationUpdateAccountInformationArgs = {
  command: UpdateAccountInformationRequestInput
}

export type MutationUpdateAddressArgs = {
  command: UpdateAddressRequestInput
}

export type MutationUpdateAttestGroupArgs = {
  command: UpdateGroupRequestInput
}

export type MutationUpdateAttestGroupLevelArgs = {
  command: UpdateGroupLevelRequestInput
}

export type MutationUpdateCartItemCommentArgs = {
  cartItemId: Scalars['Int']
  value?: InputMaybe<Scalars['String']>
}

export type MutationUpdateCartWithItemArgs = {
  command: UpdateCartWithItemRequestInput
}

export type MutationUpdateCartWithMultipleItemsArgs = {
  command: UpdateCartWithMultipleItemsRequestInput
}

export type MutationUpdateCartWithProductArgs = {
  comment?: InputMaybe<Scalars['String']>
  mainProductErpIdentifier?: InputMaybe<Scalars['String']>
  parentCartItemId?: InputMaybe<Scalars['Int']>
  productErpIdentifier: Scalars['String']
  quantity?: InputMaybe<Scalars['Int']>
}

export type MutationUpdateCartWithProductsArgs = {
  cartItemParams: Array<InputMaybe<CartItemParamInput>>
}

export type MutationUpdateCompareListWithProductArgs = {
  productErpIdentifier: Scalars['String']
  productListName?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Int']>
}

export type MutationUpdateContactPermissionsArgs = {
  command: UpdateContactPermissionsRequestInput
}

export type MutationUpdateCustomerPageCategoryArgs = {
  command: UpdateCategoryRequestInput
}

export type MutationUpdateCustomerPageContentArgs = {
  command: UpdatePageContentRequestInput
}

export type MutationUpdateFavoriteListNameArgs = {
  favoriteListName: Scalars['String']
  updatedFavoriteListName: Scalars['String']
}

export type MutationUpdateFavoriteListWithProductArgs = {
  favoriteListId?: InputMaybe<Scalars['Int']>
  favoriteListName: Scalars['String']
  productErpIdentifier: Scalars['String']
  quantity?: InputMaybe<Scalars['Int']>
}

export type MutationUpdateRteRequestStatusArgs = {
  accept: Scalars['Boolean']
  token?: InputMaybe<Scalars['String']>
}

export type MutationUpdateSarRequestStatusArgs = {
  accept: Scalars['Boolean']
  token?: InputMaybe<Scalars['String']>
}

export type MutationUpdateShowPricesInclVatUserSettingsArgs = {
  command: UpdateShowPricesInclVatUserSettingRequestInput
}

export type MutationUpdateUserInformationArgs = {
  command: UpdateUserInformationRequestInput
}

export type MutationUpsertEndUserInformationArgs = {
  cartItemId: Scalars['Int']
  label: Scalars['String']
  lineNumber: Scalars['Decimal']
  value?: InputMaybe<Scalars['String']>
}

export type NavigationByCategory = {
  /** Child categories for the given category tree node. */
  children: Array<ProductCategoryChildNavigationItem>
  /** Child categories for the given category tree node, ordered by number of hits. */
  childrenSortedByHits: Array<ProductCategoryChildNavigationItem>
  id?: Maybe<Scalars['String']>
  /** Breadcrumb path to the requested category in the full category tree. */
  path: Array<CategoryNavigationItem>
  /** Sibling categories for the given category tree node. */
  siblings: Array<CategoryNavigationItem>
}

export enum NotificationType {
  AvailabilityAlert = 'AVAILABILITY_ALERT',
  CampaignAlert = 'CAMPAIGN_ALERT',
  None = 'NONE',
}

export type OciDataResult = {
  authorizationCode?: Maybe<Scalars['String']>
  contactId: Scalars['Int']
  customerLandingPage?: Maybe<Scalars['URL']>
  punchOutTicket: Scalars['UUID']
  sessionUserIdentifier: Scalars['UUID']
  state: OciDataState
}

export enum OciDataState {
  InvalidHookUrl = 'INVALID_HOOK_URL',
  InvalidSiteType = 'INVALID_SITE_TYPE',
  InvalidUserNameOrPassword = 'INVALID_USER_NAME_OR_PASSWORD',
  MultipleContacts = 'MULTIPLE_CONTACTS',
  NoContacts = 'NO_CONTACTS',
  Ok = 'OK',
}

export type Order = {
  id: Scalars['Int']
  orderErpIdentifier?: Maybe<Scalars['String']>
}

export type OrderCharge = {
  amountType: AmountType
  chargeOnlyOnFirstDelivery: Scalars['Boolean']
  chargeType: ChargeType
  currencyCode: Currency
  deliveryCountryCode?: Maybe<Scalars['String']>
  deliveryModeErpIdentifier?: Maybe<Scalars['String']>
  invoiceType?: Maybe<Scalars['String']>
  itemReference?: Maybe<Scalars['String']>
  percentage?: Maybe<Scalars['Decimal']>
  price: Scalars['Decimal']
  priceVat: Scalars['Decimal']
  taxCategoryErpIdentifier?: Maybe<Scalars['String']>
  taxGroup: TaxGroup
}

export enum OrderChipStatus {
  Canceled = 'CANCELED',
  None = 'NONE',
  PartlyShipped = 'PARTLY_SHIPPED',
  Pending = 'PENDING',
  Shipped = 'SHIPPED',
}

export type OrderConfirmationDetails = {
  googleAnalyticsPurchase?: Maybe<GoogleAnalyticsPurchase>
  klarnaHtmlSnippet?: Maybe<Scalars['String']>
  orderConfirmationTimedOut: Scalars['Boolean']
  orderDetails?: Maybe<OrderDetails>
  orderUnavailable: Scalars['Boolean']
}

export type OrderDetailPriceModel = {
  priceDisplay?: Maybe<Scalars['String']>
  quantityBackOrdered: Scalars['Int']
  quantityCanceled: Scalars['Int']
  quantityDelivered: Scalars['Int']
  quantityReadyForDelivery: Scalars['Int']
  singlePriceWithVat: Scalars['Decimal']
  totalPriceDisplay?: Maybe<Scalars['String']>
  totalPriceDisplayBackOrdered?: Maybe<Scalars['String']>
  totalPriceDisplayCanceled?: Maybe<Scalars['String']>
  totalPriceDisplayDelivered?: Maybe<Scalars['String']>
  totalPriceDisplayReadyForDelivery?: Maybe<Scalars['String']>
}

export type OrderDetails = {
  customerErpId?: Maybe<Scalars['String']>
  customerUnit?: Maybe<Scalars['String']>
  deliveryAddress?: Maybe<AddressDetails>
  deliveryMethod?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  isGuestOrder: Scalars['Boolean']
  isNewCustomer: Scalars['Boolean']
  isRental: Scalars['Boolean']
  isRentalPending?: Maybe<Scalars['Boolean']>
  paymentMethod?: Maybe<Scalars['String']>
}

export type OrderDetailsCharge = {
  amountType: AmountType
  chargeOnlyOnFirstDelivery: Scalars['Boolean']
  chargeType: ChargeType
  currencyCode: Currency
  deliveryCountryCode?: Maybe<Scalars['String']>
  deliveryModeErpIdentifier?: Maybe<Scalars['String']>
  itemReference?: Maybe<Scalars['String']>
  percentage?: Maybe<Scalars['Decimal']>
  price: Scalars['Decimal']
  priceDisplay?: Maybe<Scalars['String']>
  priceVat: Scalars['Decimal']
  taxCategoryErpIdentifier?: Maybe<Scalars['String']>
  taxGroup: TaxGroup
}

export type OrderDetailsForRmaOrderDto = {
  cellphone?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  orderErpIdentifier?: Maybe<Scalars['String']>
  orderId: Scalars['Int']
}

export type OrderDetailsForRmaOrderItemDto = {
  productDisplayName?: Maybe<Scalars['String']>
  productErpIdentifier?: Maybe<Scalars['String']>
  quantityOrdered: Scalars['Int']
}

export type OrderDetailsForRmaResult = {
  contact?: Maybe<ContactModel>
  order?: Maybe<OrderDetailsForRmaOrderDto>
  orderItems?: Maybe<Array<Maybe<OrderDetailsForRmaOrderItemDto>>>
}

export type OrderDetailsShippingAddress = {
  addressLine?: Maybe<Scalars['String']>
  addressee?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  department?: Maybe<Scalars['String']>
  goodsReceiverName?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
}

export type OrderDto = {
  contactId?: Maybe<Scalars['Int']>
  contactName?: Maybe<Scalars['String']>
  currencyCode?: Maybe<Scalars['String']>
  customerPurchaseOrderNumber?: Maybe<Scalars['String']>
  customerReferenceCostCenter?: Maybe<Scalars['String']>
  grandTotalExcludingVat: Scalars['Decimal']
  grandTotalIncludingVat: Scalars['Decimal']
  invoiceDueDate?: Maybe<Scalars['DateTime']>
  invoiceDueDateExpired?: Maybe<Scalars['Boolean']>
  invoiceIdIdentifiers?: Maybe<Array<Scalars['Int']>>
  orderDateUtc: Scalars['DateTime']
  orderErpIdentifier?: Maybe<Scalars['String']>
  orderId: Scalars['Int']
  orderState: OrderState
  paymentState: PaymentState
  paymentTermsErpIdentifier?: Maybe<Scalars['String']>
  paymentTypeName?: Maybe<Scalars['String']>
  placedById?: Maybe<Scalars['Int']>
  priceDisplay?: Maybe<Scalars['String']>
  priceDisplayForExport?: Maybe<Scalars['String']>
  pricesArePerMonth: Scalars['Boolean']
}

export type OrderItem = {
  agreementAmount?: Maybe<Scalars['Decimal']>
  agreementPercent?: Maybe<Scalars['Decimal']>
  agreementType: PricingRuleAgreementType
  availabilityInformation?: Maybe<AccountGraphql_Availability>
  charges?: Maybe<Array<Maybe<OrderCharge>>>
  comments?: Maybe<Array<Maybe<OrderItemComment>>>
  costPrice: Scalars['Decimal']
  displayName?: Maybe<Scalars['String']>
  etaDateUtc?: Maybe<Scalars['DateTime']>
  id: Scalars['Int']
  itemReference?: Maybe<Scalars['String']>
  manufacturerProductIdentifier?: Maybe<Scalars['String']>
  mediaIdentifier?: Maybe<Scalars['String']>
  monthlyPrice?: Maybe<Scalars['Decimal']>
  monthlyVat?: Maybe<Scalars['Decimal']>
  nameSlug?: Maybe<Scalars['String']>
  orderItemState: OrderItemState
  parentItemReference?: Maybe<Scalars['String']>
  price: Scalars['Decimal']
  priceDisplay?: Maybe<OrderDetailPriceModel>
  priceVat: Scalars['Decimal']
  product?: Maybe<Product>
  productErpIdentifier?: Maybe<Scalars['String']>
  productId: Scalars['Int']
  productType: ProductType
  quantityAllocated?: Maybe<Scalars['Int']>
  quantityOrdered: Scalars['Int']
  quantityShipped: Scalars['Int']
  serialNumbers?: Maybe<Array<Maybe<Scalars['String']>>>
  serviceDurationMonths?: Maybe<Scalars['Int']>
  supplierCode?: Maybe<Scalars['String']>
  taxCategoryErpIdentifier?: Maybe<Scalars['String']>
  taxGroup: TaxGroup
  unitType: UnitType
}

export type OrderItemComment = {
  commentText?: Maybe<Scalars['String']>
  id: Scalars['Int']
}

export enum OrderItemState {
  Canceled = 'CANCELED',
  Confirmed = 'CONFIRMED',
  Created = 'CREATED',
  Invoiced = 'INVOICED',
  None = 'NONE',
  Open = 'OPEN',
  PartlyShipped = 'PARTLY_SHIPPED',
  Shipped = 'SHIPPED',
}

export enum OrderPaymentChipStatus {
  None = 'NONE',
  NotPaid = 'NOT_PAID',
  Overdue = 'OVERDUE',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
}

export type OrderPaymentState = {
  amountPaid: Scalars['Decimal']
  id: Scalars['Int']
  orderId: Scalars['Int']
  ourReference?: Maybe<Scalars['String']>
  paidVia?: Maybe<Scalars['String']>
  paymentMethodId: Scalars['Int']
  paymentServiceProvider?: Maybe<Scalars['String']>
  paymentServiceProviderErrorMessage?: Maybe<Scalars['String']>
  paymentServiceProviderReference?: Maybe<Scalars['String']>
  paymentServiceProviderStatus?: Maybe<Scalars['String']>
  paymentStateSetBy?: Maybe<Scalars['String']>
  paymentStateSetDateTimeUtc: Scalars['DateTime']
  paymentTermsErpIdentifier?: Maybe<Scalars['String']>
  paymentType?: Maybe<Scalars['String']>
  site: Site
  state: PaymentState
}

export enum OrderState {
  Canceled = 'CANCELED',
  Confirmed = 'CONFIRMED',
  Created = 'CREATED',
  Invoiced = 'INVOICED',
  None = 'NONE',
  Open = 'OPEN',
  Shipped = 'SHIPPED',
}

export enum OrdersToShow {
  AllForCustomer = 'ALL_FOR_CUSTOMER',
  PlacedByContact = 'PLACED_BY_CONTACT',
  WhereContactIsAttester = 'WHERE_CONTACT_IS_ATTESTER',
}

export type OtherInformation = {
  costCenterReference?: Maybe<Scalars['String']>
  goodsMark?: Maybe<Scalars['String']>
  goodsReceiverDepartment?: Maybe<Scalars['String']>
  goodsReceiverName?: Maybe<Scalars['String']>
  purchaseOrderNumber?: Maybe<Scalars['String']>
  referenceName?: Maybe<Scalars['String']>
}

export type Page =
  | ArticlePage
  | BlogPage
  | BrandContentPage
  | BrandListPage
  | BrandPage
  | CustomerProductGroupPage
  | CustomerStartPage
  | EditorialPage
  | StartPage

export type PageContentDto = {
  content?: Maybe<Scalars['String']>
  header?: Maybe<Scalars['String']>
  lead?: Maybe<Scalars['String']>
  pageId: Scalars['Int']
}

export type PageDto = {
  banner?: Maybe<BannerDto>
  categories?: Maybe<Array<CategoryDto>>
  id: Scalars['Int']
  logo?: Maybe<LogoDto>
  name?: Maybe<Scalars['String']>
  pageContent?: Maybe<PageContentDto>
  settings?: Maybe<SettingsDto>
}

export enum PageType {
  Article = 'ARTICLE',
  Blog = 'BLOG',
  Brand = 'BRAND',
  BrandContent = 'BRAND_CONTENT',
  BrandList = 'BRAND_LIST',
  CustomerProductGroup = 'CUSTOMER_PRODUCT_GROUP',
  CustomerStart = 'CUSTOMER_START',
  Editorial = 'EDITORIAL',
  Redirect = 'REDIRECT',
  Start = 'START',
}

export type Pagination = {
  page: Scalars['Int']
  pageSize: Scalars['Int']
  totalHitsUntilEnd: Scalars['Int']
  totalPages: Scalars['Int']
}

export type Panel = {
  ads?: Maybe<Array<Maybe<AdContainer>>>
  attributes: Array<PanelAttribute>
  count?: Maybe<Scalars['Int']>
  displayName: Scalars['String']
  error?: Maybe<Scalars['String']>
  facets?: Maybe<Array<Maybe<Facet>>>
  name: Scalars['String']
  phrases?: Maybe<Array<Phrase>>
  productReferences?: Maybe<Array<ProductReference>>
  type: ResultType
}

export type PanelAttribute = {
  key: Scalars['String']
  value: Scalars['String']
}

export type PanelsResult = {
  zones: Array<Zone>
}

export type ParentProduct = {
  descriptions?: Maybe<Array<Maybe<QualifiedValueOfString>>>
  id?: Maybe<Scalars['String']>
  isEmpty: Scalars['Boolean']
  keySellingPoints?: Maybe<Array<Maybe<QualifiedValueOfString>>>
  names?: Maybe<Array<Maybe<QualifiedValueOfString>>>
  oneliner?: Maybe<Array<Maybe<QualifiedValueOfString>>>
}

export type PaymentCharge = {
  amountExcludingVat: Scalars['Decimal']
  amountIncludingVat: Scalars['Decimal']
  chargeId: Scalars['Int']
  chargeType: ChargeType
  taxGroup: TaxGroup
  vat: Scalars['Decimal']
}

export type PaymentConfirmationResult = {
  success: Scalars['Boolean']
}

export type PaymentMethodPaymentServiceProviderParameter = {
  paymentMethodId: Scalars['Int']
  paymentServiceProviderAgentName?: Maybe<Scalars['String']>
  paymentServiceProviderParameterName?: Maybe<Scalars['String']>
  paymentServiceProviderParameterValue?: Maybe<Scalars['String']>
}

export enum PaymentState {
  None = 'NONE',
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PaymentInitiated = 'PAYMENT_INITIATED',
  Unknown = 'UNKNOWN',
}

export type PeppolDataResult = {
  authorizationCode?: Maybe<Scalars['String']>
  contactId: Scalars['Int']
  customerLandingPage?: Maybe<Scalars['URL']>
  punchOutTicket: Scalars['UUID']
  sessionUserIdentifier: Scalars['UUID']
  state: PeppolDataState
}

export enum PeppolDataState {
  InvalidHookUrl = 'INVALID_HOOK_URL',
  InvalidSiteType = 'INVALID_SITE_TYPE',
  InvalidUserNameOrPassword = 'INVALID_USER_NAME_OR_PASSWORD',
  MultipleContacts = 'MULTIPLE_CONTACTS',
  NoContacts = 'NO_CONTACTS',
  Ok = 'OK',
}

export type Phrase = {
  text: Scalars['String']
}

export enum Placement {
  Check = 'CHECK',
  Radio = 'RADIO',
  Top = 'TOP',
  Unspecified = 'UNSPECIFIED',
}

export type PopularSearchPhrase = {
  rank: Scalars['Int']
  relevance: Scalars['Decimal']
  text?: Maybe<Scalars['String']>
  ticket?: Maybe<Scalars['String']>
}

export type PopularSearches = {
  popularSearchPhrases?: Maybe<Array<Maybe<PopularSearchPhrase>>>
}

export enum PreOrderAttestState {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  None = 'NONE',
  Pending = 'PENDING',
}

export type PreOrderAttestStepV2 = {
  actionById?: Maybe<Scalars['Int']>
  actionByName?: Maybe<Scalars['String']>
  actionDateTimeUtc?: Maybe<Scalars['DateTime']>
  actionOnBehalfOfId?: Maybe<Scalars['Int']>
  actionOnBehalfOfName?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
  id: Scalars['Int']
  isAttestable: Scalars['Boolean']
  level: Scalars['Int']
  limit: Scalars['Decimal']
  limitDisplay?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  state: PreOrderAttestState
}

export type PreOrderChargeModel = {
  displayPriceExcludingVat?: Maybe<Scalars['String']>
  displayTextExcludingVat?: Maybe<Scalars['String']>
}

export type PreOrderInformationModel = {
  billingAddress?: Maybe<Address>
  cart?: Maybe<GetCartForCheckoutResult>
  charges?: Maybe<Array<Maybe<PreOrderChargeModel>>>
  deliveryAddress?: Maybe<Address>
  deliveryMethod?: Maybe<DeliveryMethodDto>
  paymentMethod?: Maybe<CheckoutPaymentMethod>
  total?: Maybe<PreOrderTotalModel>
}

export type PreOrderTotalModel = {
  totalExcludingVat?: Maybe<Scalars['String']>
  totalIncludingVat?: Maybe<Scalars['String']>
  totalVat?: Maybe<Scalars['String']>
}

export type PreOrderV2 = {
  allowSplitDelivery: Scalars['Boolean']
  attestGroupName?: Maybe<Scalars['String']>
  attestSteps?: Maybe<Array<Maybe<PreOrderAttestStepV2>>>
  billingAddressId: Scalars['Int']
  callingClientIpAddress?: Maybe<Scalars['String']>
  cartId: Scalars['Int']
  cellphone?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
  createdDateTimeUtc: Scalars['DateTime']
  currencyCode?: Maybe<Scalars['String']>
  customerId: Scalars['Int']
  customerPurchaseOrderNumber?: Maybe<Scalars['String']>
  customerReferenceCostCenter?: Maybe<Scalars['String']>
  customerReferenceName?: Maybe<Scalars['String']>
  deliveryMethodId: Scalars['Int']
  electronicInvoiceReceiverReferenceValue?: Maybe<Scalars['String']>
  emailAddress?: Maybe<Scalars['String']>
  goodsMark?: Maybe<Scalars['String']>
  goodsReceiverDepartment?: Maybe<Scalars['String']>
  goodsReceiverName?: Maybe<Scalars['String']>
  id: Scalars['Int']
  invoiceEmail?: Maybe<Scalars['String']>
  invoiceType: Scalars['Int']
  isAttestable: Scalars['Boolean']
  isCancelable: Scalars['Boolean']
  lastModifiedBy?: Maybe<Scalars['String']>
  lastModifiedDateTimeUtc: Scalars['DateTime']
  numberOfAttestedSteps: Scalars['Int']
  numberOfSteps: Scalars['Int']
  orderErpIdentifier?: Maybe<Scalars['String']>
  orderId?: Maybe<Scalars['Int']>
  orderSumWhenPreOrdering: Scalars['Decimal']
  paymentMethodUniqueId?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  phoneNotification: Scalars['Boolean']
  placedByFirstName?: Maybe<Scalars['String']>
  placedById: Scalars['Int']
  placedByLastName?: Maybe<Scalars['String']>
  shippingAddressId: Scalars['Int']
  smsNotification: Scalars['Boolean']
  status: PreOrderAttestState
  voucherCode?: Maybe<Scalars['String']>
}

export type Price = {
  bidDistributorErpIdentifier?: Maybe<Scalars['String']>
  bidSourceLocally: Scalars['Boolean']
  campaignPercentage?: Maybe<Scalars['Int']>
  campaignType?: Maybe<CampaignType>
  /** Includes chemical tax. */
  costPrice: Scalars['Decimal']
  formatted: PriceFormatted
  isBestSeller: Scalars['Boolean']
  isBid: Scalars['Boolean']
  isCampaign: Scalars['Boolean']
  isRecommendedProduct: Scalars['Boolean']
  originalPrice: Scalars['Decimal']
  originalPriceExcludingVat: Scalars['Decimal']
  originalPriceIncludingVat: Scalars['Decimal']
  originalPriceVat: Scalars['Decimal']
  price: Scalars['Decimal']
  priceExcludingVat: Scalars['Decimal']
  priceIncludingVat: Scalars['Decimal']
  priceMargin: Scalars['Decimal']
  priceVat: Scalars['Decimal']
  productErpIdentifier?: Maybe<Scalars['String']>
  rentalPrice?: Maybe<MonthlyCostDto>
}

export enum PriceFeedType {
  None = 'NONE',
  Partner = 'PARTNER',
  PartnerSpecific = 'PARTNER_SPECIFIC',
  Public = 'PUBLIC',
}

export type PriceFormatted = {
  originalPrice: Scalars['String']
  originalPriceExcludingVat: Scalars['String']
  originalPriceIncludingVat: Scalars['String']
  price: Scalars['String']
  priceExcludingVat: Scalars['String']
  priceIncludingVat: Scalars['String']
}

export type PriceModel = {
  price?: Maybe<Scalars['String']>
  totalPrice?: Maybe<Scalars['String']>
}

export enum PricingRuleAgreementType {
  CostAdded = 'COST_ADDED',
  LinePrice = 'LINE_PRICE',
  MarginDiscount = 'MARGIN_DISCOUNT',
  None = 'NONE',
  PriceRebate = 'PRICE_REBATE',
}

export type Product = {
  accessories?: Maybe<Accessories>
  allSpecifications?: Maybe<ProductSpecificationsWrapper>
  assortment?: Maybe<Scalars['String']>
  availability?: Maybe<Availability>
  axCategory: Scalars['String']
  axCategoryName?: Maybe<Scalars['String']>
  brandInfo?: Maybe<GetBrandInfoResult>
  category?: Maybe<Category>
  categoryId?: Maybe<Scalars['String']>
  chemicalNetWeight?: Maybe<Scalars['Int']>
  delivery?: Maybe<DeliveryMethod>
  deliveryMethods?: Maybe<Array<Maybe<DeliveryMethod>>>
  description?: Maybe<Scalars['String']>
  displayName: Scalars['String']
  displaySpecifications: Scalars['String']
  displaySpecificationsVariant: Scalars['String']
  displaySpecificationsVariantC: Scalars['String']
  documentType?: Maybe<Scalars['String']>
  ecoResCategoryName?: Maybe<Scalars['String']>
  energyClass?: Maybe<Scalars['String']>
  energyDocumentId?: Maybe<Scalars['String']>
  energyLabelImageId?: Maybe<Scalars['String']>
  englishFullDisplayName: Scalars['String']
  freeFreight: Scalars['Boolean']
  fullDisplayName: Scalars['String']
  globalName?: Maybe<Scalars['String']>
  hasAccessories?: Maybe<Scalars['Boolean']>
  hasProductNearServices?: Maybe<Scalars['Boolean']>
  hidden: Scalars['Boolean']
  hideInFeeds: Scalars['Boolean']
  id: Scalars['String']
  internalOnly: Scalars['Boolean']
  internalProduct: Scalars['Boolean']
  isDemo?: Maybe<Scalars['Boolean']>
  isDemoProduct: Scalars['Boolean']
  isHiddenFromSearchEngines: Scalars['Boolean']
  isNewProduct: Scalars['Boolean']
  isPrivateLabel?: Maybe<Scalars['Boolean']>
  isRentable?: Maybe<Scalars['Boolean']>
  isSpecialItem?: Maybe<Scalars['Boolean']>
  itemGroup?: Maybe<Scalars['String']>
  keySellingPoints?: Maybe<Array<Maybe<Scalars['String']>>>
  keywords?: Maybe<Scalars['String']>
  kits?: Maybe<Array<Maybe<Product>>>
  lastModifiedDateTimeUtc?: Maybe<Scalars['DateTime']>
  lastModifiedUtc?: Maybe<Scalars['DateTime']>
  leadTimeWeb?: Maybe<Scalars['String']>
  lotQuantity?: Maybe<Scalars['Int']>
  manufacturer?: Maybe<Scalars['String']>
  manufacturerErpIdentifier: Scalars['String']
  manufacturerId?: Maybe<Scalars['String']>
  manufacturerName: Scalars['String']
  manufacturerPartNumber?: Maybe<Scalars['String']>
  manufacturerProductIdentifier?: Maybe<Scalars['String']>
  market?: Maybe<Scalars['String']>
  maxQuantity: Scalars['Int']
  maxQuantityPerOrder?: Maybe<Scalars['Int']>
  medias?: Maybe<Array<Maybe<Media>>>
  minQuantity: Scalars['Int']
  minQuantityPerOrder?: Maybe<Scalars['Int']>
  nameSlug: Scalars['String']
  nameSlugRedirect: Scalars['String']
  newUntilDate?: Maybe<Scalars['DateTime']>
  noReturnsOnItem: Scalars['Boolean']
  nonReturnable: Scalars['Boolean']
  oneLiner?: Maybe<Scalars['String']>
  oneliner?: Maybe<Array<Maybe<QualifiedValueOfString>>>
  pageDisplayData?: Maybe<ExtendedDisplay>
  parentId?: Maybe<Scalars['String']>
  parentProduct?: Maybe<ParentProduct>
  partitionKey?: Maybe<Scalars['String']>
  plcState?: Maybe<Scalars['String']>
  price?: Maybe<Price>
  primaryImageId?: Maybe<Scalars['String']>
  productErpIdentifier: Scalars['String']
  productId: Scalars['String']
  productLifecycle?: Maybe<Array<Maybe<ProductLifecycle>>>
  productRedirectType: ProductRedirectType
  productType: ProductType
  promoted?: Maybe<Scalars['Boolean']>
  promotedSpecifications: Array<PromotedSpecificationValue>
  purchasePrice?: Maybe<PurchasePrice>
  purchaseTaxGroupId?: Maybe<Array<QualifiedCompanyValueOfString>>
  released?: Maybe<Scalars['Boolean']>
  requireEndUserInfo?: Maybe<Scalars['String']>
  requiredEndUserInformation?: Maybe<Array<RequiredEndUserInformationLineDto>>
  reviewCount: Scalars['Int']
  reviewScore: Scalars['Int']
  salesTaxGroupId?: Maybe<Array<QualifiedCompanyValueOfString>>
  salesUnit?: Maybe<Scalars['String']>
  seoKeywords?: Maybe<Scalars['String']>
  serialNumberControlled?: Maybe<Scalars['Boolean']>
  showAlwaysInStock?: Maybe<Scalars['Boolean']>
  softCategories?: Maybe<Array<Maybe<SoftCategory>>>
  suppressOnHand?: Maybe<Scalars['Boolean']>
  variants?: Maybe<ProductVariantContainer>
  wayOfTransport?: Maybe<Scalars['String']>
}

export type ProductPromotedSpecificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type ProductAccessory = {
  dependent: Scalars['Boolean']
  displayOrder: Scalars['Int']
  id?: Maybe<Scalars['String']>
  mandatory: Scalars['Boolean']
  placement: Placement
  type: AccessoryType
}

export type ProductCategoryChildNavigationItem = {
  category?: Maybe<ProductGraphql_Category>
  categoryDisplayName?: Maybe<Scalars['String']>
  categoryEnglishDisplayName?: Maybe<Scalars['String']>
  categoryId: Scalars['String']
  children: Array<ProductCategoryChildNavigationItem>
  hits: Scalars['Int']
  productCategoryPimIdentifier?: Maybe<Scalars['Int']>
  selected: Scalars['Boolean']
  sortOrder: Scalars['Int']
  url?: Maybe<Scalars['String']>
}

export type ProductDto = {
  categoryId: Scalars['Int']
  created: Scalars['DateTime']
  product?: Maybe<Product>
  productErpIdentifier?: Maybe<Scalars['String']>
}

export type ProductEolSitemapValue = {
  eolTimestamp?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  productId: Scalars['String']
}

export type ProductFilterDto = {
  allow: Scalars['Boolean']
  categoryErpIdentifier?: Maybe<Scalars['String']>
  ecoResCategoryErpIdentifierPath?: Maybe<Scalars['String']>
  manufactureName?: Maybe<Scalars['String']>
  productFilterErpIdentifier?: Maybe<Scalars['String']>
}

export type ProductFilterList = {
  facets: Array<Facet>
  hits: Scalars['Int']
  pagination?: Maybe<Pagination>
  productReferences: Array<ProductReference>
}

export type ProductHistoryDto = {
  availabilityInformation?: Maybe<AccountGraphql_Availability>
  currentPrice?: Maybe<ProductHistoryPriceModel>
  id: Scalars['Int']
  lineDiscountGroupErpIdentifier?: Maybe<Scalars['String']>
  manufacturerErpIdentifier?: Maybe<Scalars['String']>
  manufacturerProductIdentifier?: Maybe<Scalars['String']>
  mediaIdentifier?: Maybe<Scalars['String']>
  nameSlug?: Maybe<Scalars['String']>
  pricesArePerMonth: Scalars['Boolean']
  product?: Maybe<Product>
  productDisplayName?: Maybe<Scalars['String']>
  productErpIdentifier?: Maybe<Scalars['String']>
}

export type ProductHistoryPriceModel = {
  displayOriginalPrice?: Maybe<Scalars['String']>
  displayPrice?: Maybe<Scalars['String']>
  originalPriceExcludingVat?: Maybe<Scalars['Decimal']>
  originalPriceIncludingVat?: Maybe<Scalars['Decimal']>
  priceExcludingVat?: Maybe<Scalars['Decimal']>
  priceIncludingVat?: Maybe<Scalars['Decimal']>
}

export type ProductHistoryQuantityModel = {
  totalAllocated: Scalars['Int']
  totalOrdered: Scalars['Int']
}

export enum ProductLifeCycleState {
  Active = 'ACTIVE',
  Clearance = 'CLEARANCE',
  EndOfLife = 'END_OF_LIFE',
  ExternalClearance = 'EXTERNAL_CLEARANCE',
  New = 'NEW',
  None = 'NONE',
  NotReleased = 'NOT_RELEASED',
  PermanentlyStopped = 'PERMANENTLY_STOPPED',
  TempStopped = 'TEMP_STOPPED',
}

export type ProductLifecycle = {
  company?: Maybe<Scalars['String']>
  state: State
  validFrom?: Maybe<Scalars['String']>
  validTo?: Maybe<Scalars['String']>
}

export type ProductList = {
  contactId?: Maybe<Scalars['Int']>
  id: Scalars['Int']
  items: Array<ProductListItem>
  productListName?: Maybe<Scalars['String']>
  productListType: Scalars['Int']
  userIdentifier: Scalars['UUID']
}

export type ProductListItem = {
  comment?: Maybe<Scalars['String']>
  id: Scalars['Int']
  product?: Maybe<Product>
  productErpIdentifier: Scalars['String']
  quantity: Scalars['Int']
}

export type ProductMediaDocumentType = {
  fileName?: Maybe<Scalars['String']>
  language?: Maybe<Scalars['String']>
  mediaIdentifier: Scalars['String']
  mediaTypeName?: Maybe<Scalars['String']>
}

export type ProductModelInput = {
  name?: InputMaybe<Scalars['String']>
  productErpIdentifier?: InputMaybe<Scalars['String']>
  quantity: Scalars['Int']
  quantityOpened: Scalars['Int']
}

export type ProductNearServicesAccessoriesResult = {
  moreProductNearServicesExist: Scalars['Boolean']
  products: Array<Product>
}

export type ProductPage = {
  product?: Maybe<ProductReference>
  thoseWhoBoughtAlsoBought: Array<ProductReference>
  thoseWhoViewedAlsoViewed: Array<ProductReference>
}

export type ProductPageReference = {
  productErpIdentifier: Scalars['String']
  productResult?: Maybe<Product>
  searchResult?: Maybe<ProductPage>
}

export enum ProductRedirectType {
  Category = 'CATEGORY',
  None = 'NONE',
  PermanentlyDeleted = 'PERMANENTLY_DELETED',
}

export type ProductReference = {
  id: Scalars['String']
  product?: Maybe<Product>
  productErpIdentifier: Scalars['String']
  searchResultProduct?: Maybe<SearchResultProduct>
  ticket: Scalars['String']
}

export type ProductReferenceList = {
  productHits: Scalars['Int']
  productReferences: Array<ProductReference>
}

export type ProductReview = {
  author?: Maybe<Scalars['String']>
  award?: Maybe<Scalars['String']>
  awardImageHeight?: Maybe<Scalars['String']>
  awardImageUri?: Maybe<Scalars['String']>
  awardImageWidth?: Maybe<Scalars['String']>
  productReviewType?: Maybe<Scalars['String']>
  reviewCons?: Maybe<Scalars['String']>
  reviewDate?: Maybe<Scalars['DateTime']>
  reviewPros?: Maybe<Scalars['String']>
  reviewSummary?: Maybe<Scalars['String']>
  reviewTitle?: Maybe<Scalars['String']>
  reviewUri?: Maybe<Scalars['String']>
  reviewVerdict?: Maybe<Scalars['String']>
  sourceImageX?: Maybe<Scalars['String']>
  sourceImageY?: Maybe<Scalars['String']>
  sourceLogo?: Maybe<Scalars['String']>
  sourceName?: Maybe<Scalars['String']>
  sourceReviewRating: Scalars['Int']
  sourceUrl?: Maybe<Scalars['String']>
}

export type ProductReviews = {
  alaScore: Scalars['Int']
  averageProScore: Scalars['Int']
  averageUserScore: Scalars['Int']
  id?: Maybe<Scalars['String']>
  language: Language
  proReviewCount: Scalars['Int']
  proReviewScoreCount: Scalars['Int']
  productErpIdentifier: Scalars['String']
  productReviews: Array<ProductReview>
  userHasPurchasedProduct: Scalars['Boolean']
  userReviewCount: Scalars['Int']
}

export type ProductSearch = {
  facets: Array<Facet>
  navigationTree: Array<CategoryNavigationTreeItem>
  productHits: Scalars['Int']
  productReferences: Array<ProductReference>
}

export type ProductSpecification = {
  name: Scalars['String']
  specificationItemAttributes?: Maybe<Array<SpecificationItemAttribute>>
  value: Scalars['String']
}

export type ProductSpecificationsWrapper = {
  specificationsByCategory: Array<SpecificationsCategory>
}

export enum ProductType {
  CarePacks = 'CARE_PACKS',
  Config = 'CONFIG',
  ELicense = 'E_LICENSE',
  ELicenseAlso = 'E_LICENSE_ALSO',
  Freight = 'FREIGHT',
  GiftCards = 'GIFT_CARDS',
  Installation = 'INSTALLATION',
  Insurance = 'INSURANCE',
  Licenses = 'LICENSES',
  MobileSubscription = 'MOBILE_SUBSCRIPTION',
  None = 'NONE',
  OemItems = 'OEM_ITEMS',
  Other = 'OTHER',
  Products = 'PRODUCTS',
  Reinvoice = 'REINVOICE',
  Service = 'SERVICE',
  ServicePool = 'SERVICE_POOL',
  ServiceProduct = 'SERVICE_PRODUCT',
  Solutions = 'SOLUTIONS',
  Subscription = 'SUBSCRIPTION',
}

export type ProductValue = {
  displayNameEng: Scalars['String']
  id: Scalars['Int']
  itemText: Scalars['String']
  itemValueText: Scalars['String']
  itemValueTextEng: Scalars['String']
  missingVariantNames: Array<Scalars['String']>
  product?: Maybe<Product>
  productErpIdentifier: Scalars['String']
  productVariantCombinationExists: Scalars['Boolean']
  unitText?: Maybe<Scalars['String']>
}

export type ProductVariant = {
  kind: ProductVariantKind
  sortOrder: Scalars['Int']
  values: Array<ProductValue>
  variantName: Scalars['String']
  variantNameEng?: Maybe<Scalars['String']>
}

export type ProductVariantContainer = {
  productIdsCombinationDoesNotExists: Array<Scalars['String']>
  productsWithoutVariantCombination: Array<Product>
  variantsInformation: Array<ProductVariant>
}

export enum ProductVariantKind {
  Boolean = 'BOOLEAN',
  ProductImage = 'PRODUCT_IMAGE',
  Text = 'TEXT',
}

export type ProductsByCategory = {
  ads: Array<AdContainer>
  facets: Array<Facet>
  filterOnlyFacets?: Maybe<Array<Facet>>
  productHits: Scalars['Int']
  productReferences: Array<ProductReference>
}

export type PromotedSpecificationValue = {
  name: Scalars['String']
  value: Scalars['String']
}

export type PurchasePrice = {
  currency?: Maybe<Scalars['String']>
  price: Scalars['Decimal']
}

export type QualifiedCompanyValueOfString = {
  company?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type QualifiedValueOfString = {
  channel: Channel
  language: Language
  value?: Maybe<Scalars['String']>
}

export type Query = {
  accessories?: Maybe<Accessories>
  accessoryGuide?: Maybe<AccessoryGuide>
  accessoryGuideProducts?: Maybe<AccessoryGuideProductsWithHits>
  accessoryGuides?: Maybe<Array<Category>>
  activity?: Maybe<Activity>
  addressSuggestions?: Maybe<GetAddressSuggestionsResult>
  addresses?: Maybe<GetAddressesResult>
  articleBoxList?: Maybe<ArticleBoxList>
  attachSalesAccessories?: Maybe<Accessories>
  attestGroup?: Maybe<GetAttestGroupResult>
  attestSetupOverview: AttestSetupOverview
  attestSummary?: Maybe<GetAttestSummaryResult>
  autoComplete?: Maybe<AutoComplete>
  brandInfo?: Maybe<GetBrandInfoResult>
  brandNavigation?: Maybe<BrandNavigation>
  businessCustomerSettings?: Maybe<GetBusinessCustomerSettingsResult>
  byUrlData?: Maybe<GetByUrlDataResult>
  campaignbanner?: Maybe<GetCampaignBannerResult>
  cart?: Maybe<Cart>
  cartId?: Maybe<Scalars['Int']>
  case?: Maybe<GetCaseResult>
  cases?: Maybe<GetCasesResult>
  category?: Maybe<Category>
  categoryByPath?: Maybe<Category>
  categoryNavigation?: Maybe<NavigationByCategory>
  compactOrders?: Maybe<GetCompactOrdersResult>
  compactPreOrders?: Maybe<GetCompactPreOrderResult>
  companyInfo?: Maybe<GetCompanyInfoQueryResult>
  companyName?: Maybe<Scalars['String']>
  comparisons?: Maybe<Comparisons>
  contact?: Maybe<Contact>
  contactWithTypedCustomer?: Maybe<ContactWithTypedCustomerResult>
  contacts?: Maybe<Array<Maybe<AuthConnectedListContact>>>
  customerLandingPage?: Maybe<Scalars['URL']>
  customerNavigation?: Maybe<CustomerNavigation>
  customerPageCategories?: Maybe<GetCategoriesDto>
  customerPageProducts?: Maybe<GetProductsDto>
  customerServiceInquiry?: Maybe<CustomerServiceInquiry>
  cxmlData?: Maybe<CxmlDataResult>
  cxmlPreData?: Maybe<CxmlPreDataResult>
  deliveryMethodsSearch?: Maybe<Array<Maybe<DeliveryMethod>>>
  emailStatus: EmailStatus
  externalOrderItems?: Maybe<GetExternalOrderItemsResult>
  externalOrders?: Maybe<GetExternalOrdersResult>
  favorites?: Maybe<Favorites>
  favoritesById?: Maybe<ProductList>
  favourites?: Maybe<GetProductListsResult>
  federatedBusinessCustomer?: Maybe<Result>
  footer?: Maybe<Footer>
  invitedContact?: Maybe<GetInvitedContactResult>
  invoiceCopy?: Maybe<GetInvoiceCopyResult>
  invoiceDetails?: Maybe<GetInvoiceDetailsResult>
  invoiceSummary?: Maybe<GetInvoiceSummaryResult>
  invoices?: Maybe<GetInvoicesResult>
  isAttester?: Maybe<IsAttesterResult>
  latestBoughtProducts?: Maybe<GetLatestBoughtProductsResult>
  mainNavigation?: Maybe<MainNavigation>
  manageableContacts?: Maybe<GetManageableContactsResult>
  manufacturerProductSearch?: Maybe<ManufacturerProductSearch>
  menuVisibility?: Maybe<MenuVisibilityResult>
  ociData?: Maybe<OciDataResult>
  offersByEmailStatus?: Maybe<GetOffersByEmailStatusResult>
  orderConfirmationDetail: OrderConfirmationDetails
  orderDetails?: Maybe<GetOrderDetailsResult>
  orderDetailsForRma?: Maybe<OrderDetailsForRmaResult>
  orderHistoryForProduct?: Maybe<GetOrderHistoryForProductResult>
  orders?: Maybe<GetOrderHistoryV2Result>
  page?: Maybe<Page>
  panels?: Maybe<PanelsResult>
  peppolData?: Maybe<PeppolDataResult>
  popularSearches?: Maybe<PopularSearches>
  preOrder?: Maybe<GetPreOrderV2Result>
  preOrders?: Maybe<SearchPreOrdersV2Result>
  primaryCustomerPage?: Maybe<PageDto>
  product?: Maybe<Product>
  productFilters?: Maybe<Array<ProductFilterDto>>
  productHistory?: Maybe<GetProductHistoryResult>
  productNearServicesAccessories: ProductNearServicesAccessoriesResult
  productPage: ProductPageReference
  productReviewSummary?: Maybe<ProductReviews>
  productSearch?: Maybe<ProductSearch>
  productSearchByCategoryPath?: Maybe<ProductsByCategory>
  products: Array<Product>
  productsEol?: Maybe<Array<Maybe<ProductEolSitemapValue>>>
  productsEolCount?: Maybe<Scalars['Int']>
  recentlyViewed?: Maybe<RecentlyViewed>
  recommendationsBasedOnCart?: Maybe<RecommendationsBasedOnCart>
  requiredEndUserInformation?: Maybe<Array<Maybe<RequiredEndUserInformationLineDto>>>
  rteErrand?: Maybe<GdprErrand>
  sarErrand?: Maybe<GdprErrand>
  sarReport?: Maybe<GdprErrand>
  sarReportContent?: Maybe<GetSarReportContentResult>
  softCategoryByPath?: Maybe<CategoryByPath>
  softCategoryNavigation?: Maybe<NavigationByCategory>
  suggestedDelivery?: Maybe<DeliveryMethod>
  topContentParts?: Maybe<ContentPartListType>
  topSellingCampaignProducts?: Maybe<TopSellingCampaignProducts>
  topSellingProducts?: Maybe<TopSellingProducts>
  upperLevelCategories?: Maybe<Array<Maybe<UpperLevelCategory>>>
  userSettings?: Maybe<UserSettingsModel>
  usp?: Maybe<GetUspResult>
}

export type QueryAccessoriesArgs = {
  productErpIdentifier: Scalars['String']
}

export type QueryAccessoryGuideArgs = {
  categoryPath: Scalars['String']
}

export type QueryAccessoryGuideProductsArgs = {
  categoryPath: Scalars['String']
  manufacturerName: Scalars['String']
  page: Scalars['Int']
  pageSize: Scalars['Int']
  searchPhrase?: InputMaybe<Scalars['String']>
}

export type QueryActivityArgs = {
  activityId: Scalars['Int']
}

export type QueryAddressSuggestionsArgs = {
  query?: InputMaybe<GetAddressSuggestionsRequestInput>
}

export type QueryAddressesArgs = {
  query?: InputMaybe<GetAddressesRequestInput>
}

export type QueryArticleBoxListArgs = {
  articleIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  categoryNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mustIncludeAllTags?: InputMaybe<Scalars['Boolean']>
  numberOfBoxes: Scalars['Int']
  startIndex?: InputMaybe<Scalars['Int']>
  tagNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryAttachSalesAccessoriesArgs = {
  accessoriesTakeCount: Scalars['Int']
  excludedProductErpIdentifiers: Array<Scalars['String']>
  productErpIdentifier: Scalars['String']
}

export type QueryAttestGroupArgs = {
  query?: InputMaybe<GetAttestGroupInput>
}

export type QueryAutoCompleteArgs = {
  searchPhrase: Scalars['String']
}

export type QueryBrandInfoArgs = {
  manufacturerName: Scalars['String']
}

export type QueryByUrlDataArgs = {
  workerIdentifier?: InputMaybe<Scalars['String']>
}

export type QueryCartIdArgs = {
  anonymousUserIdentifier?: InputMaybe<Scalars['UUID']>
  contactId?: InputMaybe<Scalars['Int']>
}

export type QueryCaseArgs = {
  query?: InputMaybe<GetCaseRequestInput>
}

export type QueryCasesArgs = {
  query?: InputMaybe<GetCasesRequestInput>
}

export type QueryCategoryArgs = {
  categoryId?: InputMaybe<Scalars['String']>
  onlyValidDescendants?: InputMaybe<Scalars['Boolean']>
}

export type QueryCategoryByPathArgs = {
  categoryPath?: InputMaybe<Scalars['String']>
}

export type QueryCompactOrdersArgs = {
  numberOfOrders: Scalars['Int']
}

export type QueryCompactPreOrdersArgs = {
  numberOfOrders: Scalars['Int']
}

export type QueryCompanyInfoArgs = {
  organizationNumber?: InputMaybe<Scalars['String']>
}

export type QueryCompanyNameArgs = {
  organizationNumber?: InputMaybe<Scalars['String']>
  sourcingCompany: SourcingCompany
}

export type QueryContactsArgs = {
  siteType: SiteType
}

export type QueryCustomerLandingPageArgs = {
  contactId: Scalars['Int']
}

export type QueryCustomerPageCategoriesArgs = {
  query?: InputMaybe<GetCustomerPageCategoriesRequestInput>
}

export type QueryCustomerPageProductsArgs = {
  query?: InputMaybe<GetCustomerPageProductsRequestInput>
}

export type QueryCustomerServiceInquiryArgs = {
  id: Scalars['Int']
}

export type QueryCxmlDataArgs = {
  ticket?: InputMaybe<Scalars['String']>
}

export type QueryCxmlPreDataArgs = {
  baseUrl?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['String']>
  httpMethod?: InputMaybe<Scalars['String']>
}

export type QueryDeliveryMethodsSearchArgs = {
  productErpIdentifier: Scalars['String']
}

export type QueryEmailStatusArgs = {
  email?: InputMaybe<Scalars['String']>
}

export type QueryExternalOrderItemsArgs = {
  query?: InputMaybe<GetExternalOrderItemsRequestInput>
}

export type QueryFavoritesByIdArgs = {
  favoriteListId: Scalars['Int']
}

export type QueryInvitedContactArgs = {
  invitationIdentifier: Scalars['UUID']
}

export type QueryInvoiceCopyArgs = {
  query?: InputMaybe<GetInvoiceCopyRequestInput>
}

export type QueryInvoiceDetailsArgs = {
  query?: InputMaybe<GetInvoiceDetailsRequestInput>
}

export type QueryInvoicesArgs = {
  query?: InputMaybe<GetInvoicesRequestInput>
}

export type QueryLatestBoughtProductsArgs = {
  numberOfProducts: Scalars['Int']
}

export type QueryMainNavigationArgs = {
  uri: Scalars['String']
}

export type QueryManageableContactsArgs = {
  query?: InputMaybe<GetManageableContactsRequestInput>
}

export type QueryManufacturerProductSearchArgs = {
  facets?: InputMaybe<Array<InputMaybe<FacetParameterInput>>>
  manufacturerName?: InputMaybe<Scalars['String']>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  searchPhrase?: InputMaybe<Scalars['String']>
  selectedCategoryPath?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<SortBy>
}

export type QueryOciDataArgs = {
  dustin_url?: InputMaybe<Scalars['String']>
  hook_url?: InputMaybe<Scalars['String']>
  logon_pwd?: InputMaybe<Scalars['String']>
  logon_uid?: InputMaybe<Scalars['String']>
  originalMethod?: InputMaybe<Scalars['String']>
  originalQueryParams?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  returnUrl?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QueryOffersByEmailStatusArgs = {
  contactId: Scalars['Int']
}

export type QueryOrderConfirmationDetailArgs = {
  query?: InputMaybe<GetOrderConfirmationDetailRequestInput>
}

export type QueryOrderDetailsArgs = {
  query?: InputMaybe<GetOrderDetailsRequestInput>
}

export type QueryOrderDetailsForRmaArgs = {
  query?: InputMaybe<GetOrderDetailsForRmaRequestInput>
}

export type QueryOrderHistoryForProductArgs = {
  query?: InputMaybe<GetOrderHistoryForProductRequestInput>
}

export type QueryOrdersArgs = {
  query?: InputMaybe<GetOrdersRequestInput>
}

export type QueryPageArgs = {
  preview?: InputMaybe<Scalars['Boolean']>
  uri: Scalars['String']
}

export type QueryPanelsArgs = {
  panelPath: Scalars['String']
}

export type QueryPeppolDataArgs = {
  bodyAsString?: InputMaybe<Scalars['String']>
  buyerId?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  postUrl?: InputMaybe<Scalars['String']>
  returnObjectSpecId?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QueryPreOrderArgs = {
  query?: InputMaybe<GetPreOrderInput>
}

export type QueryPreOrdersArgs = {
  query?: InputMaybe<SearchPreOrderInput>
}

export type QueryProductArgs = {
  productErpIdentifier?: InputMaybe<Scalars['String']>
}

export type QueryProductHistoryArgs = {
  query?: InputMaybe<GetProductHistoryRequestInput>
}

export type QueryProductNearServicesAccessoriesArgs = {
  accessoriesTakeCount: Scalars['Int']
  productErpIdentifier: Scalars['String']
}

export type QueryProductPageArgs = {
  productErpIdentifier: Scalars['String']
}

export type QueryProductReviewSummaryArgs = {
  productErpIdentifier: Scalars['String']
}

export type QueryProductSearchArgs = {
  facets?: InputMaybe<Array<InputMaybe<FacetParameterInput>>>
  isUserInitiated?: InputMaybe<Scalars['Boolean']>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  searchPhrase?: InputMaybe<Scalars['String']>
  selectedCategoryPath?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<SortBy>
}

export type QueryProductSearchByCategoryPathArgs = {
  categoryPath: Scalars['String']
  facets?: InputMaybe<Array<InputMaybe<FacetParameterInput>>>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  searchPhrase?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<SortBy>
}

export type QueryProductsArgs = {
  productErpIdentifiers: Array<Scalars['String']>
}

export type QueryProductsEolArgs = {
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type QueryRecommendationsBasedOnCartArgs = {
  numberOfProducts: Scalars['Int']
  productFilterArguments?: InputMaybe<Array<ElevateProductFilterArgumentInput>>
  productIds: Array<Scalars['String']>
}

export type QueryRequiredEndUserInformationArgs = {
  productErpIdentifier: Scalars['String']
}

export type QueryRteErrandArgs = {
  token?: InputMaybe<Scalars['String']>
}

export type QuerySarErrandArgs = {
  token?: InputMaybe<Scalars['String']>
}

export type QuerySarReportArgs = {
  token?: InputMaybe<Scalars['String']>
}

export type QuerySarReportContentArgs = {
  token?: InputMaybe<Scalars['String']>
}

export type QuerySoftCategoryByPathArgs = {
  categoryPath: Scalars['String']
  isSoftCategory?: InputMaybe<Scalars['Boolean']>
}

export type QueryTopContentPartsArgs = {
  uri: Scalars['String']
}

export type QueryTopSellingCampaignProductsArgs = {
  numberOfProducts: Scalars['Int']
}

export type QueryTopSellingProductsArgs = {
  categoryPath?: InputMaybe<Scalars['String']>
  facets?: InputMaybe<Array<InputMaybe<FacetParameterInput>>>
  numberOfProducts?: InputMaybe<Scalars['Int']>
}

export type QueryUpperLevelCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

export enum QueryFieldType {
  PredefinedValueSelection = 'PREDEFINED_VALUE_SELECTION',
  TextArea = 'TEXT_AREA',
  TextBox = 'TEXT_BOX',
}

export type RecentlyViewed = {
  products: ProductReferenceList
  relatedProducts: ProductReferenceList
}

export type RecentlyViewedProductsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type RecentlyViewedRelatedProductsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type RecommendationsBasedOnCart = {
  productReferences: Array<ProductReference>
}

export type RegisterCustomerResult = {
  contactId?: Maybe<Scalars['Int']>
  status: RegisterCustomerStatus
  userIdentifier: Scalars['UUID']
}

export enum RegisterCustomerStatus {
  DisallowNewContact = 'DISALLOW_NEW_CONTACT',
  OrganizationNumberAlreadyExists = 'ORGANIZATION_NUMBER_ALREADY_EXISTS',
  OrganizationNumberInvalid = 'ORGANIZATION_NUMBER_INVALID',
  PreRegistrationDataInvalid = 'PRE_REGISTRATION_DATA_INVALID',
  PreRegistrationEmailDomainInvalid = 'PRE_REGISTRATION_EMAIL_DOMAIN_INVALID',
  Success = 'SUCCESS',
  UsernameAlreadyExists = 'USERNAME_ALREADY_EXISTS',
  UsernameAlreadyRegisteredOnSite = 'USERNAME_ALREADY_REGISTERED_ON_SITE',
}

export type Registration = {
  errorMessage?: Maybe<Scalars['String']>
  registration?: Maybe<RegistrationResult>
}

export type RegistrationResult = {
  createdAt: Scalars['DateTime']
  email: Scalars['String']
  language: Language
  notificationType: NotificationType
  productId: Scalars['String']
  site: Site
  userIdentifier: Scalars['String']
}

export type RemoveAttesterRequestInput = {
  contactId: Scalars['Int']
  levelId: Scalars['Int']
}

export type RequiredEndUserInformationLineDto = {
  isMandatory: Scalars['Boolean']
  label?: Maybe<Scalars['String']>
  lineNumber: Scalars['Decimal']
}

export type Result = {
  blocked: Scalars['Boolean']
  companyName?: Maybe<Scalars['String']>
  contactCanBeAdded: Scalars['Boolean']
  customerId: Scalars['Int']
  disallowNewContacts: Scalars['Boolean']
  inactive: Scalars['Boolean']
  organizationNumber?: Maybe<Scalars['String']>
}

export enum ResultType {
  Ads = 'ADS',
  Corrections = 'CORRECTIONS',
  Count = 'COUNT',
  FacetList = 'FACET_LIST',
  Phrases = 'PHRASES',
  Products = 'PRODUCTS',
  Unknown = 'UNKNOWN',
  Values = 'VALUES',
}

export type SearchPreOrderInput = {
  datePeriod: DatePeriodType
  onlyPendingOrders: Scalars['Boolean']
  ordersPlacedFromDateTime?: InputMaybe<Scalars['DateTime']>
  ordersPlacedToDateTime?: InputMaybe<Scalars['DateTime']>
  ordersToShow?: InputMaybe<OrdersToShow>
  pageNumber?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  searchPhrase?: InputMaybe<Scalars['String']>
  sortByDirection: Direction
  sortByField: Field
}

export type SearchPreOrdersV2Result = {
  preOrders?: Maybe<Array<Maybe<PreOrderV2>>>
  totalCount: Scalars['Int']
}

export type SearchResultProduct = {
  assortmentId: Scalars['Int']
  availability?: Maybe<Availability>
  categoryName?: Maybe<Scalars['String']>
  categoryPath?: Maybe<Scalars['String']>
  categoryPathEng?: Maybe<Scalars['String']>
  categoryPimIdentifier: Scalars['Int']
  displayName?: Maybe<Scalars['String']>
  displaySpecifications: Scalars['String']
  ecoResCategoryErpIdentifier: Scalars['String']
  energyClass?: Maybe<Scalars['String']>
  energyDocumentId?: Maybe<Scalars['String']>
  energyLabelImageId?: Maybe<Scalars['String']>
  etaDateTimeUtc: Scalars['DateTime']
  id: Scalars['String']
  internalProduct: Scalars['Boolean']
  isNewProduct: Scalars['Boolean']
  lineDiscountGroupErpIdentifier: Scalars['String']
  manufacturerErpIdentifier: Scalars['String']
  manufacturerIsWhiteLabel: Scalars['Boolean']
  manufacturerName: Scalars['String']
  manufacturerProductIdentifier?: Maybe<Scalars['String']>
  maxQuantityPerOrder?: Maybe<Scalars['Int']>
  minQuantityPerOrder?: Maybe<Scalars['Int']>
  nameSlug: Scalars['String']
  oneLiner?: Maybe<Scalars['String']>
  price?: Maybe<Price>
  primaryImageId?: Maybe<Scalars['String']>
  productErpIdentifier: Scalars['String']
  productNewUntilDateTimeUtc: Scalars['DateTime']
  promotedSpecifications: Array<ProductSpecification>
  reviewCount: Scalars['Int']
  reviewScore: Scalars['Int']
  showAlwaysInStock: Scalars['Boolean']
  showEtaOnWeb: Scalars['Boolean']
  suppressExternalStock: Scalars['Boolean']
  ticket?: Maybe<Scalars['String']>
}

export type SearchResultProductPromotedSpecificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type SendContactInvitationRequestInput = {
  email: Scalars['String']
}

export type SendContactInvitationResult = {
  emailResponseType: EmailResponseType
  ok: Scalars['Boolean']
}

export type SendPaymentConfirmationEmailRequestInput = {
  orderId: Scalars['Int']
}

export type SendProductListInvitationResult = {
  id: Scalars['Int']
}

export type SendRequestForPartialDeliveryRequestInput = {
  orderId: Scalars['Int']
}

export type SendRequestForPartialDeliveryResult = {
  success: Scalars['Boolean']
}

export type SetDefaultGroupRequestInput = {
  groupId?: InputMaybe<Scalars['Int']>
}

export type SettingsDto = {
  customerErp?: Maybe<Scalars['String']>
  primaryPageId: Scalars['Int']
}

export enum Site {
  DustinDenmark = 'DUSTIN_DENMARK',
  DustinFinland = 'DUSTIN_FINLAND',
  DustinHomeDenmark = 'DUSTIN_HOME_DENMARK',
  DustinHomeFinland = 'DUSTIN_HOME_FINLAND',
  DustinHomeNetherlands = 'DUSTIN_HOME_NETHERLANDS',
  DustinHomeNorway = 'DUSTIN_HOME_NORWAY',
  DustinHomeSweden = 'DUSTIN_HOME_SWEDEN',
  DustinNetherlands = 'DUSTIN_NETHERLANDS',
  DustinNorway = 'DUSTIN_NORWAY',
  DustinSweden = 'DUSTIN_SWEDEN',
  None = 'NONE',
}

export type SiteInfo = {
  googleAnalytics?: Maybe<GoogleAnalytics>
  name?: Maybe<Scalars['String']>
  siteBaseUrl?: Maybe<Scalars['String']>
}

export enum SiteType {
  Business = 'BUSINESS',
  Consumer = 'CONSUMER',
  None = 'NONE',
}

export type SocialMediaLinks = {
  facebook?: Maybe<Scalars['String']>
  instagram?: Maybe<Scalars['String']>
  linkedIn?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  youTube?: Maybe<Scalars['String']>
}

export type SoftCategory = {
  campaignEndDateUtc?: Maybe<Scalars['DateTime']>
  campaignStartDateUtc?: Maybe<Scalars['DateTime']>
  campaignType?: Maybe<Scalars['String']>
  categoryIcon?: Maybe<Scalars['String']>
  categoryParentId?: Maybe<Scalars['String']>
  id: Scalars['String']
  idPath?: Maybe<Scalars['String']>
  isLeaf: Scalars['Boolean']
  name?: Maybe<Scalars['String']>
  namePath?: Maybe<Scalars['String']>
  pathSlug: Scalars['String']
  seoLongTitleName?: Maybe<Scalars['String']>
  seoMetaDescription?: Maybe<Scalars['String']>
  seoText?: Maybe<Scalars['String']>
  seoTitle?: Maybe<Scalars['String']>
}

export enum SortBy {
  Alphabetical = 'ALPHABETICAL',
  Asc = 'ASC',
  Desc = 'DESC',
  Relevance = 'RELEVANCE',
  ReviewScore = 'REVIEW_SCORE',
  Sales = 'SALES',
}

export enum SourcingCompany {
  Dk = 'DK',
  Fi = 'FI',
  Nl = 'NL',
  No = 'NO',
  None = 'NONE',
  Se = 'SE',
}

export enum SourcingCompanyInput {
  Dk = 'DK',
  Fi = 'FI',
  Nl = 'NL',
  No = 'NO',
  None = 'NONE',
  Se = 'SE',
}

export type SpecificationForDisplayDto = {
  name: Scalars['String']
  value: Scalars['String']
}

export type SpecificationItem = {
  displayInProductTitle: Scalars['Boolean']
  filterValue?: Maybe<Scalars['String']>
  hideInPromotedSpecification: Scalars['Boolean']
  hideInSpecification: Scalars['Boolean']
  id: Scalars['String']
  isPromoted: Scalars['Boolean']
  itemText: Scalars['String']
  itemValuesEng: Array<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sortOrder?: Maybe<Scalars['Int']>
  unitText?: Maybe<Scalars['String']>
  values: Array<Scalars['String']>
  variantSortOrder?: Maybe<Scalars['Int']>
  variantType?: Maybe<Scalars['String']>
  webFilterSortOrder?: Maybe<Scalars['Int']>
}

export enum SpecificationItemAttribute {
  FacetCollapsed = 'FACET_COLLAPSED',
  FacetOpened = 'FACET_OPENED',
  HideInShortSpecification = 'HIDE_IN_SHORT_SPECIFICATION',
  HideInSpecification = 'HIDE_IN_SPECIFICATION',
  None = 'NONE',
  ShowInProductName = 'SHOW_IN_PRODUCT_NAME',
  ShowOnSearchAndLandingPages = 'SHOW_ON_SEARCH_AND_LANDING_PAGES',
}

export type SpecificationsCategory = {
  categoryText: Scalars['String']
  displayOrder: Scalars['Float']
  specifications: Array<SpecificationItem>
}

export type StartPage = {
  body: Scalars['String']
  breadcrumbs: Array<Breadcrumb>
  disableSiteSelector: Scalars['Boolean']
  metaDescription?: Maybe<Scalars['String']>
  metaKeywords?: Maybe<Scalars['String']>
  noIndex: Scalars['Boolean']
  ogImage?: Maybe<Image>
  theme: Theme
  title: Scalars['String']
  type: PageType
}

export enum State {
  Active = 'ACTIVE',
  Clearance = 'CLEARANCE',
  EndOfLife = 'END_OF_LIFE',
  ExternalClearance = 'EXTERNAL_CLEARANCE',
  NotSet = 'NOT_SET',
  PermanentlyStopped = 'PERMANENTLY_STOPPED',
  PreOrder = 'PRE_ORDER',
  TempStopped = 'TEMP_STOPPED',
  Undefined = 'UNDEFINED',
}

export type SubTotal = {
  perMonthExcludingVat?: Maybe<Scalars['Decimal']>
  perMonthIncludingVat?: Maybe<Scalars['Decimal']>
  quantity: Scalars['Int']
  serviceDurationMonths?: Maybe<Scalars['Int']>
  totalExcludingVat: Scalars['Decimal']
  totalIncludingVat: Scalars['Decimal']
  totalVat: Scalars['Decimal']
}

export type Suggestion = {
  rank: Scalars['Int']
  relevance: Scalars['Decimal']
  ticket?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type SurveyOptInRequestInput = {
  optedIn: Scalars['Boolean']
}

export enum TaxGroup {
  FullG = 'FULL_G',
  FullG2 = 'FULL_G2',
  FullS = 'FULL_S',
  NlRev = 'NL_REV',
  None = 'NONE',
  Red1G = 'RED1_G',
  Red1S = 'RED1_S',
  Red2G = 'RED2_G',
  Red2S = 'RED2_S',
  ZeroG = 'ZERO_G',
  ZeroS = 'ZERO_S',
}

export enum Theme {
  BlackWeek = 'BLACK_WEEK',
  Default = 'DEFAULT',
}

export type TopSellingCampaignProducts = {
  products: Array<ProductReference>
}

export type TopSellingProducts = {
  productReferences: Array<ProductReference>
}

export enum UnitType {
  Hrs = 'HRS',
  None = 'NONE',
  Pack = 'PACK',
  Pcs = 'PCS',
}

export type UpdateAccountInformationRequestInput = {
  invoiceEmail?: InputMaybe<Scalars['String']>
  invoiceType: InvoiceType
  selectedStandIn?: InputMaybe<Scalars['Int']>
  standInUntilDateTime?: InputMaybe<Scalars['DateTime']>
}

export type UpdateAddressRequestInput = {
  addressId: Scalars['Int']
  addressLine: Scalars['String']
  addressee: Scalars['String']
  billing: Scalars['Boolean']
  city: Scalars['String']
  department?: InputMaybe<Scalars['String']>
  goodsReceiverName?: InputMaybe<Scalars['String']>
  houseNumber?: InputMaybe<Scalars['String']>
  houseNumberSuffix?: InputMaybe<Scalars['String']>
  postalCode: Scalars['String']
  shipping: Scalars['Boolean']
  suppressValidation: Scalars['Boolean']
}

export type UpdateAddressResult = {
  newAddressId: Scalars['Int']
}

export type UpdateCartItemCommentResult = {
  cartItemId: Scalars['Int']
}

export type UpdateCartResult = {
  id: Scalars['Int']
}

export type UpdateCartWithItemRequestInput = {
  lastViewedPriceExcludingVat?: InputMaybe<Scalars['Decimal']>
  lastViewedPriceIncludingVat?: InputMaybe<Scalars['Decimal']>
  lastViewedVatAmount?: InputMaybe<Scalars['Decimal']>
  productErpIdentifier?: InputMaybe<Scalars['String']>
  quantity: Scalars['Int']
}

export type UpdateCartWithMultipleItemsRequestInput = {
  cartItems?: InputMaybe<Array<InputMaybe<CartItemInput>>>
}

export type UpdateCategoryRequestInput = {
  categoryId: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateContactPermissionsRequestInput = {
  blockWebLogin: Scalars['Boolean']
  canSeeAllOrders: Scalars['Boolean']
  cannotManageAddresses: Scalars['Boolean']
  contactId: Scalars['Int']
  superUser: Scalars['Boolean']
}

export type UpdateGroupLevelRequestInput = {
  levelId: Scalars['Int']
  limit: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateGroupRequestInput = {
  id: Scalars['Int']
  name?: InputMaybe<Scalars['String']>
  useOnlyHighestAttestLevel: Scalars['Boolean']
}

export type UpdatePageContentRequestInput = {
  content?: InputMaybe<Scalars['String']>
  header?: InputMaybe<Scalars['String']>
  lead?: InputMaybe<Scalars['String']>
  pageId: Scalars['Int']
}

export type UpdateProductListNameResult = {
  updatedProductListName?: Maybe<Scalars['String']>
}

export type UpdateProductListResult = {
  productListId: Scalars['Int']
  productListItemId: Scalars['Int']
}

export type UpdateRequestStatusResult = {
  status: GdprErrandStatus
  successful: Scalars['Boolean']
}

export type UpdateShowPricesInclVatUserSettingRequestInput = {
  showPricesInclVat: Scalars['Boolean']
}

export type UpdateUserInformationRequestInput = {
  cellPhone?: InputMaybe<Scalars['String']>
  customerReferenceCostCenter?: InputMaybe<Scalars['String']>
  customerReferenceName?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  language: Language
  lastName: Scalars['String']
  offersByEmail?: InputMaybe<Scalars['Boolean']>
  phone?: InputMaybe<Scalars['String']>
  profilingAccepted: Scalars['Boolean']
  sendSolutionsMagazine?: InputMaybe<Scalars['Boolean']>
}

export type UpperLevelCategory = {
  categoryId: Scalars['String']
  englishName: Scalars['String']
  hasVisibleProducts: Scalars['Boolean']
  isLeaf: Scalars['Boolean']
  name: Scalars['String']
  namePath: Scalars['String']
  pathSlug: Scalars['String']
  seoTitle: Scalars['String']
  url: Scalars['String']
}

export type UpsertRequiredEndUserInformationResult = {
  cartItemId: Scalars['Int']
}

export type UserSettingsModel = {
  showPricesInclVat?: Maybe<Scalars['Boolean']>
}

export enum UserType {
  B2B = 'B2B',
  B2C = 'B2C',
  None = 'NONE',
}

export type Usp = {
  iconId: Scalars['String']
  link: ContentLink
  text: Scalars['String']
}

export enum WayOfTransport {
  Config = 'CONFIG',
  Email = 'EMAIL',
  GoodsLetter = 'GOODS_LETTER',
  Letter = 'LETTER',
  None = 'NONE',
  Package = 'PACKAGE',
  Pallet = 'PALLET',
  Sp = 'SP',
}

export type Zone = {
  name: Scalars['String']
  panels: Array<Panel>
}

export type AccountGraphql_Accessories = {
  dependentAccessories?: Maybe<Array<Maybe<Scalars['String']>>>
  insuranceAccessories?: Maybe<Array<Maybe<Scalars['String']>>>
  mandatoryAccessories?: Maybe<Array<Maybe<Scalars['String']>>>
  normalAccessories?: Maybe<Array<Maybe<Scalars['String']>>>
  topAccessories?: Maybe<Array<Maybe<AccessoryProductModel>>>
}

export type AccountGraphql_Availability = {
  availabilityDetails?: Maybe<AvailabilityDetails>
  availabilityStatus: AvailabilityStatus
  externalStock?: Maybe<ExternalStockAvailability>
  internal: Scalars['Boolean']
  internalEtaStock?: Maybe<InternalEtaStockAvailability>
  internalStock?: Maybe<InternalStockAvailability>
  isAvailableForSale: Scalars['Boolean']
  isIncludedInKit: Scalars['Boolean']
  isPreOrder: Scalars['Boolean']
  maxAvailableQuantity?: Maybe<Scalars['Int']>
  productErpIdentifier?: Maybe<Scalars['String']>
  productLifeCycleState: ProductLifeCycleState
  showAvailabilityQuantities: Scalars['Boolean']
  showEtaDate: Scalars['Boolean']
}

export type AccountGraphql_CartItem = {
  canBeDeliveredImmediately: Scalars['Boolean']
  comment?: Maybe<Scalars['String']>
  endUserInformation?: Maybe<Array<Maybe<EndUserInformation>>>
  id: Scalars['Int']
  parentCartItemId?: Maybe<Scalars['Int']>
  product?: Maybe<CartProduct>
  quantity: Scalars['Int']
  totalPriceDisplay?: Maybe<Scalars['String']>
}

export type AccountGraphql_DeliveryMethod = {
  displayName?: Maybe<Scalars['String']>
  id: Scalars['Int']
}

export type AccountGraphql_Price = {
  bidDistributorErpIdentifier?: Maybe<Scalars['String']>
  bidSourceLocally: Scalars['Boolean']
  campaignEndDateTimeUtc?: Maybe<Scalars['DateTime']>
  campaignPercentage?: Maybe<Scalars['Int']>
  campaignStartDateTimeUtc?: Maybe<Scalars['DateTime']>
  campaignType?: Maybe<CampaignType>
  /** Includes chemical tax. */
  costPrice: Scalars['Decimal']
  formatted: PriceFormatted
  isBestSeller: Scalars['Boolean']
  isBid: Scalars['Boolean']
  isCampaign: Scalars['Boolean']
  isRecommendedProduct: Scalars['Boolean']
  originalPrice: Scalars['Decimal']
  originalPriceExcludingVat: Scalars['Decimal']
  originalPriceIncludingVat: Scalars['Decimal']
  originalPriceVat: Scalars['Decimal']
  price: Scalars['Decimal']
  priceExcludingVat: Scalars['Decimal']
  priceIncludingVat: Scalars['Decimal']
  priceMargin: Scalars['Decimal']
  priceVat: Scalars['Decimal']
  productErpIdentifier?: Maybe<Scalars['String']>
}

export type AccountGraphql_ProductList = {
  contactId?: Maybe<Scalars['Int']>
  id: Scalars['Int']
  items?: Maybe<Array<Maybe<AccountGraphql_ProductListItem>>>
  productListName?: Maybe<Scalars['String']>
  productListType: Scalars['Int']
  userIdentifier: Scalars['UUID']
}

export type AccountGraphql_ProductListItem = {
  comment?: Maybe<Scalars['String']>
  id: Scalars['Int']
  productErpIdentifier?: Maybe<Scalars['String']>
  quantity: Scalars['Int']
}

export type ProductGraphql_Category = {
  categoryId: Scalars['String']
  categoryImageFileName?: Maybe<Scalars['String']>
  children?: Maybe<Array<ProductGraphql_Category>>
  englishName?: Maybe<Scalars['String']>
  englishNamePath?: Maybe<Scalars['String']>
  englishPathSlug?: Maybe<Scalars['String']>
  hasAccessoryGuides: Scalars['Boolean']
  hasVisibleProducts: Scalars['Boolean']
  id: Scalars['String']
  idPath: Scalars['String']
  isLeaf: Scalars['Boolean']
  language: Language
  mostPopularProduct?: Maybe<ProductReference>
  name: Scalars['String']
  namePath: Scalars['String']
  navigation?: Maybe<NavigationByCategory>
  pathSlug: Scalars['String']
  productLists?: Maybe<CategoryProductLists>
  productSearch?: Maybe<ProductsByCategory>
  seoMetaDescription?: Maybe<Scalars['String']>
  seoText?: Maybe<Scalars['String']>
  seoTitle?: Maybe<Scalars['String']>
  sortOrder: Scalars['Int']
}

export type ProductGraphql_CategoryNavigationArgs = {
  facets?: InputMaybe<Array<InputMaybe<FacetParameterInput>>>
}

export type ProductGraphql_CategoryProductSearchArgs = {
  facets?: InputMaybe<Array<InputMaybe<FacetParameterInput>>>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  searchPhrase?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<SortBy>
}

export type GetArticleBoxListQueryVariables = Exact<{
  numberOfBoxes: Scalars['Int']
  startIndex: Scalars['Int']
  tagNames: Array<Scalars['String']> | Scalars['String']
  mustIncludeAllTags: Scalars['Boolean']
  categoryNames: Array<Scalars['String']> | Scalars['String']
}>

export type GetArticleBoxListQuery = {
  articleBoxList?: {
    articles: Array<{
      title: string
      categoryName: string
      postDateText: string
      summary: string
      url: string
      updatedDate: any
      mainImage?: { url: string; relativeUrl: string; height: number } | null
    }>
  } | null
}

export type GetFormQueryVariables = Exact<{
  formId: Scalars['Int']
}>

export type GetFormQuery = {
  customerServiceInquiry?: {
    title: string
    fields: Array<{
      label: string
      type: QueryFieldType
      required: boolean
      rows: number
      allowMultipleSelections: boolean
      options?: Array<string> | null
      zendeskId?: string | null
    }>
  } | null
}

export type ContentBlockFragment = {
  type: string
  properties: any
  columnSpan: number
  areas: Array<{
    name: string
    blocks: Array<{
      type: string
      properties: any
      columnSpan: number
      areas: Array<{
        name: string
        blocks: Array<{ type: string; properties: any; columnSpan: number }>
      }>
    }>
  }>
}

export type EditorialPageFragment = {
  title: string
  metaKeywords?: string | null
  metaDescription?: string | null
  disableSiteSelector: boolean
  noIndex: boolean
  type: PageType
  theme: Theme
  body: string
  container: EditorialPageContainerType
  ogImage?: { url: string; relativeUrl: string } | null
  breadcrumbs: Array<{ displayName: string; url: string }>
  contentBlocks: Array<{
    type: string
    properties: any
    columnSpan: number
    areas: Array<{
      name: string
      blocks: Array<{
        type: string
        properties: any
        columnSpan: number
        areas: Array<{
          name: string
          blocks: Array<{ type: string; properties: any; columnSpan: number }>
        }>
      }>
    }>
  }>
}

export type BlogPageFragment = {
  title: string
  metaKeywords?: string | null
  metaDescription?: string | null
  disableSiteSelector: boolean
  noIndex: boolean
  type: PageType
  description: string
  numberOfArticles: number
  ogImage?: { url: string; relativeUrl: string } | null
  articles: Array<{
    url: string
    youTubeId?: string | null
    title: string
    summary: string
    postDateText: string
    tags: Array<string>
    categoryName: string
    mainImage?: { url: string; relativeUrl: string } | null
  }>
  fullCategories: Array<{ name: string; summary?: string | null }>
  categoryTags: Array<{ articleCount: number; tag: string }>
  banner: { url: string; relativeUrl: string; height: number }
}

export type ArticlePageFragment = {
  title: string
  metaKeywords?: string | null
  metaDescription?: string | null
  disableSiteSelector: boolean
  noIndex: boolean
  type: PageType
  blogUrl: string
  header: string
  body: string
  tags: Array<string>
  postDateText: string
  postDateIso8601Text: string
  youTubeId?: string | null
  excerpt?: string | null
  categoryName: string
  updatedDateText: string
  updatedDateIso8601Text: string
  ogImage?: { url: string; relativeUrl: string } | null
  contentBlocks: Array<{
    type: string
    properties: any
    columnSpan: number
    areas: Array<{
      name: string
      blocks: Array<{
        type: string
        properties: any
        columnSpan: number
        areas: Array<{
          name: string
          blocks: Array<{ type: string; properties: any; columnSpan: number }>
        }>
      }>
    }>
  }>
  heroImage?: { url: string; relativeUrl: string } | null
  mainImage?: { url: string; relativeUrl: string } | null
  categoryArticles: Array<{
    url: string
    youTubeId?: string | null
    title: string
    summary: string
    postDateText: string
    tags: Array<string>
    categoryName: string
    mainImage?: { url: string; relativeUrl: string } | null
  }>
  breadcrumbs: Array<{ displayName: string; url: string }>
}

export type StartPageFragment = {
  title: string
  metaKeywords?: string | null
  metaDescription?: string | null
  disableSiteSelector: boolean
  noIndex: boolean
  type: PageType
  ogImage?: { url: string; relativeUrl: string } | null
}

export type BrandListPageFragment = {
  title: string
  metaKeywords?: string | null
  metaDescription?: string | null
  disableSiteSelector: boolean
  noIndex: boolean
  type: PageType
  header: string
  ogImage?: { url: string; relativeUrl: string } | null
  brands: Array<{ name: string; url: string; logo?: { url: string; relativeUrl: string } | null }>
}

export type BrandPageFragment = {
  title: string
  metaKeywords?: string | null
  metaDescription?: string | null
  disableSiteSelector: boolean
  noIndex: boolean
  type: PageType
  header: string
  description: string
  url: string
  allBrandsUrl: string
  ogImage?: { url: string; relativeUrl: string } | null
  logo?: { url: string } | null
  breadcrumbs: Array<{ displayName: string; url: string }>
}

export type BrandContentPageFragment = {
  title: string
  metaKeywords?: string | null
  metaDescription?: string | null
  disableSiteSelector: boolean
  noIndex: boolean
  type: PageType
  allBrandsUrl: string
  pageHeader?: string | null
  ogImage?: { url: string; relativeUrl: string } | null
  breadcrumbs: Array<{ displayName: string; url: string }>
  navigationItems: Array<{
    displayName: string
    isActive: boolean
    url: string
    subNavigationItems: Array<{
      displayName: string
      url: string
      subNavigationItems: Array<{ displayName: string; url: string }>
    }>
  }>
  brandContent: Array<{
    content?: string | null
    outerCssStyle: string
    outerCssClass: string
    cssStyle?: string | null
    cssClass: string
    header?: string | null
    bigHeader: boolean
    showMoreText?: string | null
    showMoreUrl?: string | null
    textFirst: boolean
    brandName?: string | null
    searchPhrase?: string | null
    newProductsOnly: boolean
    campaignOnly: boolean
    categoryPath?: string | null
    contentType: BrandContentType
    contentBlocks: Array<{
      type: string
      properties: any
      columnSpan: number
      areas: Array<{
        name: string
        blocks: Array<{
          type: string
          properties: any
          columnSpan: number
          areas: Array<{
            name: string
            blocks: Array<{ type: string; properties: any; columnSpan: number }>
          }>
        }>
      }>
    }>
    image?: { url: string; height: number } | null
  }>
}

export type CustomerStartPageFragment = {
  title: string
  metaKeywords?: string | null
  metaDescription?: string | null
  disableSiteSelector: boolean
  noIndex: boolean
  type: PageType
  ogImage?: { url: string; relativeUrl: string } | null
  content: Array<{
    type: CustomerContentType
    textContent?: string | null
    listProductIds?: Array<string> | null
    displayAsList: boolean
    groupDisplayName?: string | null
    categoryPaths?: Array<string> | null
    groupProductLists?: Array<{ productIds: Array<string>; displayAsList: boolean }> | null
    linkBoxes?: Array<{
      url: string
      text: string
      openInNewWindow: boolean
      alternativeImageUrl?: string | null
      image?: { url: string; relativeUrl: string } | null
    }> | null
  }>
  customerNavigation?: {
    parentCustomerName?: string | null
    parentCustomerLandingPageUrl?: string | null
    landingPageUrl: string
    parentCustomerProductCategoryLinks: Array<{ displayName: string; url: string }>
    productCategoryLinks: Array<{ displayName: string; url: string }>
    documentsAndLinks: Array<{ displayName: string; url: string }>
    contacts: Array<{
      name: string
      title: string
      email?: string | null
      phone?: string | null
      avatar?: { height: number; url: string } | null
    }>
    logo?: { height: number; url: string } | null
  } | null
}

export type CustomerProductGroupPageFragment = {
  title: string
  metaKeywords?: string | null
  metaDescription?: string | null
  disableSiteSelector: boolean
  noIndex: boolean
  type: PageType
  header: string
  ogImage?: { url: string; relativeUrl: string } | null
  content: Array<{
    type: CustomerContentType
    textContent?: string | null
    listProductIds?: Array<string> | null
    displayAsList: boolean
    groupDisplayName?: string | null
    categoryPaths?: Array<string> | null
    groupProductLists?: Array<{ productIds: Array<string>; displayAsList: boolean }> | null
    linkBoxes?: Array<{
      url: string
      text: string
      openInNewWindow: boolean
      alternativeImageUrl?: string | null
      image?: { url: string; relativeUrl: string } | null
    }> | null
  }>
  customerNavigation?: {
    parentCustomerName?: string | null
    parentCustomerLandingPageUrl?: string | null
    landingPageUrl: string
    parentCustomerProductCategoryLinks: Array<{ displayName: string; url: string }>
    productCategoryLinks: Array<{ displayName: string; url: string }>
    documentsAndLinks: Array<{ displayName: string; url: string }>
    contacts: Array<{
      name: string
      title: string
      email?: string | null
      phone?: string | null
      avatar?: { height: number; url: string } | null
    }>
    logo?: { height: number; url: string } | null
  } | null
}

export type CustomerNavigationFragment = {
  parentCustomerName?: string | null
  parentCustomerLandingPageUrl?: string | null
  landingPageUrl: string
  parentCustomerProductCategoryLinks: Array<{ displayName: string; url: string }>
  productCategoryLinks: Array<{ displayName: string; url: string }>
  documentsAndLinks: Array<{ displayName: string; url: string }>
  contacts: Array<{
    name: string
    title: string
    email?: string | null
    phone?: string | null
    avatar?: { height: number; url: string } | null
  }>
  logo?: { height: number; url: string } | null
}

export type GetPageQueryVariables = Exact<{
  uri: Scalars['String']
  preview: Scalars['Boolean']
}>

export type GetPageQuery = {
  page?:
    | {
        title: string
        metaKeywords?: string | null
        metaDescription?: string | null
        disableSiteSelector: boolean
        noIndex: boolean
        type: PageType
        blogUrl: string
        header: string
        body: string
        tags: Array<string>
        postDateText: string
        postDateIso8601Text: string
        youTubeId?: string | null
        excerpt?: string | null
        categoryName: string
        updatedDateText: string
        updatedDateIso8601Text: string
        ogImage?: { url: string; relativeUrl: string } | null
        contentBlocks: Array<{
          type: string
          properties: any
          columnSpan: number
          areas: Array<{
            name: string
            blocks: Array<{
              type: string
              properties: any
              columnSpan: number
              areas: Array<{
                name: string
                blocks: Array<{ type: string; properties: any; columnSpan: number }>
              }>
            }>
          }>
        }>
        heroImage?: { url: string; relativeUrl: string } | null
        mainImage?: { url: string; relativeUrl: string } | null
        categoryArticles: Array<{
          url: string
          youTubeId?: string | null
          title: string
          summary: string
          postDateText: string
          tags: Array<string>
          categoryName: string
          mainImage?: { url: string; relativeUrl: string } | null
        }>
        breadcrumbs: Array<{ displayName: string; url: string }>
      }
    | {
        title: string
        metaKeywords?: string | null
        metaDescription?: string | null
        disableSiteSelector: boolean
        noIndex: boolean
        type: PageType
        description: string
        numberOfArticles: number
        ogImage?: { url: string; relativeUrl: string } | null
        articles: Array<{
          url: string
          youTubeId?: string | null
          title: string
          summary: string
          postDateText: string
          tags: Array<string>
          categoryName: string
          mainImage?: { url: string; relativeUrl: string } | null
        }>
        fullCategories: Array<{ name: string; summary?: string | null }>
        categoryTags: Array<{ articleCount: number; tag: string }>
        banner: { url: string; relativeUrl: string; height: number }
      }
    | {
        title: string
        metaKeywords?: string | null
        metaDescription?: string | null
        disableSiteSelector: boolean
        noIndex: boolean
        type: PageType
        allBrandsUrl: string
        pageHeader?: string | null
        ogImage?: { url: string; relativeUrl: string } | null
        breadcrumbs: Array<{ displayName: string; url: string }>
        navigationItems: Array<{
          displayName: string
          isActive: boolean
          url: string
          subNavigationItems: Array<{
            displayName: string
            url: string
            subNavigationItems: Array<{ displayName: string; url: string }>
          }>
        }>
        brandContent: Array<{
          content?: string | null
          outerCssStyle: string
          outerCssClass: string
          cssStyle?: string | null
          cssClass: string
          header?: string | null
          bigHeader: boolean
          showMoreText?: string | null
          showMoreUrl?: string | null
          textFirst: boolean
          brandName?: string | null
          searchPhrase?: string | null
          newProductsOnly: boolean
          campaignOnly: boolean
          categoryPath?: string | null
          contentType: BrandContentType
          contentBlocks: Array<{
            type: string
            properties: any
            columnSpan: number
            areas: Array<{
              name: string
              blocks: Array<{
                type: string
                properties: any
                columnSpan: number
                areas: Array<{
                  name: string
                  blocks: Array<{ type: string; properties: any; columnSpan: number }>
                }>
              }>
            }>
          }>
          image?: { url: string; height: number } | null
        }>
      }
    | {
        title: string
        metaKeywords?: string | null
        metaDescription?: string | null
        disableSiteSelector: boolean
        noIndex: boolean
        type: PageType
        header: string
        ogImage?: { url: string; relativeUrl: string } | null
        brands: Array<{
          name: string
          url: string
          logo?: { url: string; relativeUrl: string } | null
        }>
      }
    | {
        title: string
        metaKeywords?: string | null
        metaDescription?: string | null
        disableSiteSelector: boolean
        noIndex: boolean
        type: PageType
        header: string
        description: string
        url: string
        allBrandsUrl: string
        ogImage?: { url: string; relativeUrl: string } | null
        logo?: { url: string } | null
        breadcrumbs: Array<{ displayName: string; url: string }>
      }
    | {
        title: string
        metaKeywords?: string | null
        metaDescription?: string | null
        disableSiteSelector: boolean
        noIndex: boolean
        type: PageType
        header: string
        ogImage?: { url: string; relativeUrl: string } | null
        content: Array<{
          type: CustomerContentType
          textContent?: string | null
          listProductIds?: Array<string> | null
          displayAsList: boolean
          groupDisplayName?: string | null
          categoryPaths?: Array<string> | null
          groupProductLists?: Array<{ productIds: Array<string>; displayAsList: boolean }> | null
          linkBoxes?: Array<{
            url: string
            text: string
            openInNewWindow: boolean
            alternativeImageUrl?: string | null
            image?: { url: string; relativeUrl: string } | null
          }> | null
        }>
        customerNavigation?: {
          parentCustomerName?: string | null
          parentCustomerLandingPageUrl?: string | null
          landingPageUrl: string
          parentCustomerProductCategoryLinks: Array<{ displayName: string; url: string }>
          productCategoryLinks: Array<{ displayName: string; url: string }>
          documentsAndLinks: Array<{ displayName: string; url: string }>
          contacts: Array<{
            name: string
            title: string
            email?: string | null
            phone?: string | null
            avatar?: { height: number; url: string } | null
          }>
          logo?: { height: number; url: string } | null
        } | null
      }
    | {
        title: string
        metaKeywords?: string | null
        metaDescription?: string | null
        disableSiteSelector: boolean
        noIndex: boolean
        type: PageType
        ogImage?: { url: string; relativeUrl: string } | null
        content: Array<{
          type: CustomerContentType
          textContent?: string | null
          listProductIds?: Array<string> | null
          displayAsList: boolean
          groupDisplayName?: string | null
          categoryPaths?: Array<string> | null
          groupProductLists?: Array<{ productIds: Array<string>; displayAsList: boolean }> | null
          linkBoxes?: Array<{
            url: string
            text: string
            openInNewWindow: boolean
            alternativeImageUrl?: string | null
            image?: { url: string; relativeUrl: string } | null
          }> | null
        }>
        customerNavigation?: {
          parentCustomerName?: string | null
          parentCustomerLandingPageUrl?: string | null
          landingPageUrl: string
          parentCustomerProductCategoryLinks: Array<{ displayName: string; url: string }>
          productCategoryLinks: Array<{ displayName: string; url: string }>
          documentsAndLinks: Array<{ displayName: string; url: string }>
          contacts: Array<{
            name: string
            title: string
            email?: string | null
            phone?: string | null
            avatar?: { height: number; url: string } | null
          }>
          logo?: { height: number; url: string } | null
        } | null
      }
    | {
        title: string
        metaKeywords?: string | null
        metaDescription?: string | null
        disableSiteSelector: boolean
        noIndex: boolean
        type: PageType
        theme: Theme
        body: string
        container: EditorialPageContainerType
        ogImage?: { url: string; relativeUrl: string } | null
        breadcrumbs: Array<{ displayName: string; url: string }>
        contentBlocks: Array<{
          type: string
          properties: any
          columnSpan: number
          areas: Array<{
            name: string
            blocks: Array<{
              type: string
              properties: any
              columnSpan: number
              areas: Array<{
                name: string
                blocks: Array<{ type: string; properties: any; columnSpan: number }>
              }>
            }>
          }>
        }>
      }
    | {
        title: string
        metaKeywords?: string | null
        metaDescription?: string | null
        disableSiteSelector: boolean
        noIndex: boolean
        type: PageType
        ogImage?: { url: string; relativeUrl: string } | null
      }
    | null
}

export type SubmitFormMutationVariables = Exact<{
  id: Scalars['Int']
  title: Scalars['String']
  fields: Array<CustomerServiceInquiryFieldInput> | CustomerServiceInquiryFieldInput
  captchaResponse?: InputMaybe<Scalars['String']>
}>

export type SubmitFormMutation = { submitCustomerServiceInquiry: string }

export type GetUpperLevelCategoriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
}>

export type GetUpperLevelCategoriesQuery = {
  upperLevelCategories?: Array<{ categoryId: string; name: string; url: string } | null> | null
}
